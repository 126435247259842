export const formatCarModel = (model: string): string => {
    // Remove anything between parentheses and trim
    const baseModel = model.split('(')[0].trim();

    // List of words to filter out (body types)
    const bodyTypeWords = ['sedan', 'hatchback', 'wagon', 'coupe', 'convertible', 'suv', 'crossover'];

    // Filter out body type words and capitalize each remaining word
    return baseModel
        .split(' ')
        .filter(word => !bodyTypeWords.includes(word.toLowerCase()))
        .join(' ');
};