import React from 'react';
import { Box, Grid, Typography, Alert } from '@mui/material';
import { Car } from '../../types/Car';
import CarCard from '../car-card/CarCard';
import AddCarCard from '../car-card/AddCarCard';

interface CarListProps {
    cars: Car[];
    isLoading: boolean;
    error: any;
    mode: 'owner' | 'visitor';
    onEdit?: (car: Car) => void;
    onAdd?: () => void;
}

export const CarList: React.FC<CarListProps> = ({
    cars,
    isLoading,
    error,
    mode,
    onEdit,
    onAdd,
}) => {
    if (error) {
        return (
            <Alert severity="error" sx={{ mb: 3 }}>
                Fout tijdens het laden van de auto&apos;s
            </Alert>
        );
    }

    return (
        <Grid container spacing={2}>
            {cars.length > 0 && !isLoading ? cars.map((car) => (
                <Grid item xs={12} key={car.id}>
                    <CarCard
                        car={car}
                        onEdit={() => onEdit?.(car)}
                        showActions={mode === 'owner'}
                    />
                </Grid>
            )) :
                <Grid item xs={12}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            p: 4,
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                            boxShadow: 1
                        }}
                    >
                        <Typography variant="h6" color="text.secondary" textAlign='center' gutterBottom>
                            Geen auto&apos;s in de garage
                        </Typography>
                        {mode === 'owner' && onAdd && (
                            <Typography color="text.secondary">
                                Klik op de <b>Auto Toevoegen</b> knop om een auto toe te voegen
                            </Typography>
                        )}
                    </Box>
                </Grid>
            }
            {mode === 'owner' && onAdd && (
                <Grid item xs={12}>
                    <AddCarCard onClick={onAdd} />
                </Grid>
            )}
        </Grid>
    );
};

export default CarList; 