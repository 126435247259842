import React from 'react';
import { Box } from '@mui/material';
import EventForm from '../components/event-form/EventForm';
import { useNavigate, useParams } from 'react-router-dom';
import TopBar from '../components/top-bar/TopBar';

const CreateMeet = () => {
    const navigate = useNavigate();
    const { id: meetId } = useParams<{ id: string }>();

    return (
        <Box sx={{ maxWidth: '600px', margin: '0 auto' }}>
            {meetId ? <TopBar onBack={() => navigate('/')} variant='details' title={'Bewerk meet'} /> : null}
            <EventForm meetId={meetId} />
        </Box>
    );
};

export default CreateMeet;