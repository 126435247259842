import React, { useState, useRef, TouchEvent } from 'react';
import {
    Box,
    IconButton,
    MobileStepper,
    Modal,
} from '@mui/material';
import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Fullscreen as FullscreenIcon,
    FullscreenExit as FullscreenExitIcon,
} from '@mui/icons-material';

interface ImageCarouselProps {
    images: string[];
    aspectRatio?: number;
    showArrows?: boolean;
    onImageClick?: () => void;
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({
    images,
    aspectRatio = 1,
    showArrows = true,
    onImageClick,
}): JSX.Element => {
    const [activeStep, setActiveStep] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const maxSteps = images.length;
    const touchStartXRef = useRef<number | null>(null);
    const [touchOffset, setTouchOffset] = useState(0);
    const minSwipeDistance = 50; // minimum distance for a swipe
    const containerRef = useRef<HTMLDivElement>(null);

    const handleNext = (e?: React.MouseEvent) => {
        e?.stopPropagation();
        if (activeStep < maxSteps - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = (e?: React.MouseEvent) => {
        e?.stopPropagation();
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const toggleFullscreen = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsFullscreen(!isFullscreen);
    };

    const handleTouchStart = (e: TouchEvent) => {
        touchStartXRef.current = e.touches[0].clientX;
        setTouchOffset(0);
    };

    const handleTouchMove = (e: TouchEvent) => {
        if (touchStartXRef.current === null || !containerRef.current) return;

        const containerWidth = containerRef.current.offsetWidth;
        const currentTouch = e.touches[0].clientX;
        const diff = currentTouch - touchStartXRef.current;

        // Calculate the maximum allowed offset based on current position
        const maxOffset = activeStep === 0 ? 80 : containerWidth;
        const minOffset = activeStep === maxSteps - 1 ? -80 : -containerWidth;

        // Restrict the offset within bounds and add resistance at the edges
        let newOffset = diff;
        if (diff > 0 && activeStep === 0) {
            newOffset = Math.min(diff * 0.3, maxOffset); // Add resistance when pulling right at first image
        } else if (diff < 0 && activeStep === maxSteps - 1) {
            newOffset = Math.max(diff * 0.3, minOffset); // Add resistance when pulling left at last image
        }

        setTouchOffset(newOffset);
    };

    const handleTouchEnd = (e: TouchEvent) => {
        if (touchStartXRef.current === null) return;

        const touchEndX = e.changedTouches[0].clientX;
        const distance = touchEndX - touchStartXRef.current;

        // Check if the swipe distance is significant enough
        if (Math.abs(distance) > minSwipeDistance) {
            if (distance > 0 && activeStep > 0) {
                // Swiped right
                handleBack();
            } else if (distance < 0 && activeStep < maxSteps - 1) {
                // Swiped left
                handleNext();
            }
        }

        touchStartXRef.current = null;
        setTouchOffset(0);
    };

    if (!images.length) return <></>;

    const carousel = (
        <Box
            ref={containerRef}
            sx={{
                position: 'relative',
                aspectRatio: isFullscreen ? undefined : aspectRatio,
                cursor: onImageClick ? 'pointer' : 'default',
                height: isFullscreen ? '100%' : 'auto',
                width: '100%',
            }}
            onClick={onImageClick}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <Box
                sx={{
                    position: 'relative',
                    aspectRatio: isFullscreen ? undefined : aspectRatio,
                    height: isFullscreen ? '100%' : 'auto',
                    backgroundColor: 'background.default',
                    overflow: 'hidden',
                }}
            >
                {images.map((image, index) => (
                    <Box
                        key={index}
                        component="img"
                        src={image.startsWith('blob:') ? image : `${process.env.REACT_APP_API_BASE_URL}${image}`}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            aspectRatio: isFullscreen ? undefined : aspectRatio,
                            objectFit: isFullscreen ? 'contain' : 'cover',
                            transform: `translateX(calc(${(index - activeStep) * 100}% + ${touchOffset}px))`,
                            transition: touchOffset === 0 ? 'transform 0.3s ease-in-out' : 'none',
                            willChange: 'transform',
                        }}
                    />
                ))}

                {showArrows && maxSteps > 1 && (
                    <>
                        <IconButton
                            size="small"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                            sx={{
                                height: 24, 
                                width: 24,
                                opacity: 0.5,
                                position: 'absolute',
                                left: 12,
                                color: 'primary.main',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                bgcolor: 'secondary.main',
                                '&:hover': { bgcolor: 'secondary.main' },
                                ...(activeStep === 0 && { display: 'none' }),
                            }}
                        >
                            <ChevronLeftIcon sx={{ fontSize: 24 }} />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                            sx={{
                                height: 24,
                                width: 24,
                                opacity: 0.5,
                                position: 'absolute',
                                right: 12,
                                color: 'primary.main',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                bgcolor: 'secondary.main',
                                '&:hover': { bgcolor: 'secondary.main' },
                                ...(activeStep === maxSteps - 1 && { display: 'none' }),
                            }}
                        >
                            <ChevronRightIcon sx={{ fontSize: 24 }} />
                        </IconButton>
                    </>
                )}

                <IconButton
                    onClick={toggleFullscreen}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                        },
                    }}
                >
                    {isFullscreen ? (
                        <FullscreenExitIcon sx={{ color: 'white' }} />
                    ) : (
                        <FullscreenIcon sx={{ color: 'white' }} />
                    )}
                </IconButton>

                {maxSteps > 1 && (
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        sx={{
                            position: 'absolute',
                            bottom: isFullscreen ? 16 : 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: 'auto',
                            background: 'transparent',
                            '& .MuiMobileStepper-dots': {
                                gap: '4px'
                            },
                            '& .MuiMobileStepper-dot': {
                                width: isFullscreen ? 8 : 6,
                                height: isFullscreen ? 8 : 6,
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            },
                            '& .MuiMobileStepper-dotActive': {
                                backgroundColor: 'white',
                            },
                        }}
                        backButton={null}
                        nextButton={null}
                    />
                )}
            </Box>
        </Box>
    );

    return (
        <>
            {isFullscreen ? (
                <Modal
                    open={true}
                    onClose={() => setIsFullscreen(false)}
                    sx={{
                        '& .MuiBackdrop-root': {
                            backgroundColor: 'rgba(0, 0, 0, 0.9)',
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            outline: 'none',
                        }}
                    >
                        {carousel}
                    </Box>
                </Modal>
            ) : carousel}
        </>
    );
};

export default ImageCarousel; 