import React, { useEffect } from 'react';
import {
    Box,
    Grid,
    Autocomplete,
    TextField,
    Typography,
    Avatar,
} from '@mui/material';
import { Model, Make, Vehicle } from '../../../types/vehicle';
import { useVehicleSelector } from '../../../hooks/useVehicleSelector';
import { getManufacturerLogo } from '../../../utils/imageUtils';
import { CreateCarDTO } from '../../../types/Car';

interface BasicInfoStepProps {
    formData: CreateCarDTO;
    onUpdate: (updates: Partial<CreateCarDTO>) => void;
}

const BasicInfoStep: React.FC<BasicInfoStepProps> = ({ formData, onUpdate }) => {
    const { makes, models, vehicles, loadModels, loadVehicles } = useVehicleSelector();

    const selectedMake = makes.find(m => m.id === formData.makeId);
    const selectedModel = models.find(m => m.id === formData.modelId);
    const selectedVehicle = vehicles.find(v => v.id === formData.vehicleId);

    useEffect(() => {
        if (formData.makeId) {
            loadModels(formData.makeId);
        }
    }, [formData.makeId, loadModels]);

    useEffect(() => {
        if (formData.modelId) {
            loadVehicles(formData.modelId);
        }
    }, [formData.modelId, loadVehicles]);

    const handleMakeChange = (_: any, make: Make | null) => {
        onUpdate({
            makeId: make?.id,
            make: make?.name,
            modelId: undefined,
            model: undefined,
            vehicleId: undefined,
            vehicleName: undefined
        });
    };

    const handleModelChange = (_: any, model: Model | null) => {
        onUpdate({
            modelId: model?.id,
            model: model?.name,
            vehicleId: undefined,
            vehicleName: undefined
        });
    };

    const handleVehicleChange = (_: any, vehicle: Vehicle | null) => {
        onUpdate({
            vehicleId: vehicle?.id,
            vehicleName: vehicle?.name
        });
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Auto Selectie
            </Typography>
            <Typography color="text.secondary" sx={{ mb: 3 }}>
                Selecteer je auto uit de lijst
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Autocomplete
                        value={selectedMake || null}
                        onChange={handleMakeChange}
                        options={makes}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Avatar
                                        src={getManufacturerLogo(option.name)}
                                        alt={option.name}
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            bgcolor: 'transparent',
                                            '& img': {
                                                objectFit: 'contain',
                                            },
                                        }}
                                    />
                                    <Typography>{option.name}</Typography>
                                </Box>
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Merk"
                                required
                                color='secondary'
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        value={selectedModel || null}
                        onChange={handleModelChange}
                        options={models}
                        getOptionLabel={(option) => option.name}
                        disabled={!formData.makeId}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                color='secondary'
                                label="Model"
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        value={selectedVehicle || null}
                        onChange={handleVehicleChange}
                        options={vehicles}
                        getOptionLabel={(option) => option.name}
                        disabled={!formData.modelId}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                color='secondary'
                                label="Uitvoering"
                                required
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default BasicInfoStep; 