import React, { useState, useEffect } from 'react';
import {
    Box,
    IconButton,
    Typography,
    Button,
} from '@mui/material';
import {
    Delete as DeleteIcon,
    PhotoCamera,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';

interface ImageUploadProps {
    images: File[];
    onImagesChange: (images: File[]) => void;
    maxImages?: number;
    previewSize?: number;
    existingImagesCount?: number;
    buttonText?: string;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
    images,
    onImagesChange,
    maxImages = 5,
    previewSize = 100,
    existingImagesCount = 0,
    buttonText = 'Foto&apos;s'
}) => {
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const scrollContainerRef = React.useRef<HTMLDivElement>(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newImages = Array.from(event.target.files);
            onImagesChange([...images, ...newImages].slice(0, maxImages));
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const handleImageDelete = (indexToDelete: number) => {
        onImagesChange(images.filter((_, index) => index !== indexToDelete));
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const checkScrollButtons = () => {
        if (scrollContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 1);
        }
    };

    useEffect(() => {
        // Initial check after render
        const initialCheck = setTimeout(() => {
            checkScrollButtons();
        }, 100);
        
        // Add resize listener to recalculate on window size changes
        window.addEventListener('resize', checkScrollButtons);
        
        return () => {
            clearTimeout(initialCheck);
            window.removeEventListener('resize', checkScrollButtons);
        };
    }, [images]);

    const handleScroll = (direction: 'left' | 'right') => {
        if (scrollContainerRef.current) {
            const scrollAmount = previewSize + 8; // image width + gap
            const newScrollPosition = scrollContainerRef.current.scrollLeft +
                (direction === 'left' ? -scrollAmount : scrollAmount);
            scrollContainerRef.current.scrollTo({
                left: newScrollPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <Box>
            <Box sx={{ position: 'relative', mt: 2 }}>
                {previewSize > 0 && images.length > 0 && (
                    <>
                        {canScrollLeft && (
                            <IconButton
                                onClick={() => handleScroll('left')}
                                sx={{
                                    position: 'absolute',
                                    left: -20,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    zIndex: 2,
                                    backgroundColor: 'background.paper',
                                    '&:hover': { backgroundColor: 'action.hover' },
                                    boxShadow: 2,
                                }}
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        )}
                        {canScrollRight && (
                            <IconButton
                                onClick={() => handleScroll('right')}
                                sx={{
                                    position: 'absolute',
                                    right: -20,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    zIndex: 2,
                                    backgroundColor: 'background.paper',
                                    '&:hover': { backgroundColor: 'action.hover' },
                                    boxShadow: 2,
                                }}
                            >
                                <ChevronRightIcon />
                            </IconButton>
                        )}
                    </>
                )}
                <Box
                    ref={scrollContainerRef}
                    onScroll={checkScrollButtons}
                    sx={{
                        display: 'flex',
                        gap: 1,
                        overflowX: 'auto',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        },
                        msOverflowStyle: 'none',
                        px: 1,
                    }}
                >
                    {images.map((image, index) => (
                        <Box
                            key={index}
                            sx={{
                                position: 'relative',
                                minWidth: previewSize,
                                width: previewSize,
                                height: previewSize,
                                borderRadius: 1,
                                overflow: 'hidden',
                                flexShrink: 0,
                            }}
                        >
                            <Box
                                component="img"
                                src={URL.createObjectURL(image)}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'
                                }}
                            />
                            <IconButton
                                onClick={() => handleImageDelete(index)}
                                sx={{
                                    position: 'absolute',
                                    top: 4,
                                    right: 4,
                                    padding: '4px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.7)'
                                    }
                                }}
                            >
                                <DeleteIcon sx={{ fontSize: 16, color: 'white' }} />
                            </IconButton>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'flex-start' }}>
                <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<PhotoCamera />}
                    sx={{ height: '42px' }}
                    component="label"
                    disabled={images.length >= maxImages}
                >
                    <Typography>{buttonText}</Typography>
                    <input
                        ref={fileInputRef}
                        hidden
                        accept="image/*"
                        type="file"
                        multiple
                        onChange={handleImageUpload}
                    />
                </Button>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        ml: 'auto',
                        animation: images.length === maxImages ? 'pulse 2s infinite' : 'none',
                        '@keyframes pulse': {
                            '0%': { opacity: 1 },
                            '50%': { opacity: 0.6 },
                            '100%': { opacity: 1 },
                        }
                    }}
                >
                    {existingImagesCount}/{maxImages} foto&apos;s
                </Typography>
            </Box>
        </Box>
    );
};

export default ImageUpload; 