import useSWR from 'swr';
import { useStytch } from '@stytch/react';
import { Review, ReviewDTO } from '../types/Review';
import { defaultConfig, fetcher } from '../lib/swr';
import * as Sentry from '@sentry/react';

export const useReviews = (meetId: string) => {
    const stytch = useStytch();
    const sessionToken = stytch.session.getTokens()?.session_token;

    const { data: reviews, error, isLoading, mutate } = useSWR<Review[]>(
        [`${process.env.REACT_APP_API_BASE_URL}/reviews/${meetId}`, sessionToken],
        fetcher,
        defaultConfig
    );

    const createReview = async (review: ReviewDTO) => {
        try {
            const formData = new FormData();
            formData.append('rating', String(review.rating));
            if (review.comment) formData.append('comment', review.comment);
            formData.append('meetId', review.meetId);

            if (review.images?.length) {
                review.images.forEach(image => {
                    if (image instanceof File) {
                        formData.append('images', image);
                    }
                });
            }

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/reviews`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const newReview = await response.json();
            mutate();
            return newReview;
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error creating review:', error);
            throw error;
        }
    };

    const deleteReview = async (reviewId: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/reviews/${reviewId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            mutate();
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error deleting review:', error);
            throw error;
        }
    };

    return {
        reviews: reviews || [],
        error,
        isLoading,
        isError: !!error,
        createReview,
        deleteReview
    };
}; 