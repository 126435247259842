import { useEffect, useState } from 'react';
import { useStytch } from '@stytch/react';
import useSWR from 'swr';
import { Make, Model, Vehicle } from '../types/vehicle';
import { defaultConfig, fetcher, mutationFetcher } from '../lib/swr';

const sortByName = <T extends { name: string }>(items: T[] | undefined): T[] => {
    if (!items || !Array.isArray(items)) return [];
    return [...items].sort((a, b) => a.name.localeCompare(b.name));
};

export const useVehicleSelector = () => {
    const stytch = useStytch();
    const sessionToken = stytch.session.getTokens()?.session_token;

    const { data: makes, error: makesError, isLoading: makesLoading } = useSWR<Make[]>(
        sessionToken ? [`${process.env.REACT_APP_API_BASE_URL}/cars/makes`, sessionToken] : null,
        fetcher,
        defaultConfig
    );

    const [selectedMakeId, setSelectedMakeId] = useState<number | null>(null);
    const [selectedModelId, setSelectedModelId] = useState<number | null>(null);

    const { data: models, error: modelsError, isLoading: modelsLoading } = useSWR<Model[]>(
        selectedMakeId && sessionToken ? [`${process.env.REACT_APP_API_BASE_URL}/cars/makes/${selectedMakeId}/models`, sessionToken] : null,
        fetcher,
        defaultConfig
    );

    const { data: vehicles, error: vehiclesError, isLoading: vehiclesLoading } = useSWR<Vehicle[]>(
        selectedModelId && sessionToken ? [`${process.env.REACT_APP_API_BASE_URL}/cars/models/${selectedModelId}/vehicles`, sessionToken] : null,
        fetcher,
        defaultConfig
    );

    // Log errors for debugging
    useEffect(() => {
        if (makesError) console.error('Error fetching makes:', makesError);
        if (modelsError) console.error('Error fetching models:', modelsError);
        if (vehiclesError) console.error('Error fetching vehicles:', vehiclesError);
    }, [makesError, modelsError, vehiclesError]);

    const loadModels = (makeId: number) => {
        setSelectedMakeId(makeId);
        setSelectedModelId(null); // Reset vehicle selection when make changes
    };

    const loadVehicles = (modelId: number) => {
        setSelectedModelId(modelId);
    };

    return {
        makes: sortByName(makes),
        models: sortByName(models),
        vehicles: sortByName(vehicles),
        loadModels,
        loadVehicles,
        loading: makesLoading || modelsLoading || vehiclesLoading,
        error: makesError || modelsError || vehiclesError
    };
}; 