import { useMap } from 'react-leaflet';
import { Box, IconButton, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const CustomZoomControl = () => {
    const map = useMap();
    const theme = useTheme();

    return (
        <Box
            sx={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                right: 10,
                top: 10,
                zIndex: 1000,
                backgroundColor: 'background.paper',
                borderRadius: 1,
                '& > button': {
                    color: theme.palette.secondary.main,
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    },
                },
            }}
        >
            <IconButton size="small" onClick={() => map.zoomIn()}>
                <AddIcon />
            </IconButton>
            <IconButton size="small" onClick={() => map.zoomOut()}>
                <RemoveIcon />
            </IconButton>
        </Box>
    );
};

export default CustomZoomControl;
