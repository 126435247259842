import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Tabs,
    Tab,
    Grid,
    Typography,
    Card,
    CardMedia,
    Skeleton,
} from '@mui/material';
import { Whatshot as WhatshotIcon, NewReleases as NewIcon, EmojiEvents as TrophyIcon } from '@mui/icons-material';
import { useDiscover, DiscoverCategory } from '../hooks/useDiscover';
import StreetCredCounter from '../components/street-cred-counter/StreetCredCounter';
import carmeeterCar1 from '../assets/carmeeter-car-1-grey-transparent.png';
import { formatCarModel } from '../utils/car-utils';

const ITEMS_PER_PAGE = 21;
const ITEM_HEIGHT = 136;

const Discover: React.FC = () => {
    const navigate = useNavigate();
    const [category, setCategory] = useState<DiscoverCategory>('new');
    const [page, setPage] = useState(1);
    const [accumulatedCars, setAccumulatedCars] = useState<any[]>([]);
    const [isLoadingAccumulatedCars, setIsLoadingAccumulatedCars] = useState(true);
    const loadingRef = useRef(false);
    const observerRef = useRef<IntersectionObserver | null>(null);
    const lastItemRef = useRef<HTMLDivElement | null>(null);

    const { cars, hasMore, isLoading, error } = useDiscover({
        category,
        page,
        limit: ITEMS_PER_PAGE,
    });

    // Update accumulated cars when new data arrives
    useEffect(() => {
        if (cars) {
            if (page === 1) {
                // Only reset accumulated cars when we have new data for page 1
                setAccumulatedCars(cars);
                setTimeout(() => {
                    setIsLoadingAccumulatedCars(false);
                }, 1000);
            } else {
                // Add only new cars that aren't already in the accumulated list
                setAccumulatedCars(prev => {
                    // Create a Set of existing car IDs for efficient lookup
                    const existingCarIds = new Set(prev.map(car => car.id));
                    
                    // Filter out cars that already exist in the accumulated list
                    const newCars = cars.filter(car => !existingCarIds.has(car.id));
                    
                    // Return the combined array with only unique cars
                    return [...prev, ...newCars];
                });
            }
        }
    }, [cars, page]);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: DiscoverCategory) => {
        setIsLoadingAccumulatedCars(true);
        setPage(1); // Reset page first
        setCategory(newValue); // This will trigger a new data fetch
        setTimeout(() => {
            setIsLoadingAccumulatedCars(false);
        }, 1000);
    };

    const handleCarClick = (carId: string) => {
        navigate(`/car/${carId}`);
    };

    const loadMore = useCallback(() => {
        if (!loadingRef.current && hasMore && !isLoading) {
            loadingRef.current = true;
            setPage(prev => prev + 1);
            setTimeout(() => {
                loadingRef.current = false;
            }, 1000);
        }
    }, [hasMore, isLoading]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && !loadingRef.current) {
                    loadMore();
                }
            },
            {
                threshold: 0.1,
                rootMargin: `-${ITEM_HEIGHT}px 0px`
            }
        );
        observerRef.current = observer;

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [loadMore]);

    useEffect(() => {
        const currentObserver = observerRef.current;
        const lastItem = lastItemRef.current;

        if (currentObserver && lastItem && !isLoading && hasMore && !isLoadingAccumulatedCars) {
            currentObserver.observe(lastItem);
        }

        return () => {
            if (currentObserver && lastItem) {
                currentObserver.unobserve(lastItem);
            }
        };
    }, [accumulatedCars, isLoading, hasMore, isLoadingAccumulatedCars]);

    const renderGridItem = (car: any, isLastItem: boolean, index: number) => (
        <Grid
            item
            xs={4}
            ref={isLastItem && hasMore ? lastItemRef : null}
            key={car.id + index}
        >
            <Card
                onClick={() => handleCarClick(car.id)}
                sx={{
                    position: 'relative',
                    cursor: 'pointer',
                    '&:hover': {
                        transform: 'scale(1.02)',
                        transition: 'transform 0.2s ease-in-out',
                    },
                }}
            >
                {car.images && car.images.length > 0 ? (
                    <CardMedia
                        component='img'
                        image={process.env.REACT_APP_API_BASE_URL + car.images[0]}
                        alt={`${car.make} ${car.model}`}
                        sx={{
                            aspectRatio: 1,
                            objectFit: 'cover',
                        }}
                    />
                ) : (
                    <Box sx={{ position: 'relative' }}>
                        <CardMedia
                            component='img'
                            image={carmeeterCar1}
                            alt={`${car.make} ${car.model}`}
                            sx={(theme) => ({
                                aspectRatio: 1,
                                p: 1,
                                objectFit: 'cover',
                                backgroundColor: theme.palette.background.paper,
                            })}
                        />
                        <Box 
                            sx={{ 
                                position: 'absolute', 
                                top: 0, 
                                left: 0, 
                                width: '100%', 
                                height: '100%', 
                                display: 'flex', 
                                flexDirection: 'column', 
                                justifyContent: 'center', 
                                alignItems: 'center',
                                padding: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Pricedown',
                                    fontWeight: 'bold',
                                    color: 'white',
                                    WebkitTextStroke: '1px black',
                                    fontSize: '16pt',
                                    lineHeight: '1.0',
                                    textAlign: 'center',
                                }}
                            >
                                {car.make}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Pricedown',
                                    fontWeight: 'bold',
                                    color: 'white',
                                    WebkitTextStroke: '1px black',
                                    fontSize: '12pt',
                                    lineHeight: '1.0',
                                    textAlign: 'center',
                                }}
                            >
                                {formatCarModel(car.model)}
                            </Typography>
                        </Box>
                    </Box>
                )}
                {category === 'top' && <Box
                    sx={{
                        position: 'absolute',
                        bottom: 8,
                        right: 8,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <StreetCredCounter count={car.streetCred} />
                </Box>}
            </Card>
        </Grid>
    );

    if (error) {
        return (
            <Box sx={{ p: 3, textAlign: 'center' }}>
                <Typography color="primary">Error loading discover feed</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{
            maxWidth: '600px', margin: '0 auto'
        }}>
            <Box sx={{
                pb: 7
            }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'sticky', top: 0, bgcolor: 'background.default', zIndex: 1 }}>
                    <Tabs
                        value={category}
                        onChange={handleTabChange}
                        variant="fullWidth"
                        sx={{ minHeight: 48 }}
                    >
                        <Tab
                            icon={<NewIcon />}
                            label={'Nieuw'}
                            value="new"
                            sx={{ minHeight: 48 }}
                        />
                        <Tab
                            icon={<WhatshotIcon />}
                            label={'Trending'}
                            value="trending"
                            sx={{ minHeight: 48 }}
                        />
                        <Tab
                            icon={<TrophyIcon />}
                            label={'Top'}
                            value="top"
                            sx={{ minHeight: 48 }}
                        />
                    </Tabs>
                </Box>

                <Box sx={{ p: 1 }}>
                    <Grid container spacing={0.25}>
                        {!isLoadingAccumulatedCars && accumulatedCars.map((car, index) => renderGridItem(car, index === accumulatedCars.length - 1, index))}
                        {(isLoading || isLoadingAccumulatedCars) && Array.from({ length: ITEMS_PER_PAGE }).map((_, index) => (
                            <Grid item xs={4} key={`skeleton-${index}`}>
                                <Skeleton variant="rectangular" sx={{ aspectRatio: 1, width: '100%', height: '100%' }} />
                            </Grid>
                        ))}
                        {!isLoadingAccumulatedCars && accumulatedCars.length === 0 && <Box sx={{ display: 'flex', my: 3, justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                            <Typography>Geen auto&lsquo;s gevonden</Typography>
                        </Box>}
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default Discover; 