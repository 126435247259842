import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import FilterBar from '../components/filter-bar/FilterBar';
import EventList from '../components/event-list/EventList';
import TopBar from '../components/top-bar/TopBar';
import { Meet } from '../types/Meet';
import SortMenu from '../components/sort-menu/SortMenu';
import EventMap from '../components/event-map/EventMap';
import Footer from '../components/footer/Footer';
import CookieConsent from '../components/cookie-consent/CookieConsent';
import EventListSchema from '../components/schema/EventListSchema';
import { useStytchUser } from '@stytch/react';
import CreateMeetButton from '../components/create-meet-button/CreateMeetButton';
import { useMeets } from '../hooks/useMeets';
import { useUser, useFavoriteMeets } from '../hooks/useUser';
import * as Sentry from '@sentry/react';

function degreesToRadians(degrees: number) {
  return degrees * Math.PI / 180;
}

function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
  const earthRadiusKm = 6371;

  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(lon2 - lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusKm * c;
}

const getNextOccurrence = (date: string, recurringDay: number): Date => {
  const eventDate = new Date(date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  while (eventDate < today || eventDate.getDay() !== recurringDay) {
    eventDate.setDate(eventDate.getDate() + 1);
  }

  return eventDate;
};

const Home: React.FC = () => {
  const { meets, isLoading: meetsLoading, deleteMeet } = useMeets();
  const { user, isLoading: userLoading } = useUser();
  const { favorites, isLoading: favoritesLoading, addFavorite, removeFavorite } = useFavoriteMeets();
  const [searchQuery, setSearchQuery] = useState('');
  const [isSorting, setIsSorting] = useState(false);
  const isLoading = useMemo(() => meetsLoading, [meetsLoading]);
  const [isEventCardsLoading, setIsEventCardsLoading] = useState(true);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [showHostedMeets, setShowHostedMeets] = useState(false);
  const [highlightedMeetId, setHighlightedMeetId] = useState<string | null>(null);
  const navigate = useNavigate();
  const [showFavorites, setShowFavorites] = useState(false);
  const [userLocation, setUserLocation] = useState<{ lat: number, lng: number } | null>(null);
  const [sortOption, setSortOption] = useState<{ value: string; inverted: boolean }>({ value: 'date', inverted: false });
  const [meetNotFound, setMeetNotFound] = useState<boolean>(false);
  const [view, setView] = useState<'list' | 'map'>(() => {
    const params = new URLSearchParams(window.location.search);
    const urlView = params.get('view');
    if (urlView === 'map') return 'map';
    return 'list';
  });
  const { user: stytchUser } = useStytchUser();

  // Memoize the meets data structure
  const meetsData = useMemo(() => ({
    items: meets || [],
    timestamp: new Date().getTime()
  }), [meets]);

  // Memoize the sorting function
  const sortMeets = useCallback((items: Meet[]) => {
    const sorted = [...items].sort((a, b) => {
      const dateA = a.recurringDay !== null ? getNextOccurrence(a.date, a.recurringDay) : new Date(a.date);
      const dateB = b.recurringDay !== null ? getNextOccurrence(b.date, b.recurringDay) : new Date(b.date);
      return dateA.getTime() - dateB.getTime();
    });
    return sorted;
  }, []);

  // Memoize the sorted meets directly from the meets array
  const sortedMeets = useMemo(() =>
    sortMeets(meetsData?.items || [])
    , [meetsData, sortMeets]);

  // Memoize filter conditions
  const filterConditions = useMemo(() => ({
    searchQuery,
    selectedKeywords,
    showHostedMeets,
    showFavorites,
    favorites,
    user,
    sortOption,
    userLocation
  }), [
    searchQuery,
    selectedKeywords,
    showHostedMeets,
    showFavorites,
    favorites,
    user,
    sortOption,
    userLocation
  ]);

  // Create a stable reference for the filter function
  const filterMeet = useCallback((meet: Meet, conditions: typeof filterConditions) => {
    const matchesSearch = conditions.searchQuery === '' ||
      meet.title.toLowerCase().includes(conditions.searchQuery.toLowerCase()) ||
      meet.location.toLowerCase().includes(conditions.searchQuery.toLowerCase()) ||
      meet.description?.toLowerCase().includes(conditions.searchQuery.toLowerCase()) ||
      meet.customTitle?.toLowerCase().includes(conditions.searchQuery.toLowerCase());

    const matchesKeywords = conditions.selectedKeywords.length === 0 ||
      conditions.selectedKeywords.every(kw => meet.keywords.includes(kw));

    const matchesHostedMeets = !conditions.showHostedMeets ||
      conditions.user?.hosted_meets?.some(hostedMeet => hostedMeet.id === meet.id);

    const matchesFavorites = !conditions.showFavorites ||
      conditions.favorites.includes(meet.id);

    return matchesSearch && matchesKeywords && matchesHostedMeets && matchesFavorites;
  }, []);

  // Memoize the filtered meets with the stable filter function
  const filteredMeets = useMemo(() => {
    const filtered = sortedMeets.filter(meet => filterMeet(meet, filterConditions));

    if (filterConditions.sortOption.value === 'distance' && filterConditions.userLocation) {
      return filtered.sort((a, b) => {
        if (a.latitude && a.longitude && b.latitude && b.longitude) {
          const distanceA = calculateDistance(
            filterConditions.userLocation!.lat,
            filterConditions.userLocation!.lng,
            a.latitude,
            a.longitude
          );
          const distanceB = calculateDistance(
            filterConditions.userLocation!.lat,
            filterConditions.userLocation!.lng,
            b.latitude,
            b.longitude
          );
          return filterConditions.sortOption.inverted ? distanceB - distanceA : distanceA - distanceB;
        }
        return 0;
      });
    }

    return filtered;
  }, [sortedMeets, filterConditions, filterMeet]);

  useEffect(() => {
    // Get keywords from URL params on initial load
    const params = new URLSearchParams(window.location.search);
    const urlKeywords = params.getAll('keywords');
    const urlIsHostedMeets = params.get('user_meets') === 'true';
    if (urlKeywords.length > 0) {
      setSelectedKeywords(urlKeywords);
    }
    setShowHostedMeets(urlIsHostedMeets);
  }, []);

  const handleViewChange = (newView: 'list' | 'map') => {
    setView(newView);
    // Update URL with view parameter while preserving keywords
    const searchParams = new URLSearchParams(window.location.search);
    if (newView === 'map') {
      searchParams.set('view', 'map');
    } else {
      searchParams.delete('view');
    }

    const newUrl = `${window.location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
    window.history.replaceState(null, '', newUrl);
  };

  useEffect(() => {
    if (!meetsLoading && !isSorting && !userLoading) {
      setTimeout(() => {
        setIsEventCardsLoading(false);
      }, 100);
    } else {
      setIsEventCardsLoading(true);
    }
  }, [meetsLoading, isSorting, userLoading]);

  useEffect(() => {
    if (sortOption.value === 'distance') {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
          },
          (error) => {
            console.error('Error getting user location:', error);
          }
        );
      } else {
        console.warn('Geolocation is not available in your browser.');
        // setSortOption({ value: 'date', inverted: false });
      }
    }
  }, [sortOption]);

  const handleFavorite = async (eventId: string) => {
    if (favorites.includes(eventId)) {
      await removeFavorite(eventId);
    } else {
      await addFavorite(eventId);
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleFilterChange = (newSelectedKeywords: string[]) => {
    setSelectedKeywords(newSelectedKeywords);

    // Update URL params
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('keywords');
    newSelectedKeywords.forEach(keyword => {
      searchParams.append('keywords', keyword);
    });

    const newUrl = `${window.location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
    window.history.replaceState(null, '', newUrl);
  };

  const handleHostedMeetsChange = (showHostedMeets: boolean) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (showHostedMeets) {
      searchParams.set('user_meets', 'true');
    } else {
      searchParams.delete('user_meets');
    }
    const newUrl = `${window.location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
    window.history.replaceState(null, '', newUrl);
    setShowHostedMeets(showHostedMeets);
  };

  const handleFavoriteFilter = (showFavorites: boolean) => {
    setShowFavorites(showFavorites);
  };

  const handleScreenClick = () => {
    if (highlightedMeetId) {
      setHighlightedMeetId(null);
    }
  };

  const handleSortChange = async (option: { value: string; inverted: boolean }) => {
    setIsSorting(true);
    setSortOption(option);

    if (option.value === 'distance' && !userLocation) {
      // Get user location if sorting by distance
      try {
        await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setUserLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude
              });
              resolve(position);
            },
            (error) => reject(error)
          );
        });
      } catch (error) {
        Sentry.captureException(error);
        console.error('Error getting user location:', error);
      }
    }

    setIsSorting(false);
  };

  const handleHighlight = (id: string) => {
    setHighlightedMeetId(id);
  };

  return (
    <>
      <EventListSchema events={filteredMeets} />
      <Box sx={{
        '@media (min-width: 768px)': {
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'center',
          width: '100%',
        }
      }}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            padding: 0,
            '@media (min-width: 768px)': {
              width: '600px',
            },
            filter: meetNotFound ? 'blur(5px)' : 'none',
            pointerEvents: meetNotFound ? 'none' : 'auto'
          })}
        >
          <Box sx={{ flex: 1 }}>
            <TopBar onSearch={meets.length > 0 ? handleSearch : undefined} onFavoriteFilter={handleFavoriteFilter} view={view} onViewChange={handleViewChange} />
            {meets.length > 0 && (
              <Box sx={{ display: 'flex', alignItems: 'center', height: '48px' }}>
                <FilterBar
                  events={filteredMeets}
                  onFilterChange={handleFilterChange}
                  onHostedMeetsChange={handleHostedMeetsChange}
                  showFavorites={showFavorites}
                  favorites={favorites}
                  selectedKeywords={selectedKeywords}
                  user={user || null}
                  showHostedMeets={showHostedMeets}
                />
                {view === 'list' && <SortMenu onSortChange={handleSortChange} isLoading={isSorting} />}
              </Box>
            )}
            {view === 'list' ? (
              <EventList
                userLocation={userLocation}
                events={filteredMeets}
                searchQuery={searchQuery}
                loading={isLoading}
                eventCardsLoading={isEventCardsLoading}
                selectedKeywords={selectedKeywords}
                highlightedMeetId={highlightedMeetId}
                showFavorites={showFavorites}
                onScreenClick={handleScreenClick}
                onHighlight={handleHighlight}
                onEdit={(id: string) => navigate(`meet/${id}/edit`, { state: { from: '/' } })}
                favorites={favorites}
                handleFavorite={handleFavorite}
              />
            ) : (
              <EventMap
                events={filteredMeets}
                isLoading={isLoading}
                userLocation={userLocation}
                showFavorites={showFavorites}
                onHighlight={handleHighlight}
                onEdit={(id: string) => navigate(`/meet/${id}/edit`, { state: { from: '/' } })}
                favorites={favorites}
                handleFavorite={handleFavorite}
              />
            )}
          </Box>
          <CookieConsent />
          <Footer />
        </Box>
        {meetNotFound && (
          <Box
            sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1000,
              backgroundColor: 'background.paper',
              padding: 3,
              borderRadius: 2,
              boxShadow: 24,
              textAlign: 'center',
              maxWidth: '90%',
              width: '400px'
            }}
          >
            <Typography variant="h5" gutterBottom>
              Meet niet gevonden
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              De meet waar je naar zoekt bestaat niet of is verwijderd.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigate('/');
                setMeetNotFound(false);
              }}
            >
              Terug naar overzicht
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Home;
