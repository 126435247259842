import React from 'react';
import {
    Box,
    Grid,
    TextField,
    Typography,
    Button,
    FormControlLabel,
    Switch,
    Paper,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { CreateCarDTO, CreateModificationDTO } from '../../../types/Car';

interface ModificationsStepProps {
    formData: CreateCarDTO;
    onChange: (updates: Partial<CreateCarDTO>) => void;
}

const ModificationsStep: React.FC<ModificationsStepProps> = ({ formData, onChange }) => {
    const handleAddModification = () => {
        const newModifications = [{
            component: '',
            manufacturer: '',
            isCustom: false,
            design: ''
        }, ...(formData.modifications || [])];
        onChange({ modifications: newModifications });
    };

    const handleRemoveModification = (index: number) => {
        const newModifications = [...(formData.modifications || [])];
        newModifications.splice(index, 1);
        onChange({ modifications: newModifications });
    };

    const handleModificationChange = (index: number, updates: Partial<CreateModificationDTO>) => {
        const newModifications = [...(formData.modifications || [])];
        newModifications[index] = { ...newModifications[index], ...updates };
        onChange({ modifications: newModifications });
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Modificaties
            </Typography>
            <Typography color="text.secondary" sx={{ mb: 3 }}>
                Voeg de modificaties van je auto toe
            </Typography>

            <Box sx={{ mb: 2 }}>
                <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<AddIcon />}
                    onClick={handleAddModification}
                >
                    Modificatie Toevoegen
                </Button>
            </Box>

            {formData.modifications?.map((mod, index) => (
                <Paper key={index} sx={{ p: 2, mb: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                color='secondary'
                                fullWidth
                                label="Onderdeel"
                                value={mod.component}
                                onChange={(e) => handleModificationChange(index, { component: e.target.value })}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={mod.isCustom}
                                        onChange={(e) => handleModificationChange(index, { isCustom: e.target.checked })}
                                        color="secondary"
                                    />
                                }
                                label="Aangepast Ontwerp"
                            />
                        </Grid>

                        {mod.isCustom ? (
                            <Grid item xs={12}>
                                <TextField
                                    color='secondary'
                                    fullWidth
                                    label="Ontwerp Details"
                                    value={mod.design}
                                    onChange={(e) => handleModificationChange(index, { design: e.target.value })}
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <TextField
                                    color='secondary'
                                    fullWidth
                                    label="Fabrikant"
                                    value={mod.manufacturer}
                                    onChange={(e) => handleModificationChange(index, { manufacturer: e.target.value })}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    onClick={() => handleRemoveModification(index)}
                                    color="secondary"
                                    variant="outlined"
                                    fullWidth
                                    startIcon={<DeleteIcon />}
                                >
                                    Verwijder
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            ))}
        </Box>
    );
};

export default ModificationsStep; 