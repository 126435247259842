import useSWR from 'swr';
import { useStytch } from '@stytch/react';
import { Car } from '../types/Car';
import { defaultConfig, fetcher } from '../lib/swr';

export type DiscoverCategory = 'new' | 'trending' | 'top';

interface UseDiscoverOptions {
    category: DiscoverCategory;
    page: number;
    limit?: number;
}

interface DiscoverResponse {
    cars: Car[];
    hasMore: boolean;
    total: number;
}

export const useDiscover = ({ category, page, limit = 12 }: UseDiscoverOptions) => {
    const stytch = useStytch();
    const sessionToken = stytch.session.getTokens()?.session_token;

    const { data, error, isLoading, mutate } = useSWR<DiscoverResponse>(
        sessionToken ? [
            `${process.env.REACT_APP_API_BASE_URL}/cars/discover/${category}?page=${page}&limit=${limit}`,
            sessionToken
        ] : null,
        fetcher,
        {
            ...defaultConfig,
            keepPreviousData: true,
        }
    );

    return {
        cars: data?.cars || [],
        hasMore: data?.hasMore || false,
        total: data?.total || 0,
        error,
        isLoading,
        isError: !!error,
        mutate,
    };
}; 