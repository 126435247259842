import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Avatar, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SpeedIcon from '@mui/icons-material/Speed';
import CloseIcon from '@mui/icons-material/Close';
import { useUser } from '../hooks/useUser';
import { useCars } from '../hooks/useCars';
import { CarList } from '../components/car-list/CarList';
import TopBar from '../components/top-bar/TopBar';
import CarFormWizard from '../components/car-form/CarFormWizard';
import streetCredIconWhite from '../assets/streetcred-white.png';
import { Car } from '../types/Car';
import UserProfileSkeleton from '../components/profile/UserProfileSkeleton';
import NotFound from './NotFound';
import * as Sentry from '@sentry/react';

const ProfileHeader = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: theme.palette.background.paper,
}));

const StatsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(3),
    marginTop: theme.spacing(1),
}));

const StatItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(0.5),
    '& .MuiTypography-root': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0.5),
    },
}));

export const UserProfile = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { username } = useParams<{ username: string }>();
    const { user, getUserByUsername, isLoading: isLoadingUser } = useUser();
    const navigate = useNavigate();
    const { data: profile, error, isLoading: isLoadingUserProfile, mutate } = getUserByUsername(username?.toLowerCase() || '');
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [editingCar, setEditingCar] = useState<Car | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deletingCar, setDeletingCar] = useState<Car | null>(null);
    const { deleteCar } = useCars();
    const [isLoading, setIsLoading] = useState(true);
    const isOwnProfile = profile?.id === user?.id;

    useEffect(() => {
        if (!isLoadingUserProfile && !isLoadingUser) {
            setTimeout(() => {
                setIsLoading(false);
            }, 100);
        } else {
            setIsLoading(true);
        }
    }, [isLoadingUserProfile, isLoadingUser]);

    useEffect(() => {
        const handleRefresh = () => {
            mutate();
        };

        window.addEventListener('refreshUserProfile', handleRefresh);
        return () => {
            window.removeEventListener('refreshUserProfile', handleRefresh);
        };
    }, [mutate]);

    const handleShare = () => {
        const newUrl = `/user/${profile?.username}`;
        window.history.pushState(null, '', newUrl);
        if (navigator.share) {
            navigator.share({
                url: `${window.location.origin}/user/${profile?.username}`,
            }).catch((error) => {
                Sentry.captureException(error);
                console.error('Error sharing:', error);
            });
        }
    };

    const handleAddClick = () => {
        setShowAddDialog(true);
    };

    const handleEditClick = (car: Car) => {
        setEditingCar(car);
        setShowAddDialog(true);
    };

    const handleDeleteClick = (car: Car) => {
        setDeletingCar(car);
        setShowDeleteDialog(true);
    };

    const handleDialogClose = () => {
        setShowAddDialog(false);
        setEditingCar(null);
    };

    const handleDeleteDialogClose = () => {
        setShowDeleteDialog(false);
        setShowAddDialog(false);
        setDeletingCar(null);
    };

    const handleFormSubmit = async () => {
        await mutate();
        handleDialogClose();
    };

    const handleDelete = async () => {
        if (deletingCar) {
            try {
                await deleteCar(deletingCar.id);
                await mutate();
                handleDeleteDialogClose();
            } catch (err) {
                Sentry.captureException(err);
                console.error('Error deleting car:', err);
            }
        }
    };

    if (!isLoadingUserProfile && !isLoadingUser && profile === undefined) {
        return <NotFound />;
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center',
            pb: 2,
        }}>
            <Box sx={{
                '@media (min-width: 768px)': {
                    width: '600px',
                },
                width: '100%',
            }}>
                {!isOwnProfile && <TopBar variant='details' onBack={() => navigate(-1)} title={'Garage'} />}
                {isLoading ? <UserProfileSkeleton /> :
                    <Box
                        sx={(theme) => ({
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            p: 2,
                        })}
                    >
                        <ProfileHeader elevation={0}>
                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'space-around' }}>
                                <Box sx={{ position: 'relative', width: 'auto' }}>
                                    <Avatar sx={{ width: '100%', height: '100%', maxWidth: '150px', maxHeight: '150px', aspectRatio: '1/1' }} src={profile?.profilePicture ? `${process.env.REACT_APP_API_BASE_URL}${profile.profilePicture}` : undefined} />
                                </Box>
                                <Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography gutterBottom sx={{
                                            mb: 0.5,
                                            fontSize: isMobile ? '14pt' : '18pt',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            maxWidth: isMobile ? '50vw' : '100%',
                                        }}>
                                            {profile?.username}
                                        </Typography>
                                    </Box>
                                    {profile?.instagramHandle && (
                                        <Typography variant="body2" color="text.secondary">
                                            @{profile?.instagramHandle}
                                        </Typography>
                                    )}
                                    <StatsContainer>
                                        <StatItem>
                                            <Typography variant="body2">Auto&apos;s</Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                <DirectionsCarIcon fontSize="small" />
                                                <Typography variant="body2" fontWeight="bold">
                                                    {profile?.carCount}
                                                </Typography>
                                            </Box>
                                        </StatItem>
                                        <StatItem >
                                            <Typography variant="body2" noWrap>Totaal PK</Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                <SpeedIcon fontSize="small" />
                                                <Typography variant="body2" fontWeight="bold" noWrap>
                                                    {profile?.totalHorsepower}
                                                </Typography>
                                            </Box>
                                        </StatItem>
                                        <StatItem>
                                            <Typography variant="body2" noWrap>StreetCred</Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                <img src={streetCredIconWhite} alt="Street Cred" style={{ height: '18px', width: '18px' }} />
                                                <Typography variant="body2" fontWeight="bold">
                                                    {profile?.totalStreetCred}
                                                </Typography>
                                            </Box>
                                        </StatItem>
                                    </StatsContainer>
                                    {profile?.bio && !isMobile && (
                                        <Typography variant="body2" sx={{ mt: 2, maxWidth: '250px' }}>
                                            {profile?.bio}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            {profile?.bio && isMobile && (
                                <Typography variant="body2" sx={{ mt: 2 }}>
                                    {profile?.bio}
                                </Typography>
                            )}
                            {isOwnProfile && (
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: 1 }}>
                                    <Button
                                        size="small"
                                        fullWidth
                                        onClick={() => navigate('/profile/edit')}
                                        sx={{ mt: 2 }}
                                        variant="outlined"
                                        color="secondary"
                                    >
                                        <Typography variant="body2" fontWeight="bold">Profiel bewerken</Typography>
                                    </Button>
                                    <Button
                                        size="small"
                                        fullWidth
                                        onClick={handleShare}
                                        sx={{ mt: 2 }}
                                        variant="outlined"
                                        color="secondary"
                                    >
                                        <Typography variant="body2" fontWeight="bold">Profiel delen</Typography>
                                    </Button>
                                </Box>
                            )}
                        </ProfileHeader>

                        <Box>
                            {profile?.id && (
                                <CarList
                                    cars={profile?.cars || []}
                                    isLoading={isLoading}
                                    error={error}
                                    mode={isOwnProfile ? 'owner' : 'visitor'}
                                    onEdit={handleEditClick}
                                    onAdd={handleAddClick}
                                />
                            )}

                            <Dialog
                                open={showAddDialog}
                                onClose={handleDialogClose}
                                maxWidth="md"
                                fullWidth
                                fullScreen={isMobile}
                                color="backround.paper"
                            >
                                <IconButton
                                    onClick={handleDialogClose}
                                    sx={{
                                        position: 'absolute',
                                        top: theme.spacing(2),
                                        right: theme.spacing(2),
                                        zIndex: 1,
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <DialogTitle>
                                    <Typography variant="h5">
                                        {editingCar ? 'Bewerk Auto' : 'Voeg Auto Toe'}
                                    </Typography>
                                </DialogTitle>
                                <DialogContent>
                                    <CarFormWizard
                                        car={editingCar || undefined}
                                        onSubmit={handleFormSubmit}
                                        onCancel={handleDialogClose}
                                        onDelete={handleDeleteClick}
                                    />
                                </DialogContent>
                            </Dialog>

                            <Dialog
                                open={showDeleteDialog}
                                onClose={handleDeleteDialogClose}
                            >
                                <DialogTitle>Delete Car</DialogTitle>
                                <DialogContent>
                                    <Typography>
                                        Weet je zeker dat je {deletingCar?.make} {deletingCar?.model} wilt verwijderen?
                                        Je raakt je opgebouwde StreetCred kwijt. Deze actie kan niet ongedaan worden gemaakt.
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleDeleteDialogClose}>Cancel</Button>
                                    <Button onClick={handleDelete} color="error" variant="contained">
                                        Verwijderen
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    </Box>}
            </Box>
        </Box>
    );
};

export default UserProfile; 