import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import streetCredGold from '../../assets/streetcred-gold.png';

const CounterContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    borderRadius: '50px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(4px)',
}));

const StreetCredIcon = styled('img')({
    width: '20px',
    height: '20px',
});

interface StreetCredCounterProps {
    count: number;
}

export const StreetCredCounter: React.FC<StreetCredCounterProps> = ({ count }) => {
    return (
        <CounterContainer>
            <StreetCredIcon src={streetCredGold} alt="Street Cred" />
            <Typography variant="body2" color="white" sx={{ fontWeight: 'medium' }}>
                {count}
            </Typography>
        </CounterContainer>
    );
};

export default StreetCredCounter; 