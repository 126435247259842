import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { CreateCarDTO } from '../../../types/Car';

interface DescriptionStepProps {
    formData: CreateCarDTO;
    onChange: (updates: Partial<CreateCarDTO>) => void;
}

const DescriptionStep: React.FC<DescriptionStepProps> = ({ formData, onChange }) => {
    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
                Beschrijving
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
                Vertel iets over je auto. Wat maakt hem bijzonder? Wat is het verhaal erachter?
            </Typography>
            <TextField
                fullWidth
                multiline
                rows={6}
                variant="outlined"
                placeholder="Beschrijf je auto..."
                value={formData.description || ''}
                onChange={(e) => onChange({ description: e.target.value })}
                sx={{ mt: 2 }}
            />
        </Box>
    );
};

export default DescriptionStep; 