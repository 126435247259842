import React from 'react';
import {
    Box,
    Typography,
    Paper,
    Grid,
    CardMedia,
} from '@mui/material';
import { CreateCarDTO } from '../../../types/Car';
import { DirectionsCar as DirectionsCarIcon } from '@mui/icons-material';
import { getManufacturerLogo } from '../../../utils/imageUtils';
import ImageCarousel from '../../image-carousel/ImageCarousel';
import { formatCarModel } from '../../../utils/car-utils';
import carmeeterCar1 from '../../../assets/carmeeter-car-1-grey-transparent.png';

interface ReviewStepProps {
    formData: CreateCarDTO;
    pendingFiles: File[];
}

const ReviewStep: React.FC<ReviewStepProps> = ({ formData, pendingFiles }) => {
    // Convert pending files to object URLs for the carousel
    const allImages = [
        ...(formData.images || []),
        ...pendingFiles.map(file => URL.createObjectURL(file))
    ];

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Overzicht
            </Typography>
            <Typography color="text.secondary" sx={{ mb: 3 }}>
                Controleer je auto details
            </Typography>

            <Paper sx={{ p: 2, mb: 3 }}>
                {/* Title Section */}
                <Box sx={{ marginBottom: 4 }}>
                    <Typography
                        gutterBottom
                        sx={{
                            marginBottom: 0.1,
                            fontFamily: 'Pricedown',
                            fontWeight: 'bold',
                            color: 'white',
                            WebkitTextStroke: '1px black',
                            zIndex: 0,
                            fontSize: '32pt',
                            lineHeight: '1.0',
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        {formData.make}
                    </Typography>
                    <Typography
                        gutterBottom
                        sx={{
                            fontFamily: 'Pricedown',
                            fontWeight: 'bold',
                            color: 'white',
                            WebkitTextStroke: '1px black',
                            zIndex: 0,
                            fontSize: '22pt',
                            lineHeight: '1.0',
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        {formData.model && formatCarModel(formData.model)}
                    </Typography>
                </Box>

                {/* Image Section */}
                <Box sx={{ position: 'relative', marginBottom: 2 }}>
                    {allImages.length > 0 ? (
                        <ImageCarousel
                            images={allImages}
                            aspectRatio={Number(process.env.REACT_APP_ASPECT_RATIO)}
                            showArrows={true}
                        />
                    ) : (
                        <CardMedia
                            component="img"
                            image={carmeeterCar1}
                            sx={(theme) => ({
                                aspectRatio: Number(process.env.REACT_APP_ASPECT_RATIO),
                                p: 1,
                                objectFit: 'cover',
                                backgroundColor: theme.palette.background.default,
                            })}
                            alt={`${formData.make} ${formData.model}`}
                        />
                    )}
                </Box>

                {/* Description Section */}
                {formData.description && (
                    <Box sx={{ marginY: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Beschrijving
                        </Typography>
                        <Typography variant="body1" fontSize="13pt">
                            {formData.description}
                        </Typography>
                    </Box>
                )}

                {/* Specifications Section */}
                {(formData.year || formData.engineType || formData.horsePower || formData.torque || formData.transmission || formData.color) && (
                    <Box sx={{ marginY: 3 }}>
                        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            Specificaties
                        </Typography>
                        <Grid container spacing={1} gap={0}>
                            {formData.year && (
                                <Grid item xs={6}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, backgroundColor: 'background.default', p: 1.5, borderRadius: 1 }}>
                                        <Typography variant="h6">📅</Typography>
                                        <Box>
                                            <Typography variant="caption" color="text.secondary">Bouwjaar</Typography>
                                            <Typography variant="body2">
                                                {formData.year}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                            {formData.engineType && (
                                <Grid item xs={6}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, backgroundColor: 'background.default', p: 1.5, borderRadius: 1 }}>
                                        <Typography variant="h6">⛽️</Typography>
                                        <Box>
                                            <Typography variant="caption" color="text.secondary">Motor</Typography>
                                            <Typography variant="body2">
                                                {formData.engineDisplacement && `${formData.engineDisplacement.toFixed(1)}L`} {formData.engineType}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                            {formData.horsePower && (
                                <Grid item xs={6}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, backgroundColor: 'background.default', p: 1.5, borderRadius: 1 }}>
                                        <Typography variant="h6">🏎️</Typography>
                                        <Box>
                                            <Typography variant="caption" color="text.secondary">Vermogen</Typography>
                                            <Typography variant="body2">{formData.horsePower} PK</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                            {formData.torque && (
                                <Grid item xs={6}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, backgroundColor: 'background.default', p: 1.5, borderRadius: 1 }}>
                                        <Typography variant="h6">⚙️</Typography>
                                        <Box>
                                            <Typography variant="caption" color="text.secondary">Koppel</Typography>
                                            <Typography variant="body2">{formData.torque} NM</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                            {formData.transmission && (
                                <Grid item xs={6}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, backgroundColor: 'background.default', p: 1.5, borderRadius: 1 }}>
                                        <Typography variant="h6">🕹</Typography>
                                        <Box>
                                            <Typography variant="caption" color="text.secondary">Transmissie</Typography>
                                            <Typography variant="body2">{formData.transmission}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                            {formData.color && (
                                <Grid item xs={6}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, backgroundColor: 'background.default', p: 1.5, borderRadius: 1 }}>
                                        <Typography variant="h6">🎨</Typography>
                                        <Box>
                                            <Typography variant="caption" color="text.secondary">Kleur</Typography>
                                            <Typography variant="body2">{formData.color}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                )}

                {/* Modifications Section */}
                {formData.modifications && formData.modifications.length > 0 && (
                    <Box sx={{ marginY: 3 }}>
                        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            Modificaties
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            {formData.modifications.map((mod, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        p: 2,
                                        bgcolor: 'background.default',
                                        borderRadius: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 0.5
                                    }}
                                >
                                    <Typography variant="subtitle2" color="secondary">
                                        {mod.component}
                                    </Typography>
                                    {mod.isCustom ? (
                                        <Typography variant="body2" color="text.secondary">
                                            Custom: {mod.design}
                                        </Typography>
                                    ) : (
                                        <Typography variant="body2" color="text.secondary">
                                            {mod.manufacturer}
                                        </Typography>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}

                {/* Social Media Section */}
                {(formData.instagramUrl || formData.tiktokUrl || formData.youtubeUrl) && (
                    <Box sx={{ marginY: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Social Media
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            {formData.instagramUrl && (
                                <Typography variant="body2" color="text.secondary">
                                    Instagram: {formData.instagramUrl}
                                </Typography>
                            )}
                            {formData.tiktokUrl && (
                                <Typography variant="body2" color="text.secondary">
                                    TikTok: {formData.tiktokUrl}
                                </Typography>
                            )}
                            {formData.youtubeUrl && (
                                <Typography variant="body2" color="text.secondary">
                                    YouTube: {formData.youtubeUrl}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                )}
            </Paper>
        </Box>
    );
};

export default ReviewStep; 