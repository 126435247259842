import React, { useState, useMemo, useEffect } from 'react';
import { Paper, BottomNavigation as MuiBottomNavigation, BottomNavigationAction, IconButton, Box, useTheme } from '@mui/material';
import {
    Home as HomeIcon,
    HomeOutlined as HomeIconOutline,
    AddCircle as AddIcon,
    AddCircleOutlineOutlined as AddIconOutlined,
    DirectionsCar as CarIcon,
    DirectionsCarOutlined as CarIconOutline,
    Search as SearchIcon,
    SearchOutlined as SearchIconOutline,
    Explore as ExploreIcon,
    ExploreOutlined as ExploreIconOutline,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { User } from '../../types/User';
import AuthModal from '../auth-modal/AuthModal';
import { useStytchUser } from '@stytch/react';
import VerifyEmailModal from '../verify-email-modal/VerifyEmailModal';

const getCurrentValue = (path: string, user?: User) => {
    if (path === '/') return '/';
    if (path === '/create') return '/create';
    if (path === '/search') return '/search';
    if (path === '/discover') return '/discover';
    if (path.startsWith('/user/')) return `/user/${user?.username}`;
    return path;
};

const HIDDEN_PATHS = [
    '/login',
    '/register',
    '/reset-password',
    '/reset-password-complete',
    '/verify-email-sent',
    '/verify-email',
    '/privacy-statement',
    '/algemene-voorwaarden',
    '/winactie',
    '/profile/edit',
];

interface BottomNavigationProps {
    isDesktop?: boolean;
    isExpanded?: boolean;
    onToggleExpanded?: () => void;
}

const BottomNavigation: React.FC<BottomNavigationProps> = ({ isDesktop = false, isExpanded = true, onToggleExpanded }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useUser();
    const { user: stytchUser } = useStytchUser();
    const [currentPath, setCurrentPath] = useState(getCurrentValue(location.pathname, user));
    const [authModalMessage, setAuthModalMessage] = useState('');
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const theme = useTheme();

    const shouldShow = useMemo(() => {
        return !HIDDEN_PATHS.some(path => location.pathname.startsWith(path));
    }, [location.pathname]);

    const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
        if (newValue === '/create') {
            if (!stytchUser) {
                setAuthModalMessage('Log in om een meet aan te maken');
                setShowAuthModal(true);
                return;
            }
            if (!user?.isVerified) {
                setShowVerifyModal(true);
                return;
            }
        } else if (newValue.includes('/user')) {
            if (!stytchUser) {
                setAuthModalMessage('Log in om je profiel te bekijken');
                setShowAuthModal(true);
                return;
            }
            if (!user?.isVerified) {
                setShowVerifyModal(true);
                return;
            }
        } else if (newValue.includes('/discover')) {
            if (!stytchUser) {
                setAuthModalMessage('Log in om auto\'s en gebruikers te ontdekken');
                setShowAuthModal(true);
                return;
            }
            if (!user?.isVerified) {
                setShowVerifyModal(true);
                return;
            }
        } else if (newValue.includes('/search')) {
            if (!stytchUser) {
                setAuthModalMessage('Log in om auto\'s en gebruikers te zoeken');
                setShowAuthModal(true);
                return;
            }
            if (!user?.isVerified) {
                setShowVerifyModal(true);
                return;
            }
        }
        setCurrentPath(newValue);
        navigate(newValue);
    };

    // Update currentPath only after navigation is complete
    useEffect(() => {
        const newPath = getCurrentValue(location.pathname, user);
        if (newPath !== currentPath) {
            setCurrentPath(newPath);
        }
    }, [location.pathname, user, currentPath]);

    const navigationItems = [
        {
            value: '/',
            label: 'Home',
            icon: currentPath === '/' || currentPath.includes('/meet/') ? <HomeIcon /> : <HomeIconOutline />
        },
        {
            value: '/search',
            label: 'Zoeken',
            icon: currentPath === '/search' ? <SearchIcon /> : <SearchIconOutline />
        },
        {
            value: '/create',
            label: 'Meet aanmaken',
            icon: currentPath === '/create' ? <AddIcon /> : <AddIconOutlined />,
            testId: 'create-meet'
        },
        {
            value: '/discover',
            label: 'Ontdekken',
            icon: currentPath === '/discover' ? <ExploreIcon /> : <ExploreIconOutline />
        },
        {
            value: `/user/${user?.username}`,
            label: 'Profiel',
            icon: currentPath === `/user/${user?.username}` ? <CarIcon /> : <CarIconOutline />
        }
    ];

    if (!shouldShow) return null;

    const renderMobileNavigation = () => (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                paddingBottom: 'env(safe-area-inset-bottom)',
                zIndex: 1000,
                backgroundColor: theme.palette.background.default,
            }}
        >
            <MuiBottomNavigation
                value={currentPath}
                onChange={handleChange}
                showLabels
                sx={{
                    backgroundColor: theme.palette.background.default,
                    width: '100%',
                    '& .MuiBottomNavigationAction-root': {
                        minWidth: 'auto',
                        padding: { xs: '6px 0', sm: '6px 12px' },
                        maxWidth: { xs: '100%', sm: 168 },
                    }
                }}
            >
                {navigationItems.map((item) => (
                    <BottomNavigationAction
                        key={item.value}
                        value={item.value}
                        icon={item.icon}
                        data-testid={item.testId}
                        disableRipple
                        disableTouchRipple
                    />
                ))}
            </MuiBottomNavigation>
        </Box>
    );

    const renderDesktopNavigation = () => (
        <Paper
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                width: isExpanded ? 240 : 64,
                zIndex: 999,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                backgroundImage: 'none',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
            elevation={3}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    pt: 2,
                }}
            >
                {navigationItems.map((item) => (
                    <Box
                        key={item.value}
                        onClick={() => handleChange({} as React.SyntheticEvent, item.value)}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            p: 2,
                            cursor: 'pointer',
                            color: currentPath === item.value ? 'secondary.main' : 'text.primary',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: 40 }}>{item.icon}</Box>
                        {isExpanded && (
                            <Box sx={{ ml: 1 }}>{item.label}</Box>
                        )}
                    </Box>
                ))}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 1,
                }}
            >
                <IconButton onClick={onToggleExpanded}>
                    {isExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </Box>
        </Paper>
    );

    return (
        <>
            {isDesktop ? renderDesktopNavigation() : renderMobileNavigation()}
            <AuthModal
                open={showAuthModal}
                onClose={() => setShowAuthModal(false)}
                message={authModalMessage}
            />
            <VerifyEmailModal
                open={showVerifyModal}
                onClose={() => setShowVerifyModal(false)}
                email={user?.email}
            />
        </>
    );
};

export default BottomNavigation; 