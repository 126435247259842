import React, { useState, useEffect } from 'react';
import { useStytch } from '@stytch/react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

const ResetPasswordComplete: React.FC = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const stytch = useStytch();
    const navigate = useNavigate();
    const location = useLocation();

    const [token, setToken] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tokenParam = searchParams.get('token');
        if (tokenParam) {
            setToken(tokenParam);
        } else {
            setError('Geen reset token gevonden. Probeer opnieuw een wachtwoord reset aan te vragen.');
        }
    }, [location]);

    const handleResetPassword = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        if (password !== confirmPassword) {
            setError('Wachtwoorden komen niet overeen.');
            setIsLoading(false);
            return;
        }

        try {
            await stytch.passwords.resetByEmail({
                token: token,
                password: password,
                session_duration_minutes: 60
            });
            navigate('/login', { state: { message: 'Wachtwoord succesvol gereset. Je kunt nu inloggen met je nieuwe wachtwoord.' } });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            Sentry.captureException(error);
            setError('Er is een fout opgetreden bij het resetten van je wachtwoord. Probeer het opnieuw.');
            console.error('Password reset error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            backgroundColor: '#111418',
        }}>
            <Box
                component="form"
                onSubmit={handleResetPassword}
                sx={{
                    width: '320px',
                    backgroundColor: 'background.paper',
                    padding: 3,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Nieuw wachtwoord instellen
                </Typography>
                <TextField
                    fullWidth
                    label="Nieuw wachtwoord"
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ mb: 2 }}
                    required
                />
                <TextField
                    fullWidth
                    label="Bevestig nieuw wachtwoord"
                    variant="outlined"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    sx={{ mb: 2 }}
                    required
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isLoading}
                    sx={{ mb: 2 }}
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Wachtwoord resetten'}
                </Button>
                {error && (
                    <Typography color="primary" sx={{ marginBottom: 2, textAlign: 'center' }}>
                        {error}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default ResetPasswordComplete;
