import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    Paper,
    Avatar,
    IconButton,
    CircularProgress,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import TopBar from '../components/top-bar/TopBar';
import { validateUsername } from '../utils/user-utils';
import * as Sentry from '@sentry/react';

export const EditProfile = () => {
    const navigate = useNavigate();
    const { user, updateUser, isLoading: isLoadingUser } = useUser();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [formData, setFormData] = useState({
        username: '',
        bio: '',
    });
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | undefined>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [usernameError, setUsernameError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    useEffect(() => {
        if (user) {
            setFormData({
                username: user.username.toLowerCase(),
                bio: user.bio || '',
            });
            setPreviewUrl(user.profilePicture ? `${process.env.REACT_APP_API_BASE_URL}${user.profilePicture}` : undefined);
        }
    }, [user]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'username') {
            const newUsername = value.toLowerCase();
            if (newUsername.includes(' ')) {
                setUsernameError('Gebruikersnaam mag geen spaties bevatten.');
            } else if (!validateUsername(newUsername) && newUsername !== '') {
                setUsernameError('Gebruikersnaam mag alleen letters, cijfers en _ bevatten.');
            } else {
                setUsernameError(null);
                setFormData(prev => ({
                    ...prev,
                    [name]: newUsername
                }));
            }
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files?.length) return;

        const file = e.target.files[0];
        if (!file.type.startsWith('image/')) {
            setError('Kies een geldige bestandstype.');
            return;
        }

        setSelectedFile(file);
        const tempPreviewUrl = URL.createObjectURL(file);
        setPreviewUrl(tempPreviewUrl);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            setIsSubmitting(true);
            setError(null);

            await updateUser({
                username: formData.username,
                bio: formData.bio,
            }, selectedFile || undefined);

            navigate(`/user/${formData.username}`);
        } catch (err: any) {
            if (err.message.includes('Invalid username format or forbidden username')) {
                setError('Deze gebruikersnaam is niet beschikbaar. Kies een andere gebruikersnaam.');
            } else {
                Sentry.captureException(err);
                setError('Er ging iets mis bij het aanpassen van je profiel. Probeer het opnieuw.');
            }
            setTimeout(() => setError(null), 5000);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isLoadingUser) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!user) return null;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center',
        }}>
            <Box sx={{
                height: '100%',
                '@media (min-width: 768px)': {
                    width: '600px',
                },
            }}>
                <TopBar variant='details' onBack={() => navigate(-1)} title='Profiel bewerken' />
                <Box sx={{ p: 2 }}>
                    <Paper sx={{ p: 3 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
                            <Box sx={{ position: 'relative' }}>
                                <Avatar
                                    src={previewUrl}
                                    sx={{ width: 100, height: 100, mb: 2 }}
                                />
                                <IconButton
                                    color="secondary"
                                    aria-label="upload picture"
                                    component="label"
                                    sx={{
                                        position: 'absolute',
                                        bottom: 16,
                                        right: -8,
                                        backgroundColor: 'primary.main',
                                        '&:hover': { backgroundColor: 'primary.main' }
                                    }}
                                >
                                    <input
                                        hidden
                                        accept="image/*"
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileSelect}
                                    />
                                    <PhotoCamera />
                                </IconButton>
                            </Box>
                        </Box>

                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                label="Username"
                                name="username"
                                value={formData.username}
                                onChange={handleInputChange}
                                margin="normal"
                                // error={!!usernameError}
                                helperText={usernameError}
                                inputProps={{
                                    autoCapitalize: 'none',
                                    autoCorrect: 'off',
                                    maxLength: 30,
                                    pattern: '[a-z0-9_]*'
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        textAlign: 'right',
                                        mr: 0,
                                        color: error ? 'error.main' : 'text.secondary'
                                    }
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Bio"
                                name="bio"
                                value={formData.bio}
                                onChange={handleInputChange}
                                margin="normal"
                                multiline
                                rows={3}
                                inputProps={{ maxLength: 150 }}
                                helperText={`${formData.bio.length}/150`}
                                FormHelperTextProps={{
                                    sx: {
                                        textAlign: 'right',
                                        mr: 0,
                                        color: 'text.secondary'
                                    }
                                }}
                            />

                            {error && (
                                <Typography
                                    variant="body2"
                                    color="primary"
                                    sx={{
                                        mt: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    {error}
                                </Typography>
                            )}

                            {successMessage && (
                                <Typography
                                    variant="body2"
                                    color="success.main"
                                    sx={{
                                        mt: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    {successMessage}
                                </Typography>
                            )}

                            <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                disabled={isSubmitting}
                                sx={{ mt: 3 }}
                            >
                                {isSubmitting ? <CircularProgress size={24} /> : 'Opslaan'}
                            </Button>
                        </form>
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
};

export default EditProfile; 