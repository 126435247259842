import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Card,
    CardMedia,
    Typography,
    Box,
    Button,
    Grid,
    IconButton,
    Tooltip,
} from '@mui/material';
import {
    Speed as SpeedIcon,
    Instagram as InstagramIcon,
    YouTube as YouTubeIcon,
    ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import TikTokIcon from '../../icons/TikTokIcon';
import { Car } from '../../types/Car';
import carmeeterCar1 from '../../assets/carmeeter-car-1-grey-transparent.png';
import { useCars } from '../../hooks/useCars';
import StreetCredButton from '../street-cred-button/StreetCredButton';
import StreetCredCounter from '../street-cred-counter/StreetCredCounter';
import ImageCarousel from '../image-carousel/ImageCarousel';
import ImagePreviewModal from '../image-preview/ImagePreviewModal';
import { formatCarModel } from '../../utils/car-utils';
import BuildIcon from '@mui/icons-material/Build';
import * as Sentry from '@sentry/react';

interface CarCardProps {
    car: Car;
    onEdit?: () => void;
    showActions?: boolean;
}

const CarCard: React.FC<CarCardProps> = ({ car, onEdit, showActions = true }) => {
    const navigate = useNavigate();
    const { voteStreetCred, isVoteProcessing, car: cachedCar } = useCars({ carId: car.id });
    const [showImagePreview, setShowImagePreview] = useState(false);

    const currentCar = cachedCar || car;

    const handleCardClick = (e: React.MouseEvent) => {
        if (
            e.target instanceof HTMLElement &&
            (e.target.closest('button') ||
                e.target.closest('[role="menuitem"]') ||
                e.target.closest('[role="menu"]'))
        ) {
            return;
        }
        navigate(`/car/${currentCar.id}`);
    };

    const handleStreetCredVote = async () => {
        if (!currentCar?.id) {
            console.error('Car ID is undefined');
            return;
        }

        try {
            await voteStreetCred(currentCar.id);
        } catch (error) {
            console.error('Error voting street cred:', error);
            Sentry.captureException(error);
        }
    };

    const renderSocialMediaButtons = () => {
        if (!currentCar.instagramUrl && !currentCar.tiktokUrl && !currentCar.youtubeUrl) {
            return null;
        }

        return (
            <Box sx={{ display: 'flex', gap: 1 }}>
                {currentCar.instagramUrl && (
                    <Tooltip title="Instagram">
                        <IconButton
                            sx={{ p: 0 }}
                            size="small"
                            color="secondary"
                            onClick={() => window.open(currentCar.instagramUrl, '_blank')}
                        >
                            <InstagramIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {currentCar.tiktokUrl && (
                    <Tooltip title="TikTok">
                        <IconButton
                            sx={{ p: 0 }}
                            size="small"
                            color="secondary"
                            onClick={() => window.open(currentCar.tiktokUrl, '_blank')}
                        >
                            <TikTokIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {currentCar.youtubeUrl && (
                    <Tooltip title="YouTube">
                        <IconButton
                            sx={{ p: 0 }}
                            size="small"
                            color="secondary"
                            onClick={() => window.open(currentCar.youtubeUrl, '_blank')}
                        >
                            <YouTubeIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        );
    };

    const renderPowerPill = () => {
        if (!currentCar.horsePower) return null;
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                    borderRadius: '50px',
                    padding: '4px 8px',
                }}
            >
                <SpeedIcon sx={{ fontSize: 16 }} />
                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                    {currentCar.horsePower} PK
                </Typography>
            </Box>
        );
    };

    return (
        <Card
            onClick={handleCardClick}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                bgcolor: 'background.paper',
                cursor: 'pointer',
                WebkitTapHighlightColor: 'transparent',
                position: 'relative',
                '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                },
            }}
        >
            <Box sx={{ position: 'relative', aspectRatio: Number(process.env.REACT_APP_ASPECT_RATIO) }}>
                {currentCar.images && currentCar.images.length > 0 ? (
                    <ImageCarousel
                        images={currentCar.images}
                        aspectRatio={Number(process.env.REACT_APP_ASPECT_RATIO)}
                    />
                ) : (
                    <CardMedia
                        component="img"
                        image={carmeeterCar1}
                        sx={(theme) => ({
                            aspectRatio: Number(process.env.REACT_APP_ASPECT_RATIO),
                            p: 1,
                            objectFit: 'cover',
                            border: `8px solid ${theme.palette.background.paper}`,
                            backgroundColor: theme.palette.background.default,
                        })}
                        alt={`${currentCar.make} ${currentCar.model}`}
                    />
                )}
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 8,
                        left: 8,
                        display: 'flex',
                        gap: 1
                    }}
                >
                    {renderPowerPill()}
                </Box>
                <Box sx={{ position: 'absolute', bottom: 8, right: 8 }}>
                    <StreetCredCounter count={currentCar.streetCred} />
                </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, p: 1, pb: 1 }}>
                <Box sx={{ p: 1, width: '100%' }}>
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '11pt',
                            mb: 0.5
                        }}
                    >
                        {currentCar.make.charAt(0) + currentCar.make.toLowerCase().slice(1)} {formatCarModel(currentCar.model)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {currentCar.year} {currentCar.engineType && `• ${currentCar.engineType}`} {currentCar.engineDisplacement && `• ${currentCar.engineDisplacement.toFixed(1)}L`}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', pt: 2 }}>
                        {renderSocialMediaButtons()}
                    </Box>

                </Box>
                <Grid container sx={{ p: 1 }}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', gap: 1, flexDirection: 'column', }}>

                        {showActions ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onEdit}
                                startIcon={<BuildIcon />}
                            >
                                <Typography variant="body2" fontWeight="bold">Bewerken</Typography>
                            </Button>
                        ) : (

                            <StreetCredButton
                                fullWidth
                                hasVoted={currentCar.hasVoted || false}
                                onVote={handleStreetCredVote}
                                disabled={isVoteProcessing(currentCar.id)}
                            />
                        )}
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={() => navigate(`/car/${currentCar.id}`)}
                            endIcon={<ArrowForwardIcon />}
                            size="small"
                        >
                            Details
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            {currentCar.images && currentCar.images.length > 0 && (
                <ImagePreviewModal
                    open={showImagePreview}
                    onClose={() => setShowImagePreview(false)}
                    images={currentCar.images}
                    initialIndex={0}
                />
            )}
        </Card>
    );
};

export default CarCard; 