import { useStytch } from '@stytch/react';
import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
export const ACTIVITY_TIMEOUT = 14 * 24 * 60; // 14 days in minutes
const ACTIVITY_CHECK_INTERVAL = 5 * 60 * 1000; // Check every 5 minutes

export const useSession = () => {
    const stytch = useStytch();

    const handleSessionExpired = useCallback(() => {
        localStorage.removeItem('staySignedIn');
        stytch.session.revoke();
        window.location.href = '/login';
    }, [stytch.session]);

    const resetActivityTimer = useCallback(async () => {
        try {
            const sessionToken = stytch.session.getTokens()?.session_token;
            if (sessionToken) {
                await stytch.session.authenticate({
                    session_duration_minutes: ACTIVITY_TIMEOUT
                });
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error('Failed to extend session:', error);
            handleSessionExpired();
        }
    }, [stytch.session, handleSessionExpired]);

    const startActivityMonitoring = useCallback(() => {

        localStorage.setItem('staySignedIn', 'true');
        let lastActivity = Date.now();

        const handleActivity = () => {
            lastActivity = Date.now();
        };

        const activityEvents = ['mousedown', 'keydown', 'scroll', 'touchstart'];
        activityEvents.forEach(event => {
            window.addEventListener(event, handleActivity);
        });

        const intervalId = setInterval(async () => {
            const inactiveTime = Date.now() - lastActivity;
            if (inactiveTime < ACTIVITY_CHECK_INTERVAL) {
                await resetActivityTimer();
            }
        }, ACTIVITY_CHECK_INTERVAL);

        return () => {
            activityEvents.forEach(event => {
                window.removeEventListener(event, handleActivity);
            });
            clearInterval(intervalId);
        };
    }, [resetActivityTimer]);

    return {
        startActivityMonitoring,
        handleSessionExpired
    };
}; 