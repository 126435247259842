import React from 'react';
import { Box, Typography, Button, IconButton, Modal, Divider, Chip, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStytch } from '@stytch/react';
import FullScreenLoader from '../loader/FullScreenLoader';
import { useUser } from '../../hooks/useUser';
import ContactButtons from '../contact-buttons/ContactButtons';
import InstagramIcon from '@mui/icons-material/Instagram';
import VerifiedIcon from '@mui/icons-material/Verified';
import EventIcon from '@mui/icons-material/Event';
import GroupsIcon from '@mui/icons-material/Groups';
import StarIcon from '@mui/icons-material/Star';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { useNavigate } from 'react-router-dom';

type ProfileModalProps = {
    open: boolean;
    onClose: () => void;
}

const ProfileModal: React.FC<ProfileModalProps> = ({ open, onClose }) => {
    const stytch = useStytch();
    const { user } = useUser();
    const navigate = useNavigate();

    const handleLogout = () => {
        stytch.session.revoke();
        onClose();
    };

    const handleGarageClick = () => {
        if (user?.username) {
            navigate(`/user/${user.username}`);
            onClose();
        }
    };


    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby='profile-modal-title'
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: { xs: '95%', sm: 360 },
                maxHeight: '90vh',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: { xs: 2, sm: 2.5 },
                pt: 1,
                borderRadius: 2,
                outline: 'none',
                overflowY: 'auto',
            }}>
                <Box sx={{ display: 'flex', width: '100%', mb: 2, justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography id="profile-modal-title" variant="h6" component="h2" sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem' } }}>
                        Profiel
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        size="small"
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                {!user ? <FullScreenLoader /> : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                        {/* User Info Section */}
                        <Box sx={{
                            bgcolor: 'background.default',
                            p: 1.5,
                            borderRadius: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 0.5
                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Avatar src={user.profilePicture ? `${process.env.REACT_APP_API_BASE_URL}${user.profilePicture}` : undefined} sx={{ bgcolor: 'primary.main', width: 48, height: 48 }} />
                                <Box sx={{ minWidth: 0 }}>
                                    <Typography variant="body2" sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        mb: 1
                                    }}>
                                        {user.username}
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, flexWrap: 'wrap', mt: 0.5 }}>
                                        {user.isVerified && (
                                            <Chip
                                                size="small"
                                                icon={<VerifiedIcon sx={{ fontSize: 16 }} />}
                                                label="Geverifieerd"
                                                sx={{ height: 24, p: 1 }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        {/* Stats Section */}
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr)',
                            gap: 1
                        }}>
                            <Box sx={{
                                bgcolor: 'background.default',
                                p: 1,
                                borderRadius: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                                <EventIcon color="secondary" sx={{ fontSize: 20, mb: 0.5 }} />
                                <Typography variant="h6" sx={{ fontSize: '1.1rem', lineHeight: 1 }}>{user.hosted_meets?.length || 0}</Typography>
                                <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem' }}>Georganiseerd</Typography>
                            </Box>
                            <Box sx={{
                                bgcolor: 'background.default',
                                p: 1,
                                borderRadius: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                                <GroupsIcon color="secondary" sx={{ fontSize: 20, mb: 0.5 }} />
                                <Typography variant="h6" sx={{ fontSize: '1.1rem', lineHeight: 1 }}>{user.attended_meets?.length || 0}</Typography>
                                <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem' }}>Bezocht</Typography>
                            </Box>
                            <Box sx={{
                                bgcolor: 'background.default',
                                p: 1,
                                borderRadius: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                                <StarIcon color="secondary" sx={{ fontSize: 20, mb: 0.5 }} />
                                <Typography variant="h6" sx={{ fontSize: '1.1rem', lineHeight: 1 }}>{user.reviews?.length || 0}</Typography>
                                <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem' }}>Reviews</Typography>
                            </Box>
                        </Box>

                        {/* Social Links */}
                        {user.instagramHandle && (
                            <Box sx={{
                                bgcolor: 'background.default',
                                p: 1.5,
                                borderRadius: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                            }}>
                                <InstagramIcon color="secondary" sx={{ fontSize: 20 }} />
                                <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                                    {user.instagramHandle}
                                </Typography>
                            </Box>
                        )}

                        <Divider sx={{ my: 0.5 }} />

                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleLogout}
                            fullWidth
                            size="small"
                        >
                            Uitloggen
                        </Button>

                        <ContactButtons />
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default ProfileModal;