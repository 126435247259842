import React from 'react';
import L from 'leaflet';
import { useTheme } from '@mui/material';

// Original L.divIcon version
export const createMapMarkerIcon = (theme: any) => {
    return L.divIcon({
        html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30"><path fill="${theme.palette.primary.main}" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z"/><path fill="${theme.palette.secondary.main}" d="M12 11.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>`,
        className: 'custom-div-icon',
        iconSize: [30, 30],
        iconAnchor: [15, 30],
    });
};

// New React component version
interface MarkerIconProps {
    className?: string;
}

export const MarkerIcon: React.FC<MarkerIconProps> = ({ className = 'custom-div-icon' }) => {
    const theme = useTheme();

    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" 
            width="30" 
            height="30"
            className={className}
            style={{
                position: 'relative',
            }}
        >
            <path 
                fill={theme.palette.primary.main} 
                d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z"
            />
            <path 
                fill={theme.palette.secondary.main} 
                d="M12 11.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
            />
        </svg>
    );
};

export default MarkerIcon;