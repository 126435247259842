import React, { useState, useCallback, useEffect } from 'react';
import { Button, debounce, Typography, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useStytchUser } from '@stytch/react';
import AuthModal from '../auth-modal/AuthModal';
import VerifyEmailModal from '../verify-email-modal/VerifyEmailModal';
import { useMeets } from '../../hooks/useMeets';
import { useUser } from '../../hooks/useUser';
import { getManufacturerLogo } from '../../utils/imageUtils';
import { Car } from '../../types/Car';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { formatCarModel } from '../../utils/car-utils';
import * as Sentry from '@sentry/react';
type AttendButtonProps = {
    meetId: string;
    isAttending: boolean;
    attendeesCount: number;
    onAttendChange: (isAttending: boolean, newAttendeesCount: number, selectedCar?: Car) => void;
    loading?: boolean;
    meetDate: Date;
    isUserVerified: boolean;
    email?: string;
}

const AttendButton: React.FC<AttendButtonProps> = ({ meetId, isAttending, isUserVerified, attendeesCount, onAttendChange, loading, meetDate, email }) => {
    const { attendMeet, unattendMeet } = useMeets();
    const [isAttended, setIsAttended] = useState(isAttending);
    const [animateAttend, setAnimateAttend] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
    const [isProcessing, setIsProcessing] = useState(true);
    const { user: stytchUser } = useStytchUser();
    const { user } = useUser();
    const isMeetStarted = new Date() >= new Date(meetDate);
    const [showCarSelectionDialog, setShowCarSelectionDialog] = useState(false);

    // Add useEffect to sync with parent's isAttending prop
    useEffect(() => {
        setIsAttended(isAttending);
    }, [isAttending, stytchUser]);

    useEffect(() => {
        setIsProcessing(loading || false);
    }, [loading]);

    // Debounced version of the attendance update
    const debouncedAttendanceUpdate = useCallback(
        debounce(async (shouldAttend: boolean, car?: Car) => {
            try {
                if (shouldAttend) {
                    await attendMeet(meetId, meetDate, car?.id);
                    onAttendChange(true, attendeesCount + 1, car);
                } else {
                    await unattendMeet(meetId, meetDate);
                    onAttendChange(false, attendeesCount - 1);
                }
            } catch (error) {
                setIsAttended(!shouldAttend);
                Sentry.captureException(error);
                console.error('Failed to update attendance:', error);
            } finally {
                setIsProcessing(false);
            }
        }, 500),
        [meetId, attendeesCount, meetDate]
    );

    const handleAttendClick = async () => {
        if (isMeetStarted) return;

        if (stytchUser) {
            if (isProcessing) return;
            if (!isUserVerified) {
                setShowVerifyEmailModal(true);
                return;
            }

            // If user has multiple cars and is not already attending, show car selection dialog
            if (!isAttended && user?.cars && user.cars.length > 1) {
                setShowCarSelectionDialog(true);
                return;
            }

            setIsProcessing(true);
            setAnimateAttend(false);
            setIsAttended(!isAttended);
            setAnimateAttend(true);

            debouncedAttendanceUpdate(!isAttended);
        } else {
            setShowAuthModal(true);
        }
    };

    const handleCarSelect = (car: Car) => {
        setShowCarSelectionDialog(false);

        setIsProcessing(true);
        setAnimateAttend(false);
        setIsAttended(true);
        setAnimateAttend(true);

        debouncedAttendanceUpdate(true, car);
    };

    return (
        <>
            <Button
                data-testid="attend-button"
                endIcon={isMeetStarted ? (
                    <Typography sx={{ fontSize: '12pt' }}>🏁</Typography>
                ) : !isAttended || !stytchUser ? (
                    <CheckBoxOutlineBlankIcon sx={{ fontSize: '12pt', height: '14pt', width: '14pt' }} />
                ) : (
                    <CheckBoxIcon
                        sx={{
                            fontSize: '12pt',
                            height: '14pt',
                            width: '14pt',
                            animation: animateAttend ? 'bounceAttend 0.3s' : 'none',
                            '@keyframes bounceAttend': {
                                '0%': { transform: 'perspective(400px) scale(0)' },
                                '50%': { transform: 'perspective(400px) scale(1.5)' },
                                '100%': { transform: 'perspective(400px) scale(1)' },
                            }
                        }}
                    />
                )}
                aria-label="attend meet"
                onClick={handleAttendClick}
                variant={stytchUser && (isAttended || isMeetStarted) ? 'contained' : 'outlined'}
                color={stytchUser && (isAttended || isMeetStarted) ? 'primary' : 'secondary'}
                disabled={isProcessing || isMeetStarted}
                sx={{
                    minWidth: '140px',
                    alignItems: 'center',
                    height: '38px',
                    '&.Mui-disabled': isMeetStarted ? {
                        color: 'secondary.main'
                    } : {}
                }}
            >
                <Typography fontWeight="bold">{isMeetStarted ? 'Meet gestart' : 'Aanwezig'}</Typography>
            </Button>
            <AuthModal
                open={showAuthModal}
                onClose={() => setShowAuthModal(false)}
                message={'Log in om deel te nemen'}
            />
            {
                email && (
                    <VerifyEmailModal
                        open={showVerifyEmailModal}
                        onClose={() => setShowVerifyEmailModal(false)}
                        email={email}
                    />
                )
            }
            <Dialog
                open={showCarSelectionDialog}
                onClose={() => setShowCarSelectionDialog(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Met welke auto ben je aanwezig?</DialogTitle>
                <DialogContent>
                    <List>
                        {user?.cars?.map((car) => (
                            <ListItem
                                key={car.id}
                                onClick={() => handleCarSelect(car)}
                                sx={{
                                    borderRadius: 1,
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 180, 230, 0.1)'
                                    }
                                }}
                            >
                                <ListItemAvatar>
                                    {getManufacturerLogo(car.make) !== undefined ? (
                                        <img
                                            src={getManufacturerLogo(car.make)}
                                            alt={car.make}
                                            style={{
                                                backgroundColor: 'transparent',
                                                objectFit: 'contain',
                                                width: 40,
                                                height: 40
                                            }}
                                        />
                                    ) : (
                                        <DirectionsCarIcon sx={{ fontSize: '40px', p: 0.5 }} />
                                    )}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${car.make} ${formatCarModel(car.model)}`}
                                    secondary={car.year ? `${car.year}` : ''}
                                />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowCarSelectionDialog(false)} color="secondary">
                        Annuleren
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AttendButton;