import logotypes from '../assets/json/vehicle-logotypes.json';

interface Logotype {
    uri: string;
    width: number;
    height: number;
    mime: string;
    transparent: boolean;
    size: number;
}

interface LogotypeData {
    name: string;
    code: string | null;
    logotype: Logotype;
    alternatives: Logotype[];
}

/**
 * Gets the manufacturer logo URL for a given brand name
 * @param brand The vehicle brand/make name
 * @param preferAlternative Whether to prefer the alternative (larger) logo if available
 * @returns The URL of the manufacturer logo, or undefined if not found
 */
export const getManufacturerLogo = (name: string, preferAlternative = false): string | undefined => {
    // Convert manufacturer name to lowercase and normalize it
    const normalizedName = name.toLowerCase().replace(/\s+/g, '-');

    // Find the manufacturer in the logotypes data
    const manufacturerData = Object.entries(logotypes).find(([key, data]) =>
        key.toLowerCase() === normalizedName ||
        (data as LogotypeData).name.toLowerCase() === name.toLowerCase()
    );

    if (!manufacturerData) {
        return undefined;
    }

    const [, data] = manufacturerData as [string, LogotypeData];

    // If we prefer alternative and it exists, use the first alternative
    if (preferAlternative && data.alternatives && data.alternatives.length > 0) {
        return data.alternatives[0].uri;
    }

    // Otherwise use the default logotype
    return data.logotype.uri;
};

/**
 * Gets the full URL for an image stored on the backend
 * @param imageUrl The relative image URL from the backend
 * @returns The complete image URL including the API base URL
 */
export const getFullImageUrl = (imageUrl: string | undefined): string | undefined => {
    if (!imageUrl) return undefined;
    return `${process.env.REACT_APP_API_BASE_URL}${imageUrl}`;
}; 