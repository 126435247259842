import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/fonts/fonts.css';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import Home from './pages/Home';
import CreateMeet from './pages/CreateMeet';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';  // Adjust the path as needed
import themeRed from './theme-red';
import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';
import Login from './pages/Login'; // Make sure to create this component
import PrivacyStatement from './pages/PrivacyStatement';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordComplete from './pages/ResetPasswordComplete';
import MeetDetails from './pages/MeetDetails';
import CarDetails from './pages/CarDetails';
import TermsAndConditions from './pages/TermsAndConditions';
import VerifyEmailSent from './pages/VerifyEmailSent';
import VerifyEmail from './pages/VerifyEmail';
import Winactie from './pages/Winactie';
import NotFound from './pages/NotFound';
import UserProfile from './pages/UserProfile';
import EditProfile from './pages/EditProfile';
import Layout from './components/layout/Layout';
import Search from './pages/Search';
import Discover from './pages/Discover';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://47fbdf739842fb76533714e4a1b877fa@o4508915789201408.ingest.de.sentry.io/4508915791036496',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/carmeeter\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const stytch = new StytchUIClient(process.env.REACT_APP_STYTCH_PUBLIC_TOKEN || '');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <StytchProvider stytch={stytch}>
      <ThemeProvider theme={themeRed}>
        <CssBaseline />
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/create" element={<CreateMeet />} />
              <Route path="/search" element={<Search />} />
              <Route path="/discover" element={<Discover />} />
              <Route path="/meet/:id" element={<MeetDetails />} />
              <Route path="/meet/:id/edit" element={<CreateMeet />} />
              <Route path="/car/:id" element={<CarDetails />} />
              <Route path="/user/:username" element={<UserProfile />} />
              <Route path="/profile/edit" element={<EditProfile />} />
              <Route path="/login" element={<Login />} />
              <Route path="/privacy-statement" element={<PrivacyStatement />} />
              <Route path="/algemene-voorwaarden" element={<TermsAndConditions />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/reset-password-complete" element={<ResetPasswordComplete />} />
              <Route path="/verify-email-sent" element={<VerifyEmailSent />} />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/winactie" element={<Winactie />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </ThemeProvider>
    </StytchProvider>
  </React.StrictMode>
);
