import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStytch } from '@stytch/react';
import { useUser } from '../hooks/useUser';
import FullScreenLoader from '../components/loader/FullScreenLoader';
import * as Sentry from '@sentry/react';
const VerifyEmailSent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const stytch = useStytch();
    const [message, setMessage] = useState(location.state?.message || '');
    const { user, isLoading } = useUser();

    const handleResendEmail = async () => {
        const baseUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL || window.location.origin;
        const verifyUrl = new URL('/verify-email', baseUrl).toString();
        if (!user?.email) {
            return;
        }
        try {
            await stytch.magicLinks.email.send(
                user.email,
                {
                    login_magic_link_url: verifyUrl,
                    signup_magic_link_url: verifyUrl,
                    login_expiration_minutes: 60,
                    signup_expiration_minutes: 60
                }
            );
            setMessage('Nieuwe verificatie e-mail verzonden. Controleer je inbox.');
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error resending verification email:', error);
            setMessage('Er ging iets mis. Probeer het opnieuw.');
        }
    };

    return (
        <>
            {isLoading && <FullScreenLoader />}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                backgroundColor: '#111418',
                padding: 3
            }}>
                <Box sx={{
                    backgroundColor: 'background.paper',
                    padding: 3,
                    borderRadius: 2,
                    maxWidth: 400,
                    width: '100%'
                }}>
                    <Typography variant="h5" gutterBottom align="center">
                        Verifieer je e-mail
                    </Typography>
                    <Typography align="center" sx={{ mb: 3 }}>
                        {message || `We hebben een verificatie link naar ${location.state?.email || user?.email} verzonden. Controleer je e-mail om je account te verifiëren.`}
                    </Typography>
                    {location.state.action && <Button
                        fullWidth
                        variant="contained"
                        onClick={handleResendEmail}
                        sx={{ mb: 2 }}
                    >
                        Nieuwe verificatie e-mail verzenden
                    </Button>}
                    <Button
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate('/')}
                    >
                        Terug
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default VerifyEmailSent; 