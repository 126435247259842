import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Button,
    CircularProgress,
    InputAdornment,
    Tabs,
    Tab
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useUser } from '../hooks/useUser';
import carmeetercar from '../assets/carmeeter-car-1-grey-transparent.png';
import { formatCarModel } from '../utils/car-utils';
import * as Sentry from '@sentry/react';

type SearchTab = 'cars' | 'users';

const Search = () => {
    const navigate = useNavigate();
    const { searchUsers, searchCars, lastVisited, clearLastVisited, addToLastVisited, lastVisitedUsers, clearLastVisitedUsers, addToLastVisitedUsers } = useUser();
    const [query, setQuery] = useState('');
    const [submittedCarQuery, setSubmittedCarQuery] = useState('');
    const [submittedUserQuery, setSubmittedUserQuery] = useState('');
    const [userResults, setUserResults] = useState<any[]>([]);
    const [carResults, setCarResults] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<SearchTab>('cars');

    const handleSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!query.trim()) return;

        if (activeTab === 'users') {
            setUserResults([]);
        }

        if (activeTab === 'cars') {
            setCarResults([]);
        }

        setIsLoading(true);
        setError(null);

        try {
            if (activeTab === 'users') {
                setSubmittedUserQuery(query);
                setSubmittedCarQuery(''); // Clear car query when searching users
                const results = await searchUsers(query);
                setUserResults(results);
                setCarResults([]);
            } else {
                setSubmittedCarQuery(query);
                setSubmittedUserQuery(''); // Clear user query when searching cars
                const results = await searchCars(query);
                setCarResults(results);
                setUserResults([]);
            }
        } catch (err) {
            Sentry.captureException(err);
            setError('Failed to search. Please try again.');
            console.error('Search error:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCarClick = async (carId: string) => {
        try {
            navigate(`/car/${carId}`);
            await addToLastVisited(carId);
        } catch (err) {
            Sentry.captureException(err);
            console.error('Error adding to last visited:', err);
        }
    };

    const handleUserClick = async (username: string, userId: string) => {
        try {
            navigate(`/user/${username}`);
            await addToLastVisitedUsers(userId);
        } catch (err) {
            Sentry.captureException(err);
            console.error('Error adding to last visited users:', err);
        }
    };

    const handleTabChange = (_event: React.SyntheticEvent, newValue: SearchTab) => {
        setActiveTab(newValue);
        setError(null);
        setQuery(''); // Clear the search input when switching tabs
        // Don't clear the results - they'll be hidden by the tab switch
    };

    const renderCarItem = (car: any, username: string, profilePicture?: string) => (
        <ListItem
            key={car.id}
            button
            onClick={() => handleCarClick(car.id)}
            sx={{
                borderRadius: 1,
                mb: 1,
                bgcolor: 'background.paper'
            }}
        >
            <ListItemAvatar>
                <Avatar
                    variant="rounded"
                    sx={{ width: 56, height: 56, borderRadius: 1 }}
                >
                    <img
                        src={car?.images?.length > 0 ? `${process.env.REACT_APP_API_BASE_URL}${car.images[0]}` : carmeetercar}
                        alt={`${car.make} ${car.model}`}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={`${car.make[0].toUpperCase() + car.make.slice(1).toLowerCase()} ${formatCarModel(car.model)}`}
                secondary={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Avatar
                            src={profilePicture ? `${process.env.REACT_APP_API_BASE_URL}${profilePicture}` : undefined}
                            sx={{ width: 20, height: 20 }}
                        />
                        <Typography variant="body2" color="text.secondary">
                            {username}
                        </Typography>
                    </Box>
                }
                sx={{ ml: 2 }}
            />
        </ListItem>
    );

    const renderUserItem = (user: any) => (
        <ListItem
            key={user.id}
            button
            onClick={() => handleUserClick(user.username, user.id)}
            sx={{
                borderRadius: 1,
                mb: 1,
                bgcolor: 'background.paper'
            }}
        >
            <ListItemAvatar>
                <Avatar
                    src={user.profilePicture ? `${process.env.REACT_APP_API_BASE_URL}${user.profilePicture}` : undefined}
                    sx={{ width: 56, height: 56 }}
                />
            </ListItemAvatar>
            <ListItemText
                primary={user.username}
                secondary={`${user.totalCars} auto${user.totalCars !== 1 ? '\'s' : ''}`}
                sx={{ ml: 2 }}
            />
        </ListItem>
    );

    const renderSearchResults = () => {
        const results = activeTab === 'users' ? userResults : carResults;
        const submittedQuery = activeTab === 'users' ? submittedUserQuery : submittedCarQuery;

        // If there's a submitted query, show search results or "no results found" message
        if (submittedQuery) {
            if (!results.length && !isLoading) {
                return (
                    <Typography color="text.secondary" sx={{ textAlign: 'center', mt: 4 }}>
                        {activeTab === 'cars' ? 'Geen auto\'s gevonden' : 'Geen gebruikers gevonden'}
                    </Typography>
                );
            }

            return (
                <List sx={{ mb: 4 }}>
                    {activeTab === 'users' ? (
                        results.map(user => renderUserItem(user))
                    ) : (
                        results.map((result) => (
                            result.cars.map((car: any) => renderCarItem(car, result.username, result.profilePicture))
                        ))
                    )}
                </List>
            );
        }

        // If no submitted query, show last visited items
        if (activeTab === 'cars' && lastVisited.length > 0) {
            return (
                <Box sx={{ mt: 4 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6">Recent bekeken auto&apos;s</Typography>
                        <Button
                            onClick={clearLastVisited}
                            color="primary"
                        >
                            Wissen
                        </Button>
                    </Box>
                    <List>
                        {lastVisited.map((car) => renderCarItem(car, car.owner.username, car.owner.profilePicture))}
                    </List>
                </Box>
            );
        } else if (activeTab === 'users' && lastVisitedUsers.length > 0) {
            return (
                <Box sx={{ mt: 4 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6">Recent bekeken gebruikers</Typography>
                        <Button
                            onClick={clearLastVisitedUsers}
                            color="primary"
                        >
                            Wissen
                        </Button>
                    </Box>
                    <List>
                        {lastVisitedUsers.map((user) => renderUserItem(user))}
                    </List>
                </Box>
            );
        }

        return null;
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            pb: 2,
        }}>
            <Box sx={{
                width: '100%',
                '@media (min-width: 768px)': {
                    width: '600px',
                },
            }}>
                {/* <TopBar variant="details" title="Zoeken" onBack={() => navigate(-1)} /> */}

                <Box sx={{ p: 2 }}>
                    <form onSubmit={handleSearch}>
                        <TextField
                            fullWidth
                            size="small"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder={`Zoek ${activeTab === 'cars' ? 'auto\'s op merk/model' : 'gebruikers'}...`}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ mb: 1 }}
                        />
                    </form>

                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        sx={{ mb: 2, p: 0 }}
                        variant="fullWidth"
                    >
                        <Tab label="Auto's" value="cars" sx={{ textTransform: 'none' }} />
                        <Tab label="Gebruikers" value="users" sx={{ textTransform: 'none' }} />
                    </Tabs>

                    {isLoading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                            <CircularProgress />
                        </Box>
                    )}

                    {error && (
                        <Typography color="primary" sx={{ mb: 2 }}>
                            {error}
                        </Typography>
                    )}

                    {renderSearchResults()}
                </Box>
            </Box>
        </Box>
    );
};

export default Search; 