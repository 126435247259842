import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardHeader, CardActions, IconButton, Typography, Box, Button, useTheme } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import IosShareIcon from '@mui/icons-material/IosShare';
import { formatInTimeZone } from 'date-fns-tz';
import { useNavigate } from 'react-router-dom';
import DirectionsIcon from '@mui/icons-material/Directions';
import { Meet } from '../../types/Meet';
import { useStytchUser } from '@stytch/react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import AuthModal from '../auth-modal/AuthModal';
import RatingDisplay from '../rating/Rating';
import AttendButton from '../attend-button/AttendButton';
import AttendeesCounter from '../attendees-counter/AttendeesCounter';
import DistanceCounter from '../distance-counter/DistanceCounter';
import { getNextOccurrence } from '../../utils/date-utils';
import MapMarkerIcon, { createMapMarkerIcon } from '../event-map/MapMarkerIcon';
import { useUser } from '../../hooks/useUser';
import { useMeets } from '../../hooks/useMeets';
import * as Sentry from '@sentry/react';

type MeetCardProps = {
    meet: Meet;
    loading: boolean;
    isFavorite: boolean;
    onFavorite: () => Promise<void>;
    onHighlight: (meetId: string) => void;
    isHighlighted?: boolean;
    onEdit: () => void;
    userLocation?: { lat: number, lng: number } | null;
}

const MeetCard: React.FC<MeetCardProps> = ({
    meet,
    loading,
    isFavorite,
    onFavorite,
    onHighlight,
    isHighlighted = false,
    onEdit,
    userLocation,
}) => {
    const { id, title: originalTitle, customTitle, date, location, needsAdminAttention, latitude, longitude } = meet;
    const displayDate = meet?.recurringDay
        ? getNextOccurrence(date, meet.recurringDay)
        : date;
    const formattedDate = useMemo(() => {
        const parsedDate = new Date(displayDate);
        return formatInTimeZone(parsedDate, 'Europe/London', 'd MMMM yyyy - HH:mm');
    }, [displayDate]);
    const title = useMemo(() => customTitle || originalTitle, [customTitle, originalTitle]);
    const subTitle = useMemo(() => customTitle && originalTitle ? originalTitle.length > 40 ? originalTitle.substring(0, 40) + '...' : originalTitle : null, [customTitle, originalTitle]);
    const navigate = useNavigate();
    const [animateFavorite, setAnimateFavorite] = useState(false);
    const [animateShare, setAnimateShare] = useState(false);
    const [isFavorited, setIsFavorited] = useState(isFavorite);
    const [isProcessingFavorite, setIsProcessingFavorite] = useState(false);
    const [isSharing, setIsSharing] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const { user: stytchUser } = useStytchUser();
    const { user, mutateUser } = useUser();
    const { mutate } = useMeets();
    const isAttending = useMemo(() => user?.attended_meets.includes(id) || false, [user, id]);
    const [attendeesCount, setAttendeesCount] = useState(meet.attendeesAmount);
    const [isHost, setIsHost] = useState(user?.hosted_meets.some(m => m.id === id) || false);
    const theme = useTheme();

    useEffect(() => {
        setAttendeesCount(meet.attendeesAmount);
    }, [meet.attendeesAmount]);

    useEffect(() => {
        setIsFavorited(isFavorite);
    }, [isFavorite]);

    useEffect(() => {
        setIsHost(user?.hosted_meets.some(m => m.id === id) || false);
    }, [user]);


    const handleFavoriteClick = async () => {
        if (stytchUser) {
            if (isProcessingFavorite) {
                return;
            }
            setIsProcessingFavorite(true);
            setAnimateFavorite(false);
            setIsFavorited(!isFavorited);
            setAnimateFavorite(true);
            await onFavorite();
            setTimeout(() => {
                setIsProcessingFavorite(false);
            }, 500);
        } else {
            setShowAuthModal(true);
        }
    };

    const handleShare = () => {
        onHighlight(meet?.id);
        const originalUrl = window.location.pathname;
        const newUrl = `/meet/${meet?.id}`;
        window.history.pushState(null, '', newUrl);
        setAnimateShare(false);

        if (navigator.share) {
            setAnimateShare(true);
            setIsSharing(true);
            navigator.share({
                url: `${window.location.origin}/meet/${meet?.id}`,
            })
                .then(() => {
                    setIsSharing(false);
                    onHighlight('');
                    window.history.pushState(null, '', originalUrl);
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    console.error('Error sharing:', error);
                    setIsSharing(false);
                    onHighlight('');
                    window.history.pushState(null, '', originalUrl);
                });
        } else {
            onHighlight('');
            window.history.pushState(null, '', originalUrl);
        }
    };

    const handleOpenLocation = () => {
        if (latitude !== null && longitude !== null) {
            const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
            window.open(mapsUrl, '_blank');
        } else {
            const encodedAddress = encodeURIComponent(location);
            const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
            window.open(mapsUrl, '_blank');
        }
    };

    const handleOpenRoute = () => {
        if (latitude !== null && longitude !== null) {
            const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
            window.open(mapsUrl, '_blank');
        } else {
            const encodedAddress = encodeURIComponent(location);
            const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
            window.open(mapsUrl, '_blank');
        }
    };

    const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
        const earthRadiusKm = 6371;
        const dLat = (lat2 - lat1) * Math.PI / 180;
        const dLon = (lon2 - lon1) * Math.PI / 180;

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return earthRadiusKm * c;
    };

    const handleCardClick = (e: React.MouseEvent) => {
        if (
            e.target instanceof HTMLElement &&
            (e.target.closest('button') ||
                e.target.closest('.leaflet-container') ||
                e.target.closest('[role="menuitem"]') ||
                e.target.closest('[role="menu"]'))
        ) {
            return;
        }
        navigate(`/meet/${meet.id}`);
    };

    return (
        <Card
            data-testid="meet-card"
            data-latitude={latitude}
            data-longitude={longitude}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                bgcolor: 'background.paper',
                transition: 'box-shadow 0.3s ease-in-out, border 0.3s ease-in-out',
                height: '290px',
                boxShadow: isHighlighted
                    ? '0 0 100px 1px rgba(0, 180, 230, 0.2)'
                    : 'none',
                border: needsAdminAttention
                    ? '2px solid yellow'
                    : 'none',
                animation: needsAdminAttention
                    ? 'pulse 1.5s infinite'
                    : 'none',
                '@keyframes pulse': {
                    '0%': { borderColor: 'yellow' },
                    '50%': { borderColor: 'orange' },
                    '100%': { borderColor: 'yellow' },
                },
                cursor: 'pointer',
                '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                },
                WebkitTapHighlightColor: 'transparent',
                '&:active': {
                    backgroundColor: 'background.paper',
                },
            }}
        >
            <Box sx={{ width: '100%' }}>
                {latitude !== null && longitude !== null && (
                    <Box onClick={(e) => {
                        e.stopPropagation();
                        handleOpenLocation();
                    }} sx={{ width: '100%', height: '125px', padding: 1, paddingBottom: 0, flexShrink: 0 }} >
                        <MapContainer
                            center={[(latitude + 0.001), longitude]} // Add a small offset to the latitude to center the marker in the mapview 
                            zoom={13}
                            zoomControl={false}
                            dragging={false}
                            touchZoom={false}
                            doubleClickZoom={false}
                            scrollWheelZoom={false}
                            attributionControl={false}
                            style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                        >
                            <TileLayer
                                url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
                                detectRetina
                            />
                            {meet.latitude && meet.longitude && <Marker
                                key={meet.id}
                                position={[meet.latitude, meet.longitude]}
                                icon={createMapMarkerIcon(theme)}
                                eventHandlers={{
                                    click: handleOpenLocation,
                                }}
                            />}
                            <Box sx={{ position: 'absolute', bottom: 8, right: 8, zIndex: 999, display: 'flex', gap: 1 }}>
                                <RatingDisplay averageRating={meet.averageRating} />
                                <AttendeesCounter
                                    attendeeCount={attendeesCount}
                                    attendees={meet.attendees || []}
                                />
                            </Box>
                            {user?.isAdmin && <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 999, display: 'flex', gap: 1 }}>
                                <Box sx={{ backgroundColor: meet.host?.username !== user.username ? 'primary.main' : 'rgba(64, 64, 64, 0.4)', px: 2, py: 0.5, borderRadius: 4, }}>
                                    <Typography variant="body2" color="text.secondary">
                                        {meet.host?.username}
                                    </Typography>
                                </Box>
                            </Box>}
                            <Box sx={{ position: 'absolute', bottom: 8, left: 8, zIndex: 999, display: 'flex', gap: 1 }}>
                                {userLocation && latitude !== null && longitude !== null && (
                                    <DistanceCounter distance={Math.round(calculateDistance(userLocation.lat, userLocation.lng, latitude, longitude))} />
                                )}
                            </Box>
                        </MapContainer>
                    </Box>
                )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minWidth: '250px', width: '100%', position: 'relative', height: '100%', }}>
                <CardHeader
                    sx={{
                        paddingBottom: 0,
                        paddingTop: 1,
                    }}
                    action={
                        <IconButton
                            aria-label="add to favorites"
                            onClick={() => handleFavoriteClick()}
                            sx={{
                                color: stytchUser ? 'inherit' : 'action.disabled',
                                '&:hover': {
                                    backgroundColor: stytchUser ? 'action.hover' : 'transparent',
                                },
                                height: '40px',
                                width: '40px',
                            }}
                        >
                            {!isFavorited || !stytchUser ? (
                                <BookmarkBorderIcon />
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: '16pt',
                                        animation: animateFavorite ? 'twistBounce 1s' : 'none',
                                        transform: 'rotate(135deg)',
                                        '@keyframes twistBounce': {
                                            '0%': { transform: 'scale(0) rotateY(0) rotate(135deg)' },
                                            '50%': { transform: 'scale(1.5) rotateY(360deg) rotate(135deg)' },
                                            '100%': { transform: 'scale(1) rotateY(360deg) rotate(135deg)' },
                                        },
                                    }}
                                >
                                    🔖
                                </Typography>
                            )}
                        </IconButton>
                    }
                    title={
                        <Box onClick={handleCardClick}
                            sx={{
                                fontSize: 0,
                                marginBottom: subTitle ? 0 : 1
                            }}>
                            <Typography data-testid="meet-title" sx={{ fontWeight: 'bold', fontSize: title.length > 20 ? '11pt' : title.length > 15 ? '13pt' : '14pt' }} component="span">
                                {title}
                            </Typography>
                            <br />
                            {subTitle && (
                                <Typography sx={{ fontSize: '10pt' }} component="span">
                                    {subTitle}
                                </Typography>
                            )}
                        </Box>
                    }
                    subheader={
                        <Box sx={{ mt: 1 }}>
                            <Box display="flex" alignItems="center">
                                <Typography sx={{ marginRight: 1 }}>📅</Typography>
                                <Typography data-testid="meet-date" variant="body2" color="text.secondary">
                                    {formattedDate}
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography sx={{ marginRight: 1 }}>📍</Typography>
                                <Typography variant="body2" color="text.secondary">{getStreetAddress(location)}</Typography>
                            </Box>

                        </Box>
                    }
                />
                <CardActions disableSpacing sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box>
                        <IconButton
                            aria-label="get directions"
                            onClick={handleOpenRoute}
                            sx={{ height: '40px', width: '40px' }}
                        >
                            <DirectionsIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                        <IconButton
                            aria-label="share"
                            onClick={handleShare}
                            sx={{ height: '40px', width: '40px' }}
                        >
                            {!isSharing ? (
                                <IosShareIcon sx={{ fontSize: '20px' }} />
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: '16pt',
                                        animation: animateShare && isSharing ? 'bounceShare 0.3s' : 'none',
                                        '@keyframes bounceShare': {
                                            '0%': { transform: 'perspective(400px) scale(0)' },
                                            '50%': { transform: 'perspective(400px) scale(1.5)' },
                                            '100%': { transform: 'perspective(400px) scale(1)' },
                                        }
                                    }}
                                >
                                    🔗
                                </Typography>
                            )}
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {isHost || user?.isAdmin ?
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onEdit}
                                endIcon={<Typography>🛠</Typography>}
                                size="small"
                                sx={{
                                    minWidth: '140px',
                                    height: '38px',
                                }}
                            >
                                <Typography fontWeight="bold" color="secondary">Bewerken</Typography>
                            </Button>
                            :
                            <AttendButton
                                meetId={meet.id}
                                meetDate={meet.recurringDay ? new Date(getNextOccurrence(meet.date, meet.recurringDay)) : new Date(meet.date)}
                                isAttending={isAttending}
                                isUserVerified={user?.isVerified || false}
                                attendeesCount={attendeesCount}
                                loading={loading}
                                email={user?.email}
                                onAttendChange={(newIsAttending, newAttendeesCount, selectedCar) => {
                                    mutateUser();
                                    setAttendeesCount(newAttendeesCount);
                                    mutate();
                                }}
                            />}
                    </Box>
                </CardActions>
            </Box>
            <AuthModal open={showAuthModal} onClose={() => setShowAuthModal(false)} message={'Log in om te bewaren'} />
        </Card >
    );
};

const getStreetAddress = (fullAddress: string): string => {
    const parts = fullAddress.split(',');
    return parts[0].trim();
};

export default MeetCard;
