import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import { useNavigate } from 'react-router-dom';
import CustomZoomControl from './CustomZoomControl';
import { Meet } from '../../types/Meet';
import { createMapMarkerIcon } from './MapMarkerIcon';
import { useTheme } from '@mui/material/styles';

type EventMapViewProps = {
    events: Meet[];
    showFavorites: boolean;
    favorites: string[];
}

const EventMapView: React.FC<EventMapViewProps> = ({
    events,
    showFavorites,
    favorites,
}) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const handleMapCreated = React.useCallback((mapInstance: L.Map) => {
        if (mapInstance) {
            mapInstance.setMaxBounds([[-90, -180], [90, 180]]);
            mapInstance.options.maxBoundsViscosity = 1.0;
        }
    }, []);

    const handleMarkerClick = (event: Meet) => {
        const searchParams = new URLSearchParams(window.location.search);
        const keywords = searchParams.getAll('keywords');

        const newParams = new URLSearchParams();
        newParams.set('view', 'map');
        keywords.forEach(keyword => {
            newParams.append('keywords', keyword);
        });

        navigate(`/meet/${event.id}${newParams.toString() ? `?${newParams.toString()}` : ''}`);
    };

    return (
        <MapContainer
            center={[52.1326, 5.2913]}
            zoom={7}
            style={{
                width: '100%',
                height: '100%'
            }}
            zoomControl={false}
            ref={handleMapCreated}
        >
            <TileLayer
                url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                keepBuffer={30}
                updateWhenZooming={false}
                updateWhenIdle={true}
                noWrap={true}
            />
            <CustomZoomControl />
            {events
                .filter(event => showFavorites ? favorites.includes(event.id) : true)
                .map((event) => (
                    event.latitude && event.longitude && (
                        <Marker
                            key={event.id}
                            position={[event.latitude, event.longitude]}
                            icon={createMapMarkerIcon(theme)}
                            eventHandlers={{
                                click: () => handleMarkerClick(event),
                            }}
                        />
                    )
                ))}
        </MapContainer>
    );
};

export default React.memo(EventMapView);
