import React, { useState, useEffect } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import BottomNavigation from '../navigation/BottomNavigation';
import { useStytchUser } from '@stytch/react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';

interface LayoutProps {
    children: React.ReactNode;
}

const PATHS_THAT_REQUIRE_VERIFICATION = ['/create', '/user', '/discover', '/search', '/car'];

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(isLargeScreen);

    const { user: stytchUser } = useStytchUser();
    const { user, isLoading } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        const isProtectedPath = PATHS_THAT_REQUIRE_VERIFICATION.some(path =>
            window.location.pathname.includes(path));

        if (isProtectedPath) {
            // Check if user is not authenticated with Stytch
            if (!stytchUser) {
                navigate('/');
                return;
            }

            // Check if user data is loaded and not verified
            if (!isLoading && user && !user.isVerified) {
                navigate('/');
            }
        }
    }, [stytchUser, user, isLoading, navigate]);

    // Reset sidebar state when switching between mobile and desktop
    useEffect(() => {
        if (isDesktop) {
            setIsSidebarExpanded(isLargeScreen);
        }
    }, [isDesktop, isLargeScreen]);

    return (
        <Box
            sx={{
                paddingLeft: isDesktop ? (isSidebarExpanded ? '240px' : '64px') : 0,
                paddingBottom: isDesktop ? 0 : '56px', // Bottom navigation height on mobile
                transition: theme.transitions.create(['padding'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                width: '100%',
                overflow: 'hidden', // Prevent horizontal overflow
            }}
        >
            <BottomNavigation
                isDesktop={isDesktop}
                isExpanded={isSidebarExpanded}
                onToggleExpanded={() => setIsSidebarExpanded(!isSidebarExpanded)}
            />
            {children}
        </Box>
    );
};

export default Layout; 