import React from 'react';
import { Card, Box, Skeleton } from '@mui/material';

const CarCardSkeleton = () => {
    return (
        <Card sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            bgcolor: 'background.paper',
            cursor: 'pointer',
            WebkitTapHighlightColor: 'transparent',
            position: 'relative',
        }}>
            {/* Image Carousel Skeleton */}
            <Box sx={{ position: 'relative', aspectRatio: Number(process.env.REACT_APP_ASPECT_RATIO) }}>
                <Skeleton 
                    variant="rectangular" 
                    width="100%" 
                    height="100%"
                    animation="wave"
                />
                {/* Street Cred Counter Skeleton */}
                <Box sx={{ position: 'absolute', bottom: 8, right: 8 }}>
                    <Skeleton 
                        variant="rounded" 
                        width={60} 
                        height={24} 
                        animation="wave"
                    />
                </Box>
                {/* Horsepower Pill Skeleton */}
                <Box sx={{ position: 'absolute', bottom: 8, left: 8 }}>
                    <Skeleton 
                        variant="rounded" 
                        width={80} 
                        height={24} 
                        animation="wave"
                    />
                </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, p: 1, pb: 1 }}>
                <Box sx={{ p: 1, width: '100%' }}>
                    {/* Car Title Skeleton */}
                    <Skeleton variant="text" width="70%" height={24} sx={{ mb: 0.5 }} />
                    {/* Car Details Skeleton */}
                    <Skeleton variant="text" width="50%" height={20} sx={{ mb: 2 }} />
                    
                    {/* Social Media Icons Skeleton */}
                    <Box sx={{ display: 'flex', gap: 1, pt: 1 }}>
                        <Skeleton variant="circular" width={24} height={24} />
                        <Skeleton variant="circular" width={24} height={24} />
                        <Skeleton variant="circular" width={24} height={24} />
                    </Box>
                </Box>

                <Box sx={{ p: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', gap: 1, flexDirection: 'column' }}>
                        {/* Action Button Skeletons */}
                        <Skeleton variant="rectangular" width={120} height={36} sx={{ borderRadius: 1 }} />
                        <Skeleton variant="rectangular" width={120} height={36} sx={{ borderRadius: 1 }} />
                    </Box>
                </Box>
            </Box>
        </Card>
    );
};

export default CarCardSkeleton; 