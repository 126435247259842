import React from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MeetCard from '../event-card/EventCard';
import { Meet } from '../../types/Meet';
import { User } from '../../types/User';
import { useUser } from '../../hooks/useUser';

type EventMapModalProps = {
    selectedEvent: Meet | null;
    onClose: () => void;
    userLocation?: { lat: number; lng: number } | null;
    onHighlight: (id: string) => void;
    onEdit: (id: string) => void;
    favorites: string[];
    handleFavorite: (id: string) => Promise<void>;
}

const EventMapModal: React.FC<EventMapModalProps> = ({
    selectedEvent,
    onClose,
    userLocation,
    onHighlight,
    onEdit,
    favorites,
    handleFavorite
}) => {
    const { user } = useUser();
    return (
        <Modal
            open={!!selectedEvent}
            onClose={onClose}
            aria-labelledby="event-modal"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: { xs: '90%', sm: '600px' },
                maxHeight: '90vh',
                overflow: 'auto',
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 24,
                p: 2,
            }}>
                {selectedEvent && (
                    <>
                        <MeetCard
                            loading={false}
                            meet={selectedEvent}
                            isFavorite={favorites.includes(selectedEvent.id)}
                            onFavorite={() => handleFavorite(selectedEvent.id)}
                            onHighlight={() => onHighlight(selectedEvent.id)}
                            onEdit={() => onEdit(selectedEvent.id)}
                            userLocation={userLocation}
                        />
                        <IconButton
                            onClick={onClose}
                            sx={{
                                position: 'absolute',
                                bottom: 8,
                                right: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default React.memo(EventMapModal);
