import React from 'react';
import { Box, Typography, Avatar, Tooltip } from '@mui/material';
import { MeetAttendee } from '../../types/Meet';
import { getManufacturerLogo } from '../../utils/imageUtils';

interface AttendeesCounterProps {
    attendeeCount: number;
    size?: 'small' | 'medium';
    attendees?: MeetAttendee[];
}

const AttendeesCounter: React.FC<AttendeesCounterProps> = ({
    attendeeCount,
    size = 'small',
    attendees = []
}) => {
    // Get unique car makes from attendees (up to 3)
    const uniqueCarMakes = React.useMemo(() => {
        const makes = new Set<string>();

        // Collect car makes from attendees (only the car they attended with)
        attendees.forEach(attendee => {
            if (attendee.car) {
                // Only consider the first car in the array, which is the car they attended with
                const car = attendee.car;
                if (car && car.make) {
                    makes.add(car.make);
                }
            }
        });

        // Convert to array and take first 3
        return Array.from(makes).slice(0, 3);
    }, [attendees]);

    return (
        <Box
            data-testid="attendees-counter"
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: 'rgba(28, 28, 28, 0.8)',
                borderRadius: '100px',
                padding: '4px 8px',
                width: 'fit-content'
            }}>
            <Typography
                component="span"
                variant="body2"
                sx={{
                    opacity: attendeeCount < 1 ? 0.3 : 1,
                    fontSize: size === 'small' ? '12pt' : '14pt'
                }}
            >
                🚗
            </Typography>

            <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                    fontSize: size === 'small' ? '12pt' : '14pt'
                }}
            >
                {attendeeCount >= 1
                    ? attendeeCount
                    : '-'
                }
            </Typography>
            {uniqueCarMakes.length > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 0.5 }}>
                    {uniqueCarMakes.map((make, index) => (
                        <img
                            key={index}
                            src={getManufacturerLogo(make)}
                            alt={make}

                            style={{
                                width: size === 'small' ? 22 : 24,
                                height: size === 'small' ? 22 : 24,
                                backgroundColor: 'rgba(28, 28, 28, 1)',
                                objectFit: 'contain',
                                marginLeft: index > 0 ? -6 : 0,
                                borderRadius: '100px',
                                border: '1px solid rgba(255, 255, 255, 0.1)',
                            }}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default AttendeesCounter;
