import useSWR, { mutate as globalMutate } from 'swr';
import { useStytch } from '@stytch/react';
import { Meet, MeetDTO } from '../types/Meet';
import { defaultConfig, fetcher, mutationFetcher } from '../lib/swr';
import { useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
export const MEETS_CACHE_KEY = 'meets';

interface useMeetsProps {
    meetId?: string;
}

export const useMeets = ({ meetId }: useMeetsProps = {}) => {
    const stytch = useStytch();
    const sessionToken = stytch.session.getTokens()?.session_token;
    const [processingAttendance, setProcessingAttendance] = useState<Record<string, boolean>>({});

    const { data: meets, error, isLoading, mutate } = useSWR<Meet[]>(
        [`${process.env.REACT_APP_API_BASE_URL}/meets`, sessionToken || null],
        fetcher,
        {
            ...defaultConfig,
            revalidateOnFocus: false,
            revalidateIfStale: false,
            dedupingInterval: 5000,
            keepPreviousData: true
        }
    );

    const { data: meet, error: meetError, isLoading: isMeetLoading, mutate: mutateMeet } = useSWR<Meet>(
        meetId ? [`${process.env.REACT_APP_API_BASE_URL}/meets/${meetId}`, sessionToken || null] : null,
        fetcher,
        defaultConfig
    );

    const createMeet = async (meetData: MeetDTO) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionToken}`,
                },
                body: JSON.stringify(meetData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const newMeet = await response.json();
            mutate([...(meets || []), newMeet]);
            return newMeet;
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error creating meet:', error);
            throw error;
        }
    };

    const updateMeet = async (id: string, meetData: Partial<MeetDTO>) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionToken}`,
                },
                body: JSON.stringify(meetData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const updatedMeet = await response.json();
            
            // Update all caches
            if (meets?.length) {
                await mutate(
                    meets.map(m => m.id === id ? updatedMeet : m),
                    { revalidate: false }
                );
            }
            if (meetId === id) {
                await mutateMeet(updatedMeet, { revalidate: false });
            }

            // Update the global cache for this meet
            const meetKey = [`${process.env.REACT_APP_API_BASE_URL}/meets/${id}`, sessionToken];
            await globalMutate(meetKey, updatedMeet, { revalidate: false });

            // Revalidate all caches to ensure consistency
            mutate();
            if (meetId === id) {
                mutateMeet();
            }
            globalMutate(meetKey);
            
            return updatedMeet;
        } catch (error) {
            Sentry.captureException(error);
            // Revalidate all caches on error to ensure consistency
            mutate();
            if (meetId === id) {
                mutateMeet();
            }
            const meetKey = [`${process.env.REACT_APP_API_BASE_URL}/meets/${id}`, sessionToken];
            globalMutate(meetKey);
            
            console.error('Error updating meet:', error);
            throw error;
        }
    };

    const deleteMeet = async (id: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Update both caches
            if (meets?.length) {
                mutate(meets.filter(m => m.id !== id));
            }
            if (meetId === id) {
                mutateMeet(undefined);
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error deleting meet:', error);
            throw error;
        }
    };

    const attendMeet = async (meetId: string, eventDate: Date, carId?: string) => {
        // If already processing attendance for this meet, ignore the request
        if (processingAttendance[meetId]) {
            return;
        }

        const currentMeets = meets || [];
        const currentMeet = meet;

        try {
            setProcessingAttendance(prev => ({ ...prev, [meetId]: true }));

            // Helper function to update a meet's attendance count
            const updateMeetAttendance = (m: Meet): Meet => {
                if (m.id === meetId) {
                    return {
                        ...m,
                        attendeesAmount: (m.attendeesAmount || 0) + 1
                    };
                }
                return m;
            };

            // Optimistically update both caches
            if (currentMeets.length > 0) {
                await mutate(
                    currentMeets.map(updateMeetAttendance),
                    { revalidate: false }
                );
            }

            if (currentMeet && currentMeet.id === meetId) {
                await mutateMeet(
                    updateMeetAttendance(currentMeet),
                    { revalidate: false }
                );
            }

            // Also update the individual meet cache if it exists
            const meetKey = [`${process.env.REACT_APP_API_BASE_URL}/meets/${meetId}`, sessionToken];
            const cachedMeet = await globalMutate<Meet>(
                meetKey,
                (cached) => cached ? updateMeetAttendance(cached) : undefined,
                { revalidate: false }
            );

            // Make the API call
            await mutationFetcher(
                `${process.env.REACT_APP_API_BASE_URL}/attend/${meetId}`,
                { arg: { attendedAt: eventDate, carId } },
                sessionToken
            );

            // Revalidate all caches to ensure consistency
            if (currentMeets.length > 0) {
                mutate();
            }
            if (currentMeet && currentMeet.id === meetId) {
                mutateMeet();
            }
            globalMutate(meetKey);
            } catch (error) {
            Sentry.captureException(error);
            // Revert optimistic updates on error
            if (currentMeets.length > 0) {
                mutate(currentMeets, { revalidate: true });
            }
            if (currentMeet && currentMeet.id === meetId) {
                mutateMeet(currentMeet, { revalidate: true });
            }
            const meetKey = [`${process.env.REACT_APP_API_BASE_URL}/meets/${meetId}`, sessionToken];
            globalMutate(meetKey);
            console.error('Error attending meet:', error);
            throw error;
        } finally {
            setProcessingAttendance(prev => ({ ...prev, [meetId]: false }));
        }
    };

    const unattendMeet = async (meetId: string, eventDate: Date) => {
        // If already processing attendance for this meet, ignore the request
        if (processingAttendance[meetId]) {
            return;
        }

        const currentMeets = meets || [];
        const currentMeet = meet;

        try {
            setProcessingAttendance(prev => ({ ...prev, [meetId]: true }));

            // Helper function to update a meet's attendance count
            const updateMeetAttendance = (m: Meet): Meet => {
                if (m.id === meetId) {
                    return {
                        ...m,
                        attendeesAmount: Math.max(0, (m.attendeesAmount || 1) - 1)
                    };
                }
                return m;
            };

            // Optimistically update both caches
            if (currentMeets.length > 0) {
                await mutate(
                    currentMeets.map(updateMeetAttendance),
                    { revalidate: false }
                );
            }

            if (currentMeet && currentMeet.id === meetId) {
                await mutateMeet(
                    updateMeetAttendance(currentMeet),
                    { revalidate: false }
                );
            }

            // Also update the individual meet cache if it exists
            const meetKey = [`${process.env.REACT_APP_API_BASE_URL}/meets/${meetId}`, sessionToken];
            await globalMutate<Meet>(
                meetKey,
                (cached) => cached ? updateMeetAttendance(cached) : undefined,
                { revalidate: false }
            );

            // Make the API call
            await mutationFetcher(
                `${process.env.REACT_APP_API_BASE_URL}/unattend/${meetId}`,
                { arg: { attendedAt: eventDate } },
                sessionToken
            );

            // Revalidate all caches to ensure consistency
            if (currentMeets.length > 0) {
                mutate();
            }
            if (currentMeet && currentMeet.id === meetId) {
                mutateMeet();
            }
            globalMutate(meetKey);
            } catch (error) {
            Sentry.captureException(error);
            // Revert optimistic updates on error
            if (currentMeets.length > 0) {
                mutate(currentMeets, { revalidate: true });
            }
            if (currentMeet && currentMeet.id === meetId) {
                mutateMeet(currentMeet, { revalidate: true });
            }
            const meetKey = [`${process.env.REACT_APP_API_BASE_URL}/meets/${meetId}`, sessionToken];
            globalMutate(meetKey);
            console.error('Error unattending meet:', error);
            throw error;
        } finally {
            setProcessingAttendance(prev => ({ ...prev, [meetId]: false }));
        }
    };

    const generateSeoDescriptionPreview = async (meetData: Partial<Meet>) => {
        return mutationFetcher(
            `${process.env.REACT_APP_API_BASE_URL}/meets/seo/description/preview`,
            { arg: { meetData } },
            sessionToken
        ).then(response => response.data);
    };

    const generateSeoKeywordsPreview = async (meetData: Partial<Meet>) => {
        return mutationFetcher(
            `${process.env.REACT_APP_API_BASE_URL}/meets/seo/keywords/preview`,
            { arg: { meetData } },
            sessionToken
        ).then(response => response.data);
    };

    const regenerateSeoDescription = async (id: string) => {
        const response = await mutationFetcher(
            `${process.env.REACT_APP_API_BASE_URL}/meets/seo/description`,
            {
                arg: {
                    meetIds: [id],
                    updateAll: false,
                    isConcept: true,
                }
            },
            sessionToken
        );
        return response.updatedMeets?.[0]?.seoDescription || null;
    };

    const regenerateSeoKeywords = async (id: string) => {
        const response = await mutationFetcher(
            `${process.env.REACT_APP_API_BASE_URL}/meets/seo/keywords`,
            {
                arg: {
                    meetIds: [id],
                    updateAll: false,
                    isConcept: true,
                }
            },
            sessionToken
        );
        return response.updatedMeets?.[0]?.seoKeywords || null;
    };

    return useMemo(() => ({
        meets: meets || [],
        meet,
        error: error || meetError,
        isLoading: isLoading || isMeetLoading,
        isError: !!(error || meetError),
        mutate,
        mutateMeet,
        createMeet,
        updateMeet,
        deleteMeet,
        attendMeet,
        unattendMeet,
        isAttendanceProcessing: (id: string) => processingAttendance[id] || false,
        generateSeoDescriptionPreview,
        generateSeoKeywordsPreview,
        regenerateSeoDescription,
        regenerateSeoKeywords
    }), [meets, meet, error, meetError, isLoading, isMeetLoading, mutate, mutateMeet, processingAttendance]);
};


