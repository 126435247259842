import React from 'react';
import { Box } from '@mui/material';
import smokeEffect from '../../assets/smoke.gif';

interface SmokeEffectProps {
    show: boolean;
    positionX: number;
    positionY: number;
}

const SmokeEffect: React.FC<SmokeEffectProps> = ({ show, positionX , positionY }) => {
    if (!show) return null;

    return (
        <Box
            sx={{
                position: 'absolute',
                top: positionY,
                left: positionX,
                transform: 'translate(-50%, -50%)',
                width: '32px',
                height: '16px',
                pointerEvents: 'none',
            }}
        >
            <img
                src={smokeEffect}
                alt=""
                style={{
                    width: '100%',
                    height: '100%',
                    opacity: show ? 1 : 0,
                    transition: 'opacity 0.2s ease-out',
                }}
            />
        </Box>
    );
};

export default SmokeEffect; 