import { createTheme } from '@mui/material/styles';

// Color constants from Material Theme Builder (dark theme)
const PRIMARY = '#ab2116';         // primary.80
const SECONDARY = '#FFFFFF';       // secondary.80
const SECONDARY_DARK = '#4d4d4d';       // secondaryContainer
const BACKGROUND = '#0F1416';      // background
const PAPER_BACKGROUND = '#1B2022'; // surfaceContainer
const ERROR = '#2d0806';          // error
const ERROR_LIGHT = '#e7534b';          // error
const ERROR_DARK = '#160403';          // error
const SUCCESS = '#5FBA5F';        // Keeping existing success color
const INFO = '#4BD7F8';           // primary.80
const WARNING = '#FFB77B';        // tertiary.80
const TEXT_PRIMARY = '#DEE3E6';    // onSurface
const TEXT_SECONDARY = '#BFC8CB';  // onSurfaceVariant

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: PRIMARY,
            light: '#ffcacf',      // primary.95
            dark: '#730000',       // primary.30
            contrastText: '#FFFFFF', // onPrimary
        },
        secondary: {
            main: SECONDARY,
            light: '#FFFFFF',      // secondary.95
            dark: SECONDARY_DARK,       // secondaryContainer
            contrastText: '#a3000b', // onSecondary
        },
        error: {
            main: ERROR,
            light: ERROR_LIGHT,      // errorContainer
            dark: ERROR_DARK,       // errorContainer
            contrastText: '#FFFFFF', // onError
        },
        success: {
            main: SUCCESS,
        },
        info: {
            main: INFO,
        },
        warning: {
            main: WARNING,
        },
        background: {
            default: BACKGROUND,
            paper: PAPER_BACKGROUND,
        },
        text: {
            primary: TEXT_PRIMARY,
            secondary: TEXT_SECONDARY,
        },
        action: {
            hover: 'rgba(255, 255, 255, 0.08)',
            selected: 'rgba(255, 255, 255, 0.16)',
            disabled: 'rgba(255, 255, 255, 0.3)',
            disabledBackground: 'rgba(255, 255, 255, 0.12)',
        },
    },
    typography: {
        fontFamily: 'Barlow, Arial, sans-serif',
        fontSize: 16,
        h6: {
            fontSize: '1.2rem',
        },
        h4: {
            fontSize: '2.125rem',
        },
        body1: {
            fontSize: '0.95rem',
            fontWeight: 500,
        },
        body2: {
            fontSize: '0.9rem',
            fontWeight: 600,
        },
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: '16px', // Minimum font size to prevent zoom on iOS
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    height: 48
                },
            },
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    transition: 'none',
                    '&.Mui-selected': {
                        transition: 'none',
                        color: SECONDARY,
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: SECONDARY,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: SECONDARY,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: SECONDARY,
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    '&.Mui-error': {
                        color: PRIMARY,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: SECONDARY,
                    '&.Mui-focused': {
                        color: SECONDARY,
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        color: TEXT_PRIMARY,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    fontSize: '16pt',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: TEXT_PRIMARY,
                    '&:hover': {
                        color: TEXT_PRIMARY,
                    },
                    textDecorationColor: TEXT_SECONDARY,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    textTransform: 'none',
                    fontWeight: 700,
                    fontSize: '1rem',
                },
            },
            variants: [
                {
                    props: { variant: 'contained', color: 'primary' },
                    style: {
                        backgroundColor: PRIMARY,
                        '&:hover': {
                            color: TEXT_PRIMARY,
                        },
                    },
                },
                {
                    props: { variant: 'contained', color: 'error' },
                    style: {
                        borderInline: '3px solid',
                        borderBlock: '3px solid',
                        borderColor: PRIMARY,
                        backgroundImage: `linear-gradient(to bottom, ${ERROR}, ${ERROR_DARK})`,
                        '&:hover': {
                            backgroundColor: ERROR_DARK,
                            borderColor: PRIMARY,
                            backgroundImage: `linear-gradient(to bottom, ${ERROR_DARK}, ${ERROR_DARK})`,

                        },
                    },
                },
                {
                    props: { size: 'small' },
                    style: {
                        padding: '4px 8px',
                    },
                },
                {
                    props: { size: 'medium' },
                    style: {
                        padding: '6px 16px',
                    },
                },
                {
                    props: { size: 'large' },
                    style: {
                        padding: '8px 22px',
                    },
                },
            ],
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked:not(.Mui-disabled)': {
                        color: TEXT_PRIMARY,
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    backgroundColor: PRIMARY,
                    fontWeight: 600,
                },
            },
        },
        MuiRating: {
            styleOverrides: {
                iconEmpty: {
                    color: TEXT_PRIMARY,
                    opacity: 0.3,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: PAPER_BACKGROUND,
                    backgroundImage: 'none',
                },
            },
        },
    },
});

export default theme;
