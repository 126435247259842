import React, { useState, useMemo } from 'react';
import {
    Box,
    Button,
    Typography,
    Stepper,
    Step,
    StepLabel,
    CircularProgress,
    MobileStepper,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
} from '@mui/material';
import { Car, CreateCarDTO } from '../../types/Car';
import { useCars } from '../../hooks/useCars';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    DirectionsCar as CarIcon,
    Build as BuildIcon,
    Speed as SpeedIcon,
    Image as ImageIcon,
    Preview as PreviewIcon,
    Share as ShareIcon,
    Description as DescriptionIcon
} from '@mui/icons-material';

// Form Steps
import BasicInfoStep from './steps/BasicInfoStep';
import SpecificationsStep from './steps/SpecificationsStep';
import ModificationsStep from './steps/ModificationsStep';
import ImageStep from './steps/ImageStep';
import ReviewStep from './steps/ReviewStep';
import SocialMediaStep from './steps/SocialMediaStep';
import DescriptionStep from './steps/DescriptionStep';
import * as Sentry from '@sentry/react';
interface CarFormWizardProps {
    car?: Car;
    onSubmit: () => void;
    onCancel: () => void;
    onDelete: (car: Car) => void;
}

const steps = [
    'Auto Selectie',
    'Specificaties',
    'Modificaties',
    'Foto\'s',
    'Social Media',
    'Beschrijving',
    'Overzicht'
];

const stepIcons = [
    <CarIcon key="Auto Selectie" />,
    <SpeedIcon key="Specificaties" />,
    <BuildIcon key="Modificaties" />,
    <ImageIcon key="Foto's" />,
    <ShareIcon key="Social Media" />,
    <DescriptionIcon key="Beschrijving" />,
    <PreviewIcon key="Overzicht" />
];

const CarFormWizard: React.FC<CarFormWizardProps> = ({ car, onSubmit, onCancel, onDelete }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { createCar, updateCar, uploadCarImage } = useCars();
    const [activeStep, setActiveStep] = useState(car ? -1 : 0); // Start at -1 for overview in edit mode
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [pendingImageFiles, setPendingImageFiles] = useState<File[]>([]);

    const [formData, setFormData] = useState<CreateCarDTO>({
        make: car?.make,
        model: car?.model,
        vehicleName: car?.vehicleName,
        makeId: car?.makeId,
        modelId: car?.modelId,
        vehicleId: car?.vehicleId,
        engineType: car?.engineType,
        engineDisplacement: car?.engineDisplacement,
        year: car?.year,
        color: car?.color,
        transmission: car?.transmission,
        horsePower: car?.horsePower,
        torque: car?.torque,
        modifications: car?.modifications?.map(mod => ({
            component: mod.component,
            manufacturer: mod.manufacturer,
            isCustom: mod.isCustom,
            design: mod.design
        })) || [],
        images: car?.images || [],
        instagramUrl: car?.instagramUrl,
        tiktokUrl: car?.tiktokUrl,
        youtubeUrl: car?.youtubeUrl,
        description: car?.description
    });

    const isBasicInfoStepValid = useMemo(() => {
        return formData.make && formData.model && formData.vehicleName && formData.makeId && formData.modelId && formData.vehicleId;
    }, [formData]);

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleStepClick = (step: number) => {
        setActiveStep(step);
    };

    const updateFormData = (updates: Partial<CreateCarDTO>) => {
        setFormData(prev => ({
            ...prev,
            ...Object.fromEntries(
                Object.entries(updates).map(([k, v]) => [k, v ?? ''])
            )
        }));
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setError(null);

        try {
            // Upload any pending images first
            const uploadedImageUrls = [];
            if (pendingImageFiles.length > 0) {
                for (const file of pendingImageFiles) {
                    const formData = new FormData();
                    formData.append('image', file);
                    const data = await uploadCarImage(formData);
                    uploadedImageUrls.push(data.imageUrl);
                }
            }

            // Combine existing and newly uploaded images
            const allImages = [...(formData.images || []), ...uploadedImageUrls];

            // Create or update car with all images
            if (car) {
                await updateCar({ id: car.id, ...formData, images: allImages });
            } else {
                await createCar({ ...formData, images: allImages });
            }
            onSubmit();
        } catch (err) {
            Sentry.captureException(err);
            setError(err instanceof Error ? err.message : 'An error occurred');
            setIsSubmitting(false);
        }
    };

    const handleSaveStep = async () => {
        if (!car) return;

        setIsSubmitting(true);
        setError(null);

        try {
            // Upload any pending images first
            const uploadedImageUrls = [];
            if (pendingImageFiles.length > 0) {
                for (const file of pendingImageFiles) {
                    const formData = new FormData();
                    formData.append('image', file);
                    const data = await uploadCarImage(formData);
                    uploadedImageUrls.push(data.imageUrl);
                }
            }

            // Combine existing and newly uploaded images
            const allImages = [...(formData.images || []), ...uploadedImageUrls];

            await updateCar({ id: car.id, ...formData, images: allImages });
            setPendingImageFiles([]); // Clear pending files after successful upload
            setActiveStep(-1); // Return to overview
            setIsSubmitting(false);
        } catch (err) {
            Sentry.captureException(err);
            setError(err instanceof Error ? err.message : 'An error occurred');
            setIsSubmitting(false);
        }
    };

    const isLastStep = activeStep === steps.length - 1;

    const renderOverviewStep = () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', p: 1, height: '100%' }}>
            <List sx={{ mt: 3 }}>
                {steps.map((label, index) => (
                    <ListItem
                        key={label}
                        onClick={() => handleStepClick(index)}
                        sx={{
                            borderRadius: 1,
                            mb: 1,
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        <ListItemIcon>
                            {stepIcons.find(icon => icon.key === label)}
                        </ListItemIcon>
                        <ListItemText primary={label} />
                        <KeyboardArrowRight />
                    </ListItem>
                )).filter((item) => (item.key !== 'Auto Selectie' && item.key !== 'Overzicht') && car)}
            </List>
            <Box sx={{ display: 'flex', bottom: 0, width: '100%', gap: 2, flexDirection: 'column' }}>
                {car && <Button color="error" variant="contained" fullWidth onClick={() => onDelete(car)}>
                    Verwijder auto
                </Button>}
                <Button variant="outlined" color="secondary" fullWidth onClick={onCancel}>Sluiten</Button>
            </Box>
        </Box>
    );

    const renderStepContent = () => {
        if (activeStep === -1) {
            return renderOverviewStep();
        }

        switch (activeStep) {
            case 0:
                return (
                    <BasicInfoStep
                        formData={formData}
                        onUpdate={updateFormData}
                    />
                );
            case 1:
                return (
                    <SpecificationsStep
                        formData={formData}
                        onChange={updateFormData}
                    />
                );
            case 2:
                return (
                    <ModificationsStep
                        formData={formData}
                        onChange={updateFormData}
                    />
                );
            case 3:
                return (
                    <ImageStep
                        formData={formData}
                        onChange={updateFormData}
                        onFilesChange={setPendingImageFiles}
                        pendingFiles={pendingImageFiles}
                    />
                );
            case 4:
                return (
                    <SocialMediaStep
                        formData={formData}
                        onChange={updateFormData}
                    />
                );
            case 5:
                return (
                    <DescriptionStep
                        formData={formData}
                        onChange={updateFormData}
                    />
                );
            case 6:
                return (
                    <ReviewStep
                        formData={formData}
                        pendingFiles={pendingImageFiles}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
        }}>
            {!isMobile && !car && (
                <>
                    <Box sx={{ px: 3, pt: 2 }}>
                        <Stepper
                            activeStep={activeStep}
                            nonLinear
                            sx={{
                                height: '40px',
                            }}
                        >
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel
                                        onClick={() => handleStepClick(index)}
                                        sx={{ cursor: 'pointer' }}
                                        StepIconComponent={() => (
                                            <Box sx={{
                                                color: activeStep >= index ? 'secondary.main' : 'text.disabled'
                                            }}>
                                                {stepIcons[index]}
                                            </Box>
                                        )}
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <Divider sx={{ mt: 2 }} />
                </>
            )}

            <Box sx={{
                flex: 1,
                overflow: 'auto',
                pb: 1,
                mb: isMobile ? 7 : 0,
            }}>
                {renderStepContent()}
            </Box>

            {/* Navigation buttons */}
            <Box sx={{
                ...(isMobile ? {
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgcolor: 'background.paper',
                    borderTop: 1,
                    borderColor: 'divider',
                    zIndex: 1
                } : {
                    p: 2,
                    borderTop: 1,
                    borderColor: 'divider',
                })
            }}>
                {car ? (
                    // Edit mode navigation
                    activeStep !== -1 ? (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '60px', p: 1 }}>
                            <Button
                                color="inherit"
                                onClick={() => setActiveStep(-1)}
                            >
                                Annuleren
                            </Button>
                            <Button
                                size="small"
                                variant="text"
                                color="secondary"
                                onClick={handleSaveStep}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    'Wijzigingen Opslaan'
                                )}
                            </Button>
                        </Box>
                    ) : null
                ) : (
                    // New car mode navigation
                    isMobile ? (
                        <MobileStepper
                            variant="dots"
                            color="secondary"
                            steps={steps.length}
                            position="static"
                            activeStep={activeStep}
                            sx={{ flexGrow: 1 }}
                            nextButton={
                                <Button
                                    size="small"
                                    color="secondary"
                                    onClick={isLastStep ? handleSubmit : handleNext}
                                    disabled={isSubmitting || !isBasicInfoStepValid}
                                >
                                    {isLastStep ? (
                                        isSubmitting ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            'Aanmaken'
                                        )
                                    ) : (
                                        <>
                                            Volgende
                                            <KeyboardArrowRight />
                                        </>
                                    )}
                                </Button>
                            }
                            backButton={
                                <Button
                                    size="small"
                                    color="secondary"
                                    onClick={handleBack}
                                    disabled={activeStep === 0 || isSubmitting}
                                >
                                    <KeyboardArrowLeft />
                                    Terug
                                </Button>
                            }
                        />
                    ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                color="inherit"
                                onClick={handleBack}
                                disabled={activeStep === 0 || isSubmitting}
                                sx={{ mr: 1 }}
                            >
                                Terug
                            </Button>
                            {isLastStep ? (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) : (
                                        'Auto Aanmaken'
                                    )}
                                </Button>
                            ) : (
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleNext}
                                    disabled={isSubmitting}
                                >
                                    Volgende
                                </Button>
                            )}
                        </Box>
                    )
                )}
            </Box>

            {error && (
                <Typography color="primary" sx={{ mt: 2 }}>
                    {error}
                </Typography>
            )}
        </Box>
    );
};

export default CarFormWizard; 