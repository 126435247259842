import React, { useState } from 'react';
import { useStytch } from '@stytch/react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
const ResetPassword: React.FC = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const stytch = useStytch();
    const navigate = useNavigate();

    const redirectUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL || 'http://localhost:3000';

    const handleResetPassword = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            await stytch.passwords.resetByEmailStart({
                email: email,
                login_redirect_url: `${redirectUrl}/login`,
                reset_password_redirect_url: `${redirectUrl}/reset-password-complete`,
            });
            setSuccess(true);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            Sentry.captureException(error);
            setError('Er is een fout opgetreden bij het verzenden van de reset-link. Probeer het opnieuw.');
            console.error('Password reset error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            backgroundColor: '#111418',
        }}>
            <Box
                component="form"
                onSubmit={handleResetPassword}
                sx={{
                    width: '320px',
                    backgroundColor: 'background.paper',
                    padding: 3,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Wachtwoord resetten
                </Typography>
                {!success ? (
                    <>
                        <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{ mb: 2 }}
                            required
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={isLoading}
                            sx={{ mb: 2 }}
                        >
                            {isLoading ? <CircularProgress size={24} /> : 'Reset link versturen'}
                        </Button>
                        {error && (
                            <Typography color="primary" sx={{ marginBottom: 2, textAlign: 'center' }}>
                                {error}
                            </Typography>
                        )}
                    </>
                ) : (
                    <Typography sx={{ mb: 2, textAlign: 'center' }}>
                        Een reset-link is verzonden naar je e-mailadres. Controleer je inbox en volg de instructies om je wachtwoord te resetten.
                    </Typography>
                )}
                <Button
                    onClick={() => navigate('/login')}
                    sx={{ mb: 2 }}
                >
                    Terug naar inloggen
                </Button>
            </Box>
        </Box>
    );
};

export default ResetPassword;
