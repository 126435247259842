import useSWR, { mutate } from 'swr';
import { useStytch } from '@stytch/react';
import { User, UserProfile } from '../types/User';
import { defaultConfig, fetcher, mutationFetcher } from '../lib/swr';
import * as Sentry from '@sentry/react';

interface CreateUserParams {
    username?: string;
    contestParticipant?: boolean;
    instagramHandle?: string;
}

interface UpdateUserParams {
    username?: string;
    instagramHandle?: string;
    bio?: string;
    profilePicture?: string;
}

interface SearchResult {
    id: string;
    username: string;
    profilePicture?: string;
    cars: {
        id: string;
        make: string;
        model: string;
        mainImage?: string;
    }[];
}

interface LastVisitedCar {
    id: string;
    make: string;
    model: string;
    mainImage?: string;
    owner: {
        id: string;
        username: string;
        profilePicture?: string;
    };
}

interface LastVisitedUser {
    id: string;
    username: string;
    profilePicture?: string;
    totalCars: number;
}

export const useUser = () => {
    const stytch = useStytch();
    const sessionToken = stytch.session.getTokens()?.session_token;

    const { data: user, error, isLoading, mutate: mutateUser } = useSWR<User>(
        sessionToken ? [`${process.env.REACT_APP_API_BASE_URL}/users/me`, sessionToken] : null,
        fetcher,
        defaultConfig
    );

    const { data: lastVisited, mutate: mutateLastVisited } = useSWR<{ cars: LastVisitedCar[] }>(
        sessionToken ? [`${process.env.REACT_APP_API_BASE_URL}/users/last-visited`, sessionToken] : null,
        fetcher,
        defaultConfig
    );

    const { data: lastVisitedUsers, mutate: mutateLastVisitedUsers } = useSWR<{ users: LastVisitedUser[] }>(
        sessionToken ? [`${process.env.REACT_APP_API_BASE_URL}/users/last-visited-users`, sessionToken] : null,
        fetcher,
        defaultConfig
    );

    const getUserByUsername = (username: string) => {
        return useSWR<UserProfile>(
            sessionToken && username ? [`${process.env.REACT_APP_API_BASE_URL}/users/username/${username}`, sessionToken] : null,
            fetcher,
            defaultConfig
        );
    };

    const uploadProfilePicture = async (file: File): Promise<string> => {
        if (!sessionToken) throw new Error('No session token available');

        const formData = new FormData();
        formData.append('profilePicture', file);

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/profile-picture`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${sessionToken}`
            },
            body: formData
        });

        if (!response.ok) {
            const data = await response.json();
            if (data.message?.includes('Invalid file type')) {
                throw new Error('Only JPEG, PNG and WebP images are allowed');
            }
            throw new Error(data.message || 'Failed to upload profile picture');
        }

        const data = await response.json();
        return data.profilePicture;
    };

    const updateUser = async (params: UpdateUserParams): Promise<User> => {
        if (!sessionToken) throw new Error('No session token available');

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionToken}`
            },
            body: JSON.stringify(params)
        });

        if (!response.ok) {
            const data = await response.json();
            if (response.status === 400) {
                switch (data.field) {
                    case 'username':
                        throw new Error(data.message || 'Invalid username or already taken');
                    default:
                        if (data.message?.includes('Bio must be')) {
                            throw new Error('Bio must be 150 characters or less');
                        }
                        throw new Error(data.message || 'Failed to update profile');
                }
            }
            throw new Error('Failed to update profile');
        }

        const updatedUser = await response.json();
        await mutate([`${process.env.REACT_APP_API_BASE_URL}/users/me`, sessionToken]);
        return updatedUser;
    };

    const updateUserWithProfilePicture = async (params: UpdateUserParams, file?: File): Promise<User> => {
        let profilePicture = params.profilePicture;
        
        if (file) {
            profilePicture = await uploadProfilePicture(file);
        }

        return await updateUser({
            ...params,
            ...(profilePicture && { profilePicture })
        });
    };

    const createUser = async (email: string, sessionToken: string, params?: CreateUserParams): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionToken}`
                },
                body: JSON.stringify({
                    email,
                    hasPassword: true,
                    username: params?.username,
                    contestParticipant: params?.contestParticipant,
                    instagramHandle: params?.instagramHandle
                })
            });

            if (!response.ok) {
                if (response.status === 409) {
                    console.log('User already exists (409)');
                    return;
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error creating user:', error);
            throw error;
        }
    };

    const searchUsers = async (query: string): Promise<SearchResult[]> => {
        if (!query.trim()) return [];

        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/users/search?query=${encodeURIComponent(query)}`,
            {
                headers: sessionToken ? {
                    'Authorization': `Bearer ${sessionToken}`
                } : {}
            }
        );

        if (!response.ok) {
            throw new Error('Failed to search users');
        }

        return response.json();
    };

    const searchCars = async (query: string): Promise<SearchResult[]> => {
        if (!query.trim()) return [];

        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/cars/search?query=${encodeURIComponent(query)}`,
            {
                headers: sessionToken ? {
                    'Authorization': `Bearer ${sessionToken}`
                } : {}
            }
        );

        if (!response.ok) {
            throw new Error('Failed to search cars');
        }

        return response.json();
    };

    const addToLastVisited = async (carId: string): Promise<void> => {
        if (!sessionToken) throw new Error('No session token available');

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/last-visited`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionToken}`
            },
            body: JSON.stringify({ carId })
        });

        if (!response.ok) {
            throw new Error('Failed to add to last visited');
        }

        await mutateLastVisited();
    };

    const clearLastVisited = async (): Promise<void> => {
        if (!sessionToken) throw new Error('No session token available');

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/last-visited`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${sessionToken}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to clear last visited');
        }

        await mutateLastVisited();
    };

    const addToLastVisitedUsers = async (userId: string): Promise<void> => {
        if (!sessionToken) throw new Error('No session token available');

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/last-visited-users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionToken}`
            },
            body: JSON.stringify({ visitedUserId: userId })
        });

        if (!response.ok) {
            throw new Error('Failed to add to last visited users');
        }

        await mutateLastVisitedUsers();
    };

    const clearLastVisitedUsers = async (): Promise<void> => {
        if (!sessionToken) throw new Error('No session token available');

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/last-visited-users`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${sessionToken}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to clear last visited users');
        }

        await mutateLastVisitedUsers();
    };

    const verifyEmail = async (sessionToken: string): Promise<void> => {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/verify`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${sessionToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to verify email');
        }

        await mutateUser();
    };

    return {
        user,
        createUser,
        updateUser: updateUserWithProfilePicture,
        getUserByUsername,
        error,
        isLoading,
        isError: !!error,
        searchUsers,
        searchCars,
        lastVisited: lastVisited?.cars || [],
        addToLastVisited,
        clearLastVisited,
        lastVisitedUsers: lastVisitedUsers?.users || [],
        addToLastVisitedUsers,
        clearLastVisitedUsers,
        mutateUser,
        verifyEmail,
    };
};

export const useFavoriteMeets = () => {
    const stytch = useStytch();
    const sessionToken = stytch.session.getTokens()?.session_token;

    const { data: favorites, error, isLoading, mutate: mutateFavorites } = useSWR<string[]>(
        sessionToken ? [`${process.env.REACT_APP_API_BASE_URL}/users/favorites`, sessionToken] : null,
        fetcher,
        defaultConfig
    );

    const addFavorite = async (meetId: string) => {
        try {
            await mutationFetcher(
                `${process.env.REACT_APP_API_BASE_URL}/users/favorite`,
                { arg: { meetId } },
                sessionToken
            );
            mutateFavorites();
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error adding favorite:', error);
            throw error;
        }
    };

    const removeFavorite = async (meetId: string) => {
        try {
            await mutationFetcher(
                `${process.env.REACT_APP_API_BASE_URL}/users/unfavorite`,
                { arg: { meetId } },
                sessionToken
            );
            mutateFavorites();
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error removing favorite:', error);
            throw error;
        }
    };

    return {
        favorites: favorites || [],
        error,
        isLoading,
        isError: !!error,
        addFavorite,
        removeFavorite
    };
}; 