import React from 'react';
import { Card, CardContent, Box, Typography, CardMedia } from '@mui/material';
import { Add as AddIcon, DirectionsCar as DirectionsCarIcon } from '@mui/icons-material';
import carmeeterCar1 from '../../assets/carmeeter-car-1-grey-transparent.png';
interface AddCarCardProps {
    onClick: () => void;
}

export default function AddCarCard({ onClick }: AddCarCardProps) {
    return (
        <Card
            onClick={onClick}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                bgcolor: 'background.paper',
                cursor: 'pointer',
                '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                },
                WebkitTapHighlightColor: 'transparent',
                position: 'relative',
                border: '2px dashed',
                borderColor: 'divider',
            }}
        >
            <Box sx={{ position: 'relative', height: '125px' }}>
                <CardMedia
                    component="div"
                    sx={{
                        height: '100%',
                        bgcolor: 'action.hover',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <img src={carmeeterCar1} alt="Carmeeter Car" style={{ width: '200px', height: '200px' }} />
                </CardMedia>
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
                p: 3
            }}>
                <Box
                    sx={{
                        width: 60,
                        height: 60,
                        borderRadius: '50%',
                        backgroundColor: 'action.hover',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 2
                    }}
                >
                    <AddIcon sx={{ fontSize: 32, color: 'text.secondary' }} />
                </Box>
                <Typography variant="h6" color="text.secondary">
                    Auto Toevoegen
                </Typography>
            </Box>
        </Card>
    );
}