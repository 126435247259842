import React, { useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    IconButton,
} from '@mui/material';
import { CreateCarDTO } from '../../../types/Car';
import ImageUpload from '../../image-upload/ImageUpload';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';

const MAX_IMAGES = 5;

interface ImageStepProps {
    formData: CreateCarDTO;
    onChange: (updates: Partial<CreateCarDTO>) => void;
    onFilesChange: (files: File[]) => void;
    pendingFiles: File[];
}

const ImageStep: React.FC<ImageStepProps> = ({ formData, onChange, onFilesChange, pendingFiles }) => {
    const [error, setError] = useState<string | null>(null);
    const scrollContainerRef = React.useRef<HTMLDivElement>(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);

    const handleImagesChange = (newFiles: File[]) => {
        setError(null);
        onFilesChange(newFiles);
    };

    const handleImageDelete = (indexToDelete: number) => {
        const currentImages = formData.images || [];
        onChange({ images: currentImages.filter((_, index) => index !== indexToDelete) });
        onFilesChange(pendingFiles.filter((_, index) => index !== indexToDelete));
    };

    const checkScrollButtons = () => {
        if (scrollContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 1);
        }
    };

    const handleScroll = (direction: 'left' | 'right') => {
        if (scrollContainerRef.current) {
            const scrollAmount = 108; // image width + gap
            const newScrollPosition = scrollContainerRef.current.scrollLeft +
                (direction === 'left' ? -scrollAmount : scrollAmount);
            scrollContainerRef.current.scrollTo({
                left: newScrollPosition,
                behavior: 'smooth'
            });
        }
    };

    // Add useEffect to initialize scroll buttons
    React.useEffect(() => {
        // Initial check after render
        const initialCheck = setTimeout(() => {
            checkScrollButtons();
        }, 100);
        
        // Add resize listener to recalculate on window size changes
        window.addEventListener('resize', checkScrollButtons);
        
        return () => {
            clearTimeout(initialCheck);
            window.removeEventListener('resize', checkScrollButtons);
        };
    }, [formData.images, pendingFiles]);

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Foto&lsquo;s
            </Typography>
            <Typography color="text.secondary" sx={{ mb: 3 }}>
                Voeg maximaal {MAX_IMAGES} foto&lsquo;s toe van je auto.
            </Typography>

            <Paper
                sx={{
                    width: '100%',
                    p: 1,
                    // bgcolor: 'background.default',
                }}
            >
                {/* Existing Images */}
                {(formData?.images?.length === 0 && pendingFiles.length === 0) && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200, border: '1px solid', borderColor: 'secondary.main', borderRadius: 2 }}>
                        <Typography color="text.secondary" variant="h6">
                            Voeg foto&lsquo;s toe van je auto.
                        </Typography>
                    </Box>
                )}

                {/* Show both existing and pending images */}
                {(formData?.images?.length && formData?.images?.length > 0 || pendingFiles.length > 0) && (
                    <Box sx={{ position: 'relative', mb: 3 }}>
                        {canScrollLeft && (
                            <IconButton
                                size="small"
                                onClick={() => handleScroll('left')}
                                sx={{
                                    height: 24,
                                    width: 24,
                                    opacity: 0.6,
                                    position: 'absolute',
                                    left: 0,
                                    color: 'primary.main',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    bgcolor: 'secondary.main',
                                    '&:hover': { bgcolor: 'secondary.main' },
                                    zIndex: 9999,
                                }}
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        )}
                        {canScrollRight && (
                            <IconButton
                                size="small"
                                onClick={() => handleScroll('right')}
                                sx={{
                                    height: 24,
                                    width: 24,
                                    opacity: 0.6,
                                    position: 'absolute',
                                    right: 0,
                                    color: 'primary.main',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    bgcolor: 'secondary.main',
                                    '&:hover': { bgcolor: 'secondary.main' },
                                    zIndex: 9999,
                                }}
                            >
                                <ChevronRightIcon />
                            </IconButton>
                        )}
                        <Box
                            ref={scrollContainerRef}
                            onScroll={checkScrollButtons}
                            sx={{
                                display: 'flex',
                                gap: 1,
                                overflowX: 'auto',
                                scrollbarWidth: 'none',
                                '&::-webkit-scrollbar': {
                                    display: 'none'
                                },
                                msOverflowStyle: 'none',
                                px: 1,
                            }}
                        >
                            {/* Render existing uploaded images */}
                            {formData.images?.map((image, index) => (
                                <Box
                                    key={`existing-${index}`}
                                    sx={{
                                        position: 'relative',
                                        minWidth: 100,
                                        height: 200,
                                        aspectRatio: Number(process.env.REACT_APP_ASPECT_RATIO),
                                        borderRadius: 1,
                                        overflow: 'hidden',
                                        flexShrink: 0,
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={`${process.env.REACT_APP_API_BASE_URL}${image}`}
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                    <IconButton
                                        onClick={() => handleImageDelete(index)}
                                        sx={{
                                            position: 'absolute',
                                            top: 4,
                                            right: 4,
                                            padding: '4px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.7)'
                                            }
                                        }}
                                    >
                                        <DeleteIcon sx={{ fontSize: 16, color: 'white' }} />
                                    </IconButton>
                                </Box>
                            ))}

                            {/* Render pending files with local preview */}
                            {pendingFiles.map((file, index) => (
                                <Box
                                    key={`pending-${index}`}
                                    sx={{
                                        position: 'relative',
                                        minWidth: 100,
                                        height: 200,
                                        aspectRatio: Number(process.env.REACT_APP_ASPECT_RATIO),
                                        borderRadius: 1,
                                        overflow: 'hidden',
                                        flexShrink: 0,
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={URL.createObjectURL(file)}
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                    <IconButton
                                        onClick={() => handleImageDelete(index)}
                                        sx={{
                                            position: 'absolute',
                                            top: 4,
                                            right: 4,
                                            padding: '4px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.7)'
                                            }
                                        }}
                                    >
                                        <DeleteIcon sx={{ fontSize: 16, color: 'white' }} />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}

                {/* Image Upload */}
                <ImageUpload
                    maxImages={MAX_IMAGES}
                    previewSize={0}
                    existingImagesCount={(formData.images?.length || 0) + pendingFiles.length}
                    images={pendingFiles}
                    onImagesChange={handleImagesChange}
                    buttonText="Foto&lsquo;s toevoegen"
                />
            </Paper>

            {error && (
                <Typography color="primary" sx={{ mt: 2 }}>
                    {error}
                </Typography>
            )}
        </Box>
    );
};

export default ImageStep; 