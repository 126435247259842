import useSWR from 'swr';
import { useStytch } from '@stytch/react';
import { Car, CreateCarDTO, UpdateCarDTO, CreateModificationDTO } from '../types/Car';
import { defaultConfig, fetcher, mutationFetcher } from '../lib/swr';
import { useState } from 'react';
import * as Sentry from '@sentry/react';

interface UploadImageResponse {
    imageUrl: string;
}

interface VoteStatus {
    hasVoted: boolean;
    streetCred: number; 
    message: string;
}

interface useCarsProps {
    ownerId?: string;
    carId?: string;
}

export const useCars = ({ ownerId, carId }: useCarsProps = {}) => {
    const stytch = useStytch();
    const sessionToken = stytch.session.getTokens()?.session_token;
    const [processingVotes, setProcessingVotes] = useState<Record<string, boolean>>({});

    const { data: cars, error, isLoading, mutate } = useSWR<Car[]>(
        sessionToken ? [
            `${process.env.REACT_APP_API_BASE_URL}/cars${ownerId ? `?ownerId=${ownerId}` : ''}`,
            sessionToken
        ] : null,
        fetcher,
        defaultConfig
    );

    const { data: car, error: carError, isLoading: isCarLoading, mutate: mutateCar } = useSWR<Car>(
        sessionToken && carId ? [`${process.env.REACT_APP_API_BASE_URL}/cars/${carId}`, sessionToken] : null,
        fetcher,
        defaultConfig
    );

    const createCar = async (carData: CreateCarDTO): Promise<Car> => {
        const response = await mutationFetcher(
            `${process.env.REACT_APP_API_BASE_URL}/cars`,
            { arg: carData, method: 'POST' },
            sessionToken
        );
        mutate();
        return response;
    };

    const updateCar = async (car: UpdateCarDTO): Promise<Car> => {
        const response = await mutationFetcher(
            `${process.env.REACT_APP_API_BASE_URL}/cars/${car.id}`,
            { arg: car, method: 'PUT' },
            sessionToken
        );
        mutate();
        return response;
    };

    const deleteCar = async (id: string): Promise<void> => {
        await mutationFetcher(
            `${process.env.REACT_APP_API_BASE_URL}/cars/${id}`,
            { arg: {}, method: 'DELETE' },
            sessionToken
        );
        mutate();
    };

    const addModification = async (carId: string, modification: CreateModificationDTO): Promise<Car> => {
        const response = await mutationFetcher(
            `${process.env.REACT_APP_API_BASE_URL}/cars/${carId}/modifications`,
            { arg: modification, method: 'POST' },
            sessionToken
        );
        mutate();
        return response;
    };

    const deleteModification = async (carId: string, modificationId: string): Promise<void> => {
        await mutationFetcher(
            `${process.env.REACT_APP_API_BASE_URL}/cars/${carId}/modifications/${modificationId}`,
            { arg: {}, method: 'DELETE' },
            sessionToken
        );
        mutate();
    };

    const voteStreetCred = async (carId: string): Promise<void> => {
        // If already processing a vote for this car, ignore the request
        if (processingVotes[carId]) {
            return;
        }

        // Get current values from cache before any updates
        const currentCars = cars || [];
        const currentCar = car;

        // Validate we have the necessary data
        if (!currentCar || !currentCar.model) {
            console.error('Car data is missing required properties');
            return;
        }

        try {
            setProcessingVotes(prev => ({ ...prev, [carId]: true }));

            // Helper function to update a car's vote status while preserving all properties
            const updateCarVotes = (car: Car): Car => {
                if (car.id === carId) {
                    const newHasVoted = !car.hasVoted;
                    return {
                        ...car,  // Preserve ALL car properties
                        hasVoted: newHasVoted,
                        streetCred: car.streetCred + (newHasVoted ? 1 : -1)
                    };
                }
                return car;
            };

            // Optimistically update both caches
            if (currentCars.length > 0) {
                await mutate(
                    async () => {
                        const updatedCars = currentCars.map(updateCarVotes);
                        return updatedCars;
                    },
                    {
                        optimisticData: currentCars.map(updateCarVotes),
                        revalidate: false,
                        populateCache: true
                    }
                );
            }

            await mutateCar(
                async () => {
                    const updatedCar = updateCarVotes(currentCar);
                    return updatedCar;
                },
                {
                    optimisticData: updateCarVotes(currentCar),
                    revalidate: false,
                    populateCache: true
                }
            );

            // Make the API call
            const voteStatus = await mutationFetcher(
                `${process.env.REACT_APP_API_BASE_URL}/cars/${carId}/vote`,
                { method: 'POST', arg: {} },
                sessionToken
            ) as VoteStatus;

            // Update both caches with the server response
            if (currentCars.length > 0) {
                await mutate(
                    async () => {
                        const updatedCars = currentCars.map(car => 
                            car.id === carId ? { 
                                ...car, 
                                streetCred: voteStatus.streetCred,
                                hasVoted: voteStatus.hasVoted 
                            } : car
                        );
                        return updatedCars;
                    },
                    {
                        optimisticData: currentCars.map(car => 
                            car.id === carId ? { 
                                ...car, 
                                streetCred: voteStatus.streetCred,
                                hasVoted: voteStatus.hasVoted 
                            } : car
                        ),
                        revalidate: false,
                        populateCache: true
                    }
                );
            }

            await mutateCar(
                async () => ({ 
                    ...currentCar, 
                    streetCred: voteStatus.streetCred,
                    hasVoted: voteStatus.hasVoted 
                }),
                {
                    optimisticData: { 
                        ...currentCar, 
                        streetCred: voteStatus.streetCred,
                        hasVoted: voteStatus.hasVoted 
                    },
                    revalidate: false,
                    populateCache: true
                }
            );

            // Trigger a refresh of the user profile data
            const userProfileEvent = new CustomEvent('refreshUserProfile');
            window.dispatchEvent(userProfileEvent);
        } catch (error) {
            Sentry.captureException(error);
            // Revalidate both caches on error to ensure consistent state
            if (currentCars.length > 0) {
                await mutate(
                    async () => currentCars,
                    { revalidate: true }
                );
            }
            await mutateCar(
                async () => currentCar,
                { revalidate: true }
            );
            console.error('Error toggling street cred:', error);
            throw error;
        } finally {
            setProcessingVotes(prev => ({ ...prev, [carId]: false }));
        }
    };

    const uploadCarImage = async (formData: FormData): Promise<UploadImageResponse> => {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cars/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${sessionToken}`
            },
            credentials: 'include',
            body: formData
        });

        if (!response.ok) {
            throw new Error('Failed to upload image');
        }

        return response.json();
    };

    return {
        cars: cars || [],
        car,
        error,
        isLoading,
        isCarLoading,
        isError: !!error,
        mutate,
        mutateCar,
        createCar,
        updateCar,
        deleteCar,
        addModification,
        deleteModification,
        voteStreetCred,
        isVoteProcessing: (carId: string) => processingVotes[carId] || false,
        uploadCarImage,
    };
};
