import React, { useState } from 'react';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Button,
    Paper,
    useMediaQuery,
    useTheme,
    Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { MeetAttendee } from '../../types/Meet';
import { getFullImageUrl, getManufacturerLogo } from '../../utils/imageUtils';
import { useNavigate } from 'react-router-dom';
import { formatCarModel } from '../../utils/car-utils';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { useStytchUser } from '@stytch/react';

interface AttendeesListProps {
    attendees: MeetAttendee[];
    maxInitialVisible?: number;
}

const AttendeesList: React.FC<AttendeesListProps> = ({
    attendees,
    maxInitialVisible = 3
}) => {

    const [expanded, setExpanded] = useState(false);
    const { user: stytchUser } = useStytchUser();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const visibleAttendees = expanded ? attendees : attendees.slice(0, maxInitialVisible);
    const hasMore = attendees.length > maxInitialVisible;

    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };

    const handleListItemClick = (attendee: MeetAttendee) => {
        if (attendee.car) {
            navigate(`/car/${attendee.car.id}`);
        } else {
            navigate(`/user/${attendee.username}`);
        }
    };

    return (
        <Box sx={{ marginY: 3 }}>
            <Typography variant="h6" gutterBottom>
                Deelnemers
            </Typography>
            {attendees.length > 0 ? stytchUser ? (
                <Paper
                    elevation={0}
                    sx={{
                        backgroundColor: 'background.paper',
                        overflow: 'hidden',
                    }}
                >
                    <List disablePadding>
                        {visibleAttendees.map((attendee, index) => (
                            <React.Fragment key={attendee.id}>
                                <ListItem
                                    onClick={() => handleListItemClick(attendee)}
                                    sx={{
                                        backgroundColor: 'background.default',
                                        cursor: 'pointer',
                                        mb: 1,
                                    }}
                                >
                                    <Grid container>
                                        <Grid item xs={10} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <ListItemAvatar
                                                sx={{ cursor: 'pointer', minWidth: 50 }}
                                            >
                                                <Avatar src={getFullImageUrl(attendee.profilePicture)} sx={{ bgcolor: 'primary.main' }} />
                                            </ListItemAvatar>

                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            fontWeight: 'medium',
                                                            '&:hover': {
                                                                color: 'primary.main'
                                                            }
                                                        }}
                                                    >
                                                        {attendee.username}
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={6}>
                                            {attendee.car && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: isMobile ? 'flex-end' : 'flex-start',
                                                        height: '100%',
                                                        px: isMobile ? 0.5 : 1
                                                    }}
                                                >
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        {getManufacturerLogo(attendee.car.make) !== undefined ? (
                                                            <img
                                                                src={getManufacturerLogo(attendee.car.make)}
                                                                alt={attendee.car.make}
                                                                style={{
                                                                    width: 32,
                                                                    height: 32,
                                                                    marginRight: isMobile ? 0 : 8,
                                                                    backgroundColor: 'transparent',
                                                                    objectFit: 'contain'
                                                                }}
                                                            />
                                                        ) : (
                                                            <DirectionsCarIcon sx={{ fontSize: '24px', mr: isMobile ? 0 : 1 }} />
                                                        )}
                                                        {!isMobile && (
                                                            <Box>
                                                                <Typography
                                                                    variant="body2"
                                                                    sx={{
                                                                        fontWeight: 'bold',
                                                                        lineHeight: 1.2
                                                                    }}
                                                                >
                                                                    {attendee.car.make}
                                                                </Typography>
                                                                <Typography
                                                                    variant="caption"
                                                                    sx={{
                                                                        display: 'block',
                                                                        color: 'text.secondary',
                                                                        lineHeight: 1.2
                                                                    }}
                                                                >
                                                                    {formatCarModel(attendee.car.model)}
                                                                    {attendee.car.year && ` (${attendee.car.year})`}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Box>
                                            )}
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>

                    {hasMore && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                p: 1,
                                background: 'transparent'
                            }}
                        >
                            <Button
                                onClick={handleToggleExpand}
                                endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                variant='text'
                                size='small'
                                color='secondary'
                                sx={{
                                    margin: '0 auto',
                                    textAlign: 'center',
                                }}
                            >
                                {expanded ? 'Toon minder' : `Toon alle ${attendees.length} deelnemers`}
                            </Button>
                        </Box>
                    )}
                </Paper>
            ) : (
                <Typography variant="body2" color="text.secondary">
                    Log in om de deelnemers te zien
                </Typography>
            ) : (
                <Typography variant="body2" color="text.secondary">
                    Er zijn nog geen deelnemers geregistreerd voor deze meet.
                </Typography>
            )
            }
        </Box >
    );
};

export default AttendeesList; 