import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';

type TabBarProps = {
    view: 'list' | 'map';
    onViewChange: (view: 'list' | 'map') => void;
}

const TabBar: React.FC<TabBarProps> = ({ view, onViewChange }) => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: 1,
        }}>
            <Button
                data-testid="view-list"
                color='secondary'
                variant="text"
                onClick={() => onViewChange('list')}
                startIcon={view === 'list' ? <Typography>📋</Typography> : <ListIcon />}
                size="small"
                sx={{
                    border: '1px solid',
                    borderRight: '1px solid',
                    borderColor: 'secondary.main',
                    borderRadius: 0,
                    backgroundColor: view === 'list' ? 'primary.main' : 'none',
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                    '&:focus': {
                        backgroundColor: view === 'list' ? 'primary.main' : 'none',
                    },
                    height: 28,
                }}
            >
                <Typography variant={'body2'}>Lijst</Typography>
            </Button>
            <Button
                data-testid="view-map"
                color='secondary'
                variant="text"
                onClick={() => onViewChange('map')}
                startIcon={view === 'map' ? <Typography variant={'body2'}>🗺️</Typography> : <MapIcon />}
                size="small"
                sx={{
                    border: '1px solid',
                    borderLeft: '1px solid',
                    borderColor: 'secondary.main',
                    borderRadius: 0,
                    backgroundColor: view === 'map' ? 'primary.main' : 'none',
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                    '&:focus': {
                        backgroundColor: view === 'map' ? 'primary.main' : 'none',
                    },
                    height: 28,
                }}
            >
                <Typography variant={'body2'}>Kaart</Typography>
            </Button>
        </Box>
    );
};

export default TabBar;
