import { SWRConfiguration } from 'swr';
import { useStytch } from '@stytch/react';

export const getAuthHeader = (sessionToken?: string): Record<string, string> => 
    sessionToken ? { 'Authorization': `Bearer ${sessionToken}` } : {};

export const defaultConfig: SWRConfiguration = {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 0,
    shouldRetryOnError: true,
    dedupingInterval: 2000,
    keepPreviousData: true,
    revalidateIfStale: false
};

export const fetcher = async ([url, sessionToken]: [string, string | undefined]) => {
    const response = await fetch(url, {
        headers: {
            ...getAuthHeader(sessionToken)
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
};

interface MutationOptions {
    arg: any;
    method?: 'POST' | 'PUT' | 'DELETE' | 'PATCH';
}

export const mutationFetcher = async (url: string, { arg, method = 'POST' }: MutationOptions, sessionToken?: string) => {
    const response = await fetch(url, {
        method,
        headers: {
            'Content-Type': 'application/json',
            ...getAuthHeader(sessionToken)
        },
        body: JSON.stringify(arg)
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}; 