import React, { useState } from 'react';
import { Box, Button, Tooltip, Typography, styled } from '@mui/material';
import streetCredWhite from '../../assets/streetcred-white.png';
import streetCredGold from '../../assets/streetcred-gold.png';
import SmokeEffect from './SmokeEffect';
import * as Sentry from '@sentry/react';

const StyledIconButton = styled(Button)(({ theme }) => ({
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: theme.spacing(0.8),
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
        borderColor: theme.palette.secondary.main,
    },
    '&.voted': {
        borderColor: 'rgba(241, 193, 0, 0.7)',
        backgroundImage: 'linear-gradient(45deg, rgba(241, 193, 0, 0.2), rgba(241, 193, 0, 0.6))',
    },
    '&.Mui-disabled': {
        opacity: 0.7,
    }
}));

interface StreetCredButtonProps {
    fullWidth?: boolean;
    hasVoted: boolean;
    onVote: () => Promise<void>;
    disabled?: boolean;
}

export const StreetCredButton: React.FC<StreetCredButtonProps> = ({
    hasVoted,
    onVote,
    disabled = false,
    fullWidth = false
}) => {
    const [isVoted, setIsVoted] = useState(hasVoted);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showSmoke, setShowSmoke] = useState(false);
    const [showAnimation, setShowAnimation] = useState(false);

    const handleClick = async () => {
        if (!disabled && !isProcessing) {
            setIsProcessing(true);
            setIsVoted(!isVoted); // Optimistically update UI
            setShowSmoke(!isVoted);
            setShowAnimation(true);

            // Remove smoke effect after animation
            setTimeout(() => {
                setShowSmoke(false);
            }, 1000);

            try {
                await onVote();
            } catch (error) {
                Sentry.captureException(error);
                setIsVoted(isVoted);
                console.error('Error voting:', error);
            } finally {
                setIsProcessing(false);
            }
        }
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <span style={{ width: fullWidth ? '100%' : 'auto', display: 'inline-block' }}>
                <span style={{ width: fullWidth ? '100%' : 'auto', display: 'inline-block' }}>
                    <StyledIconButton
                        onClick={handleClick}
                        disabled={disabled}
                        className={isVoted ? 'voted' : ''}
                        fullWidth={fullWidth}
                        size="small"
                        sx={{
                            position: 'relative',
                            width: fullWidth ? '100%' : 'auto',
                            '& img.streetcred': {
                                width: '20px',
                                height: '20px',
                                mr: 1,
                                animation: showAnimation && isVoted ? 'twistBounce 0.5s' : 'none',
                                '@keyframes twistBounce': {
                                    '0%': { transform: 'scale(1) rotateZ(0)' },
                                    '50%': { transform: 'scale(1) rotateZ(720)' },
                                    '100%': { transform: 'scale(1) rotateZ(1440deg)' },
                                },
                            },
                        }}
                    >
                        <SmokeEffect show={showSmoke} positionX={0} positionY={10} />
                        <img
                            src={isVoted ? streetCredGold : streetCredWhite}
                            alt="Street Cred"
                            className="streetcred"
                        />
                        <Typography variant="body2" fontWeight="bold" color={isVoted ? 'rgba(241, 193, 0, 1.0)' : 'secondary'}>
                            StreetCred
                        </Typography>
                    </StyledIconButton>
                </span>
            </span>
        </Box>
    );
};
export default StreetCredButton; 