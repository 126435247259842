import React, { useState } from 'react';
import { Box } from '@mui/material';
import EventMapView from './EventMapView';
import EventMapModal from './EventMapModal';
import { Meet } from '../../types/Meet';
import { User } from '../../types/User';
import FullScreenLoader from '../loader/FullScreenLoader';
import { useUser } from '../../hooks/useUser';

type EventMapProps = {
    events: Meet[];
    userLocation?: { lat: number; lng: number } | null;
    onHighlight: (id: string) => void;
    onEdit: (id: string) => void;
    showFavorites: boolean;
    favorites: string[];
    handleFavorite: (id: string) => Promise<void>;
    isLoading: boolean;
}

const EventMap: React.FC<EventMapProps> = (props) => {
    const [selectedEvent, setSelectedEvent] = useState<Meet | null>(null);
    const { user } = useUser();

    return (
        <>
            {props.isLoading ? (
                <FullScreenLoader />
            ) : (
                <Box data-testid="map-view"
                    sx={{
                        width: '100%',
                        height: 'calc(100vh - 60px)',
                        position: 'fixed',
                        top: '107px',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        overflow: 'hidden'
                    }}>
                    <EventMapView {...props} />
                    <EventMapModal
                        selectedEvent={selectedEvent}
                        onClose={() => setSelectedEvent(null)}
                        userLocation={props.userLocation}
                        onHighlight={props.onHighlight}
                        onEdit={props.onEdit}
                        favorites={props.favorites}
                        handleFavorite={props.handleFavorite}
                    />
                </Box>
            )}
        </>
    );
};

export default EventMap;
