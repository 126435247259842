import React, { useState, useEffect, useCallback, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
import { MeetDTO, Meet } from '../../types/Meet';
import { Chip, IconButton, Typography, FormControlLabel, Checkbox, MenuItem, Switch, CircularProgress, InputAdornment, Tooltip, Link, Skeleton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import FullScreenLoader from '../loader/FullScreenLoader';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import RoomIcon from '@mui/icons-material/Room';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomZoomControl from '../event-map/CustomZoomControl';
import MapEventHandler from '../event-map/MapEventHandler';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MapSizeHandler from '../event-map/MapSizeHandler';
import MapCenterHandler from '../event-map/MapCenterHandler';
import DeleteConfirmation from '../delete-confirmation/DeleteConfirmation';
import { useMeets } from '../../hooks/useMeets';
import { useUser } from '../../hooks/useUser';
import MapMarkerIcon from '../event-map/MapMarkerIcon';
import * as Sentry from '@sentry/react';

const MAX_TITLE_LENGTH = 50;
const MAX_DESCRIPTION_LENGTH = 500;
const MAX_KEYWORDS = 3;

// Configure dayjs plugins
dayjs.extend(utc);

type EventFormProps = {
    meetId?: string;
}

type FieldErrors = {
    title: string;
    customTitle: string;
    location: string;
    date: string;
    keywords: string;
    description: string;
    recurringDay: string;
    terms: string;
};

const DEFAULT_COORDINATES: [number, number] = [52.1326, 5.2913];

const EventForm: React.FC<EventFormProps> = ({ meetId }) => {
    const libraries: ('places')[] = ['places'];
    const { meet, isLoading: isLoadingMeet, mutate: mutateMeet } = useMeets({ meetId });
    const { createMeet, updateMeet, deleteMeet, generateSeoDescriptionPreview, generateSeoKeywordsPreview, mutate: mutateMeets } = useMeets();
    const { user, isLoading: isLoadingUser, mutateUser } = useUser();
    const [title, setTitle] = useState('');
    const [customTitle, setCustomTitle] = useState('Nieuwe Car Meet');
    const [location, setLocation] = useState('');
    const [date, setDate] = useState<Dayjs | null>();
    const [keywords, setKeywords] = useState<string[]>(['']);
    const [error, setError] = useState('');
    const [isAutocompleteUsed, setIsAutocompleteUsed] = useState(false);
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
    const [latitude, setLatitude] = useState<number | null>(52.133488040771496);
    const [longitude, setLongitude] = useState<number | null>(5.295410156250001);
    const [isRecurring, setIsRecurring] = useState(false);
    const [recurringDay, setRecurringDay] = useState<number | null>(null);
    const [needsAdminAttention, setNeedsAdminAttention] = useState(false);
    const [description, setDescription] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [isRegenerating, setIsRegenerating] = useState(false);
    const [seoKeywords, setSeoKeywords] = useState<string[]>(meet?.seoKeywords || []);
    const [isRegeneratingKeywords, setIsRegeneratingKeywords] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [isMapExpanded, setIsMapExpanded] = useState(false);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
    const [fieldErrors, setFieldErrors] = useState<FieldErrors>({
        title: '',
        customTitle: '',
        location: '',
        date: '',
        keywords: '',
        description: '',
        recurringDay: '',
        terms: '',
    });
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isLoading = useMemo(() => {
        return isLoadingMeet || isLoadingUser;
    }, [isLoadingMeet, isLoadingUser]);

    const navigate = useNavigate();

    useEffect(() => {
        if (meet) {
            setTitle(meet.title || '');
            setCustomTitle(meet.customTitle || '');
            setLocation(meet.location);
            setDate(dayjs(meet.date).utc());
            setKeywords(meet.keywords || []);
            setIsAutocompleteUsed(true);
            setLatitude(meet.latitude);
            setLongitude(meet.longitude);
            setIsRecurring(meet.recurringDay !== null);
            setRecurringDay(meet.recurringDay);
            setNeedsAdminAttention(meet.needsAdminAttention || false);
            setDescription(meet.description || '');
            setSeoDescription(meet.seoDescription || '');
            setSeoKeywords(meet.seoKeywords || []);
        }
    }, [meet]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
        libraries: libraries
    });

    useEffect(() => {
        if (loadError) {
            console.error('Error loading Google Maps API:', loadError);
        }
    }, [loadError]);

    const onLoad = useCallback((autocomplete: google.maps.places.Autocomplete) => {
        setAutocomplete(autocomplete);
    }, []);

    const handleKeywordChange = (index: number, value: string) => {
        const newKeywords = [...keywords];
        newKeywords[index] = value;
        setKeywords(newKeywords);
    };

    const addKeyword = () => {
        if (keywords.filter(k => k.trim() !== '').length < MAX_KEYWORDS) {
            setKeywords([...keywords, '']);
        }
    };

    const removeKeyword = (index: number) => {
        const newKeywords = keywords.filter((_, i) => i !== index);
        setKeywords(newKeywords);
    };

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry && place.geometry.location) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                setLocation(place.formatted_address || '');
                setLatitude(lat);
                setLongitude(lng);
                setIsAutocompleteUsed(true);

                // Extract city from address components
                const addressComponents = place.address_components;
                const cityComponent = addressComponents?.find(
                    component =>
                        component.types.includes('locality') ||
                        component.types.includes('postal_town')
                );

                if (cityComponent) {
                    const city = cityComponent.long_name;
                    setCustomTitle(`Car Meet ${city}`);
                }
            } else {
                setLocation(place.name || '');
                setLatitude(null);
                setLongitude(null);
                setIsAutocompleteUsed(false);
            }
        }
    };

    const validateFields = (): boolean => {
        const errors: FieldErrors = {
            title: '',
            customTitle: '',
            location: '',
            date: '',
            keywords: '',
            description: '',
            recurringDay: '',
            terms: '',
        };

        let isValid = true;

        // Custom title validation
        if (!customTitle.trim()) {
            errors.customTitle = 'Titel is verplicht';
            isValid = false;
        } else if (customTitle.length > MAX_TITLE_LENGTH) {
            errors.customTitle = `Titel mag maximaal ${MAX_TITLE_LENGTH} karakters bevatten`;
            isValid = false;
        }

        // Title (subtitle) validation
        if (!title.trim()) {
            errors.title = 'Ondertitel is verplicht';
            isValid = false;
        } else if (title.length > MAX_TITLE_LENGTH) {
            errors.title = `Ondertitel mag maximaal ${MAX_TITLE_LENGTH} karakters bevatten`;
            isValid = false;
        }

        // Location validation
        if (!location.trim()) {
            errors.location = 'Locatie is verplicht';
            isValid = false;
        } else if (!isAutocompleteUsed && (!latitude || !longitude)) {
            errors.location = 'Selecteer een geldige locatie uit de suggesties';
            isValid = false;
        }

        // Date validation
        if (!date) {
            errors.date = 'Datum is verplicht';
            isValid = false;
        } else {
            const selectedDate = date.toDate();
            const now = new Date();
            if (selectedDate < now && !isRecurring) {
                errors.date = 'De datum mag niet in het verleden liggen';
                isValid = false;
            }
        }

        // Recurring day validation
        if (isRecurring && recurringDay === null) {
            errors.recurringDay = 'Selecteer een dag voor wekelijkse herhaling';
            isValid = false;
        }

        // Keywords validation
        const validKeywords = keywords.filter(k => k.trim() !== '');
        if (validKeywords.length === 0) {
            errors.keywords = 'Voeg minimaal één tag toe';
            isValid = false;
        }

        // Description validation
        if (seoDescription.length > MAX_DESCRIPTION_LENGTH) {
            errors.description = `Beschrijving mag maximaal ${MAX_DESCRIPTION_LENGTH} karakters bevatten`;
            isValid = false;
        }

        // Terms validation for new meets
        if (!meet?.id && !hasAcceptedTerms) {
            errors.terms = 'Je moet akkoord gaan met de voorwaarden voor organisatoren';
            isValid = false;
        }

        setFieldErrors(errors);
        return isValid;
    };

    const handleSubmit = async () => {
        try {
            if (!validateFields()) {
                return;
            }

            if (!location || !date || !user?.id) {
                setError('Vul alle velden in');
                return;
            }
            if (!isAutocompleteUsed && (!latitude || !longitude)) {
                setError('Gebruik een geldig adres voor de locatie.');
                return;
            }

            setIsSubmitting(true);
            setError('');

            const meetData: MeetDTO = {
                title,
                customTitle,
                location,
                date: date ? date.utc().format() : '',
                host: { id: user?.id },
                keywords: keywords.filter(keyword => keyword.trim() !== ''),
                latitude,
                longitude,
                recurringDay: recurringDay,
                needsAdminAttention,
                seoDescription,
                seoKeywords: seoKeywords,
            };

            if (meet?.id) {
                await updateMeet(meet.id, meetData);
            } else {
                await createMeet(meetData);
            }

            await mutateMeets();
            await mutateMeet();
            await mutateUser();
            navigate('/', { replace: true });
            setIsSubmitting(false);
        } catch (error) {
            Sentry.captureException(error);
            console.error('Failed to save meet:', error);
            setError('Er ging iets fout bij het opslaan van de meet. Probeer het opnieuw.');
            setIsSubmitting(false);
        }
    };

    const handleGenerateSeoDescription = async () => {
        try {
            setIsRegenerating(true);
            if (!meet?.id) {
                const meetData: Partial<Meet> = {
                    title,
                    customTitle,
                    location,
                    keywords: keywords.filter(keyword => keyword.trim() !== ''),
                };

                const newDescription = await generateSeoDescriptionPreview(meetData);
                if (newDescription) {
                    setSeoDescription(newDescription);
                }
            } else {
                const newDescription = await generateSeoDescriptionPreview(meet);
                if (newDescription) {
                    setSeoDescription(newDescription);
                }
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error('Failed to regenerate SEO description:', error);
        } finally {
            setIsRegenerating(false);
        }
    };

    const handleGenerateSeoKeywords = async () => {
        try {
            setIsRegeneratingKeywords(true);
            if (!meet?.id) {
                const eventData: Partial<Meet> = {
                    title,
                    customTitle,
                    location,
                    keywords: keywords.filter(keyword => keyword.trim() !== ''),
                };
                const newKeywords = await generateSeoKeywordsPreview(eventData);
                if (newKeywords) {
                    setSeoKeywords(newKeywords);
                }
            } else {
                const newKeywords = await generateSeoKeywordsPreview(meet);
                if (newKeywords) {
                    setSeoKeywords(newKeywords);
                }
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error('Failed to regenerate SEO keywords:', error);
        } finally {
            setIsRegeneratingKeywords(false);
        }
    };

    function handleMapMoveEnd(center: { lat: number; lng: number; }): void {
        if (center.lat !== latitude || center.lng !== longitude) {
            setLatitude(center.lat);
            setLongitude(center.lng);

            // Reverse geocode the coordinates to get the address
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
                { location: { lat: center.lat, lng: center.lng } },
                (results, status) => {
                    if (status === 'OK' && results && results[0]) {
                        const address = results[0].formatted_address;
                        setLocation(address);

                        // Extract city from address components and update custom title
                        const cityComponent = results[0].address_components?.find(
                            component =>
                                component.types.includes('locality') ||
                                component.types.includes('postal_town')
                        );

                        if (cityComponent) {
                            const city = cityComponent.long_name;
                            setCustomTitle(`Car Meet ${city}`);
                        }
                    }
                }
            );
        }
    }

    const handleDeleteConfirm = async () => {
        if (meet?.id) {
            setIsDeleting(true);
            try {
                await deleteMeet(meet.id);
                navigate('/');
            } catch (error) {
                Sentry.captureException(error);
                console.error('Failed to delete event:', error);
            } finally {
                setIsDeleting(false);
                setShowDeleteConfirmation(false);
            }
        }
    };

    const handleDeleteClick = () => {
        setShowDeleteConfirmation(true);
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" sx={{
            '@media (min-width: 768px)': {
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'center',
                width: '100%',
            }
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                '@media (min-width: 768px)': {
                    width: '600px',
                },
            }}>
                {isLoading && <FullScreenLoader />}
                <Box sx={{ padding: 2, margin: 2, backgroundColor: 'background.paper' }}>
                    <Box sx={{ marginBottom: 4 }}>
                        {user?.isAdmin ?
                            <TextField
                                fullWidth
                                label="Titel"
                                value={customTitle}
                                onChange={(e) => {
                                    if (e.target.value.length <= MAX_TITLE_LENGTH) {
                                        setCustomTitle(e.target.value);
                                        setFieldErrors(prev => ({ ...prev, customTitle: '' }));
                                    }
                                }}
                                error={!!fieldErrors.customTitle}
                                helperText={fieldErrors.customTitle || `${customTitle.length}/${MAX_TITLE_LENGTH} karakters`}
                                FormHelperTextProps={{
                                    sx: {
                                        textAlign: 'right',
                                        mr: 0,
                                        color: fieldErrors.customTitle ? 'error.main' : 'text.secondary'
                                    }
                                }}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontFamily: 'Pricedown',
                                        fontSize: customTitle.length > 15 ? '18pt' : '32pt',
                                        lineHeight: '1.0',
                                        WebkitTextStroke: '1px black',
                                        textAlign: 'center',
                                    }
                                }}
                            /> :
                            <Typography
                                fontSize={customTitle.length > 15 ? '18pt' : '32pt'}
                                fontFamily="Pricedown"
                                textAlign="center"
                                sx={{
                                    WebkitTextStroke: '1px black',
                                }}
                            >
                                {customTitle}
                            </Typography>}
                    </Box>

                    {(isLoaded && !loadError) ? (
                        <Box sx={{
                            height: isMapExpanded ? '400px' : '150px',
                            marginBottom: 2,
                            borderRadius: 2,
                            overflow: 'hidden',
                            position: 'relative',
                            transition: 'height 0.3s ease-in-out'
                        }}>
                            <MapContainer
                                center={latitude && longitude ? [latitude, longitude] : [52.133488040771496, 5.295410156250001]}
                                zoom={7}
                                style={{ height: '100%', width: '100%' }}
                                zoomControl={false}
                                scrollWheelZoom={true}
                                dragging={true}
                                touchZoom={true}
                                doubleClickZoom={true}
                                boxZoom={true}
                                keyboard={true}
                                attributionControl={false}
                            >
                                <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png" />
                                <CustomZoomControl />
                                <MapEventHandler
                                    onMoveEnd={handleMapMoveEnd}
                                />
                                <MapSizeHandler isExpanded={isMapExpanded} />
                                <MapCenterHandler
                                    center={latitude && longitude ? [latitude, longitude] : DEFAULT_COORDINATES}
                                    zoom={latitude && longitude && location !== '' ? 15 : 7}
                                />
                                <IconButton
                                    sx={{
                                        position: 'absolute',
                                        zIndex: 999,
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        pointerEvents: 'none',
                                        '&:hover': { backgroundColor: 'background.paper' }
                                    }}
                                >
                                    <MapMarkerIcon />
                                </IconButton>
                            </MapContainer>
                            <IconButton
                                onClick={() => setIsMapExpanded(!isMapExpanded)}
                                sx={{
                                    position: 'absolute',
                                    bottom: 8,
                                    right: 8,
                                    backgroundColor: 'background.paper',
                                    '&:hover': { backgroundColor: 'background.paper' },
                                    zIndex: 1000,
                                }}
                            >
                                {isMapExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </Box>
                    ) : (
                        <Box sx={{ height: '150px', marginBottom: 2 }}>
                            <Skeleton variant="rectangular" height="100%" />
                        </Box>
                    )}
                    <Box sx={{ marginBottom: 4 }}>
                        <TextField
                            data-testid="meet-subtitle-input"
                            fullWidth
                            label="Ondertitel"
                            value={title}
                            onChange={(e) => {
                                if (e.target.value.length <= MAX_TITLE_LENGTH) {
                                    setTitle(e.target.value);
                                    setFieldErrors(prev => ({ ...prev, title: '' }));
                                }
                            }}
                            placeholder='Korte beschrijving van de meet'
                            autoComplete='off'
                            error={!!fieldErrors.title}
                            helperText={fieldErrors.title || `${title.length}/${MAX_TITLE_LENGTH} karakters`}
                            FormHelperTextProps={{
                                sx: {
                                    textAlign: 'right',
                                    mr: 0,
                                    color: fieldErrors.title ? 'error.main' : 'text.secondary'
                                }
                            }}
                        />
                    </Box>
                    <Box sx={{ marginY: 2 }}>
                        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                            <Typography sx={{ marginRight: 2 }}>📅</Typography>
                            <Box sx={{ width: '100%' }}>
                                <Box display="flex" alignItems="center" sx={{ mb: isRecurring ? 2 : 0 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            data-testid="meet-date-input"
                                            format="DD/MM/YYYY HH:mm"
                                            ampm={false}
                                            label={isRecurring ? 'Startdatum' : 'Datum'}
                                            value={date}
                                            onChange={(newValue) => {
                                                if (newValue) {
                                                    const utcDate = newValue.utc(true);
                                                    setDate(utcDate);
                                                    setFieldErrors(prev => ({ ...prev, date: '' }));
                                                } else {
                                                    setDate(null);
                                                }
                                            }}
                                            timezone="UTC"
                                            closeOnSelect
                                            minutesStep={15}
                                            sx={{ width: '100%' }}
                                            slotProps={{
                                                textField: {
                                                    error: !!fieldErrors.date,
                                                    helperText: fieldErrors.date
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                    {user?.isAdmin && <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isRecurring}
                                                onChange={(e) => {
                                                    setIsRecurring(e.target.checked);
                                                    if (!e.target.checked) {
                                                        setRecurringDay(null);
                                                    }
                                                }}
                                            />
                                        }
                                        label="Wekelijks"
                                        sx={{ ml: 2 }}
                                    />}
                                </Box>
                                {isRecurring && (
                                    <TextField
                                        select
                                        fullWidth
                                        label="Dag van de week"
                                        value={recurringDay !== null ? recurringDay : ''}
                                        onChange={(e) => {
                                            setRecurringDay(Number(e.target.value));
                                            setFieldErrors(prev => ({ ...prev, recurringDay: '' }));
                                        }}
                                        error={!!fieldErrors.recurringDay}
                                        helperText={fieldErrors.recurringDay}
                                        sx={{ mb: 2 }}
                                    >
                                        <MenuItem value={0}>Zondag</MenuItem>
                                        <MenuItem value={1}>Maandag</MenuItem>
                                        <MenuItem value={2}>Dinsdag</MenuItem>
                                        <MenuItem value={3}>Woensdag</MenuItem>
                                        <MenuItem value={4}>Donderdag</MenuItem>
                                        <MenuItem value={5}>Vrijdag</MenuItem>
                                        <MenuItem value={6}>Zaterdag</MenuItem>
                                    </TextField>
                                )}
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" width="100%">
                            <Typography sx={{ marginRight: 2 }}>📍</Typography>
                            <div style={{ width: '100%', position: 'relative' }}>
                                {isLoaded && !loadError ? (
                                    <Autocomplete
                                        onLoad={onLoad}
                                        onPlaceChanged={onPlaceChanged}
                                        options={{
                                            types: ['address'],
                                            componentRestrictions: { country: 'nl' },
                                            fields: ['address_components', 'formatted_address', 'name', 'geometry'],
                                        }}
                                    >
                                        <TextField
                                            data-testid="meet-location-input"
                                            fullWidth
                                            label="Locatie"
                                            value={location}
                                            onChange={(e) => {
                                                setLocation(e.target.value);
                                                setFieldErrors(prev => ({ ...prev, location: '' }));
                                            }}
                                            error={!!fieldErrors.location}
                                            helperText={fieldErrors.location}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            title={
                                                                <Typography fontSize="12px">
                                                                    Voer een volledig adres met straatnaam en huisnummer in, of gebruik de kaart bovenin om de exacte locatie aan te geven.
                                                                </Typography>
                                                            }
                                                            arrow
                                                            placement="top"
                                                            enterTouchDelay={0}
                                                            leaveTouchDelay={8000}
                                                        >
                                                            <IconButton
                                                                size="small"
                                                                sx={{ color: 'text.secondary' }}
                                                                aria-label="location info"
                                                                onClick={(e) => e.stopPropagation()}
                                                            >
                                                                <InfoOutlinedIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Autocomplete>
                                ) : (
                                    <TextField
                                        fullWidth
                                        label="Locatie"
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                        disabled={!isLoaded}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        title={
                                                            <Typography fontSize="12px">
                                                                Voer een volledig adres met straatnaam en huisnummer in voor de beste resultaten.
                                                                Geef eventueel extra informatie over de locatie in de ondertitel of beschrijving.
                                                            </Typography>
                                                        }
                                                        arrow
                                                        placement="top"
                                                        enterTouchDelay={0}
                                                        leaveTouchDelay={5000}
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            sx={{ color: 'text.secondary' }}
                                                            aria-label="location info"
                                                            onClick={(e) => e.stopPropagation()}
                                                        >
                                                            <InfoOutlinedIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            </div>
                        </Box>
                    </Box>

                    {meet?.description && (
                        <Box sx={{ marginY: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Originele Beschrijving
                            </Typography>
                            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Typography
                                    variant="body1"
                                    fontSize="13pt"
                                    sx={{
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        WebkitLineClamp: showFullDescription ? 'unset' : 3,
                                        WebkitBoxOrient: 'vertical',
                                        transition: 'all 0.3s ease',
                                        position: 'relative',
                                        mb: 1,
                                    }}
                                >
                                    {description}
                                </Typography>
                                {!showFullDescription && description.length > 200 && (
                                    <Box
                                        sx={(theme) => ({
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            height: '100px',
                                            background: `linear-gradient(to bottom, 
                                                transparent 0%, 
                                                ${theme.palette.background.paper} 66%
                                            )`,
                                            pointerEvents: 'none',
                                            marginTop: '-100px',
                                        })}
                                    />
                                )}
                                {description.length > 200 && (
                                    <Button
                                        onClick={() => setShowFullDescription(!showFullDescription)}
                                        variant='outlined'
                                        size='small'
                                        color='secondary'
                                        sx={{
                                            margin: '0 auto',
                                            textAlign: 'center',
                                            width: '120px',
                                        }}
                                    >
                                        {showFullDescription ? 'Toon minder' : 'Toon meer'}
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    )}

                    <Box sx={{ marginY: 2 }}>
                        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <TextField
                                data-testid="meet-description-input"
                                fullWidth
                                multiline
                                rows={3}
                                label="Beschrijving"
                                value={seoDescription}
                                onChange={(e) => {
                                    if (e.target.value.length <= MAX_DESCRIPTION_LENGTH) {
                                        setSeoDescription(e.target.value);
                                        setFieldErrors(prev => ({ ...prev, description: '' }));
                                    }
                                }}
                                error={!!fieldErrors.description}
                                helperText={fieldErrors.description || `${seoDescription.length}/${MAX_DESCRIPTION_LENGTH} karakters`}
                                FormHelperTextProps={{
                                    sx: {
                                        textAlign: 'right',
                                        mr: 0,
                                        color: fieldErrors.description ? 'error.main' : 'text.secondary'
                                    }
                                }}
                                sx={{ mb: 1 }}
                            />
                            {user?.isAdmin && <Button
                                onClick={handleGenerateSeoDescription}
                                variant="outlined"
                                size="small"
                                color="secondary"
                                disabled={isRegenerating}
                                sx={{
                                    ml: 'auto',
                                    textAlign: 'center',
                                    width: '180px',
                                }}
                            >
                                {isRegenerating ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : (
                                    meet?.id ? 'Regenereer' : 'Genereer'
                                )}
                            </Button>}
                        </Box>
                    </Box>

                    <Box sx={{ marginY: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                            <Typography variant="h6">Tags</Typography>
                            <Typography
                                variant="body2"
                                color={fieldErrors.keywords ? 'error.main' : 'text.secondary'}
                                sx={{
                                    opacity: keywords.filter(k => k.trim() !== '').length >= MAX_KEYWORDS ? 0.7 : 1,
                                    animation: keywords.filter(k => k.trim() !== '').length >= MAX_KEYWORDS ? 'pulse 2s infinite' : 'none',
                                    '@keyframes pulse': {
                                        '0%': { opacity: 0.7 },
                                        '50%': { opacity: 0.4 },
                                        '100%': { opacity: 0.7 },
                                    }
                                }}
                            >
                                {fieldErrors.keywords || `${keywords.filter(k => k.trim() !== '').length}/${MAX_KEYWORDS} tags`}
                            </Typography>
                        </Box>
                        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box>
                                {keywords.map((keyword, index) => (
                                    <Chip
                                        data-testid={`meet-keyword-input-${index}`}
                                        key={index}
                                        label={
                                            <TextField
                                                value={keyword}
                                                onChange={(e) => handleKeywordChange(index, e.target.value)}
                                                variant="standard"
                                                InputProps={{ disableUnderline: true }}
                                                style={{ width: '100%', padding: '0 4px' }}
                                                placeholder='Voer in'
                                                inputProps={{ maxLength: 15 }}
                                            />
                                        }
                                        style={{
                                            margin: '2px',
                                            minWidth: '100px',
                                            maxWidth: keyword ? `${Math.max(90, Math.min(keyword.length * 12 + (index > 0 ? 22 : 0), 170))}px` : '80px',
                                        }}
                                        onDelete={index > 0 ? () => removeKeyword(index) : undefined}
                                    />
                                ))}
                                {keywords[keywords.length - 1] !== '' &&
                                    keywords.filter(k => k.trim() !== '').length < MAX_KEYWORDS && (
                                        <IconButton data-testid="meet-add-keyword" onClick={addKeyword} color="primary" size="small">
                                            <AddIcon fontSize="small" />
                                        </IconButton>
                                    )}
                            </Box>
                        </Box>
                        {user?.isAdmin && <Box>
                            <Typography variant="h6" color="text.secondary" gutterBottom sx={{ mt: 2 }}>
                                SEO Keywords
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                {seoKeywords.map((keyword, index) => (
                                    <Chip
                                        key={index}
                                        label={keyword}
                                        sx={{
                                            backgroundColor: 'primary.main',
                                            color: 'primary.contrastText',
                                        }}
                                    />
                                ))}
                            </Box>
                            <Button
                                onClick={handleGenerateSeoKeywords}
                                variant="outlined"
                                size="small"
                                color="secondary"
                                disabled={isRegeneratingKeywords}
                                sx={{
                                    marginLeft: 'auto',
                                    marginTop: '1rem',
                                    textAlign: 'center',
                                    width: '180px',
                                    display: 'block'
                                }}
                            >
                                {isRegeneratingKeywords ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : (
                                    meet?.id ? 'Regenereer' : 'Genereer'
                                )}
                            </Button>
                        </Box>}
                    </Box>

                    {user?.isAdmin && <Box sx={{ marginY: 2 }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={needsAdminAttention}
                                    onChange={(e) => setNeedsAdminAttention(e.target.checked)}
                                    color="warning"
                                />
                            }
                            label="Needs Admin Attention"
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Box>}
                    {error && <Typography variant="body1" color="primary">{error}</Typography>}

                    {!meet?.id && (
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        data-testid="meet-terms-checkbox"
                                        checked={hasAcceptedTerms}
                                        onChange={(e) => {
                                            setHasAcceptedTerms(e.target.checked);
                                            setFieldErrors(prev => ({ ...prev, terms: '' }));
                                        }}
                                    />
                                }
                                label={
                                    <Box>
                                        <Typography component="span">
                                            Ik ga akkoord met de{' '}
                                            <Link
                                                href="/host-terms.html"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{ color: 'secondary.main' }}
                                            >
                                                voorwaarden voor organisatoren.
                                            </Link>
                                        </Typography>
                                        {fieldErrors.terms && (
                                            <Typography color="primary" variant="caption" display="block">
                                                {fieldErrors.terms}
                                            </Typography>
                                        )}
                                    </Box>
                                }
                            />
                        </Box>
                    )}

                    <Box sx={{ mt: 3, display: 'flex', width: '100%' }}>
                        <Button
                            data-testid="submit-meet"
                            onClick={handleSubmit}
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            disabled={isSubmitting || (!meet?.id && !hasAcceptedTerms)}
                        >
                            {isSubmitting ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                meet?.id ? 'Bijwerken' : 'Publiceer meet'
                            )}
                        </Button>
                    </Box>
                    {meet?.id && (
                        <Box sx={{ mt: 2 }}>
                            <Button
                                variant="contained"
                                color="error"
                                size="large"
                                fullWidth
                                onClick={handleDeleteClick}
                            >
                                Verwijder meet
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
            <DeleteConfirmation
                type="meet"
                open={showDeleteConfirmation}
                onClose={() => setShowDeleteConfirmation(false)}
                onConfirm={handleDeleteConfirm}
                isLoading={isDeleting}
            />
        </Box >
    );
};

export default EventForm;
