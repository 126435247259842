import React from 'react';
import {
    Box,
    TextField,
    Typography,
    InputAdornment,
} from '@mui/material';
import { Instagram, YouTube, MusicNote } from '@mui/icons-material';
import { CreateCarDTO } from '../../../types/Car';

interface SocialMediaStepProps {
    formData: CreateCarDTO;
    onChange: (updates: Partial<CreateCarDTO>) => void;
}

const SocialMediaStep: React.FC<SocialMediaStepProps> = ({ formData, onChange }) => {
    // Extract username from full URL if it exists
    const extractUsername = (url: string | null | undefined, platform: 'instagram' | 'tiktok' | 'youtube'): string => {
        if (!url) return '';

        const patterns = {
            instagram: /(?:https?:\/\/)?(?:www\.)?instagram\.com\/([^/?]+)/,
            tiktok: /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/@?([^/?]+)/,
            youtube: /(?:https?:\/\/)?(?:www\.)?youtube\.com\/@?([^/?]+)/
        };

        const match = url.match(patterns[platform]);
        // Clean any @ symbols from the username
        return match ? match[1].replace(/^@/, '') : url.replace(/^@/, '');
    };

    // Convert username to full URL
    const createFullUrl = (username: string, platform: 'instagram' | 'tiktok' | 'youtube'): string => {
        if (!username) return '';

        const cleanUsername = username.trim().replace(/^@/, '');
        switch (platform) {
            case 'instagram':
                return `https://www.instagram.com/${cleanUsername}`;
            case 'tiktok':
                return `https://www.tiktok.com/@${cleanUsername}`;
            case 'youtube':
                return `https://www.youtube.com/@${cleanUsername}`;
        }
    };

    const handleChange = (field: keyof Pick<CreateCarDTO, 'instagramUrl' | 'tiktokUrl' | 'youtubeUrl'>) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const username = event.target.value.trim().replace(/^@/, ''); // Remove @ if user types it
        const platform = field === 'instagramUrl' ? 'instagram' :
            field === 'tiktokUrl' ? 'tiktok' : 'youtube';

        onChange({ [field]: username ? createFullUrl(username, platform) : '' });
    };

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
                Social Media Accounts
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
                Voeg je social media gebruikersnamen toe om je auto profielen te delen.
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                <TextField
                    fullWidth
                    label="Instagram"
                    placeholder="gebruikersnaam"
                    value={extractUsername(formData.instagramUrl ?? null, 'instagram')}
                    onChange={handleChange('instagramUrl')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Instagram />
                            </InputAdornment>
                        ),
                    }}
                />

                <TextField
                    fullWidth
                    label="TikTok"
                    placeholder="gebruikersnaam"
                    value={extractUsername(formData.tiktokUrl ?? null, 'tiktok')}
                    onChange={handleChange('tiktokUrl')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MusicNote />
                            </InputAdornment>
                        ),
                    }}
                />

                <TextField
                    fullWidth
                    label="YouTube"
                    placeholder="gebruikersnaam"
                    value={extractUsername(formData.youtubeUrl ?? null, 'youtube')}
                    onChange={handleChange('youtubeUrl')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <YouTube />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
    );
};

export default SocialMediaStep; 