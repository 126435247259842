import React from 'react';
import {
    Box,
    Grid,
    TextField,
    Typography,
    MenuItem
} from '@mui/material';
import { CreateCarDTO } from '../../../types/Car';

interface SpecificationsStepProps {
    formData: CreateCarDTO;
    onChange: (updates: Partial<CreateCarDTO>) => void;
}

const engineTypes = [
    'Inline 3',
    'Inline 4',
    'Inline 5',
    'Inline 6',
    'V6',
    'V8',
    'V10',
    'V12',
    'Boxer 4',
    'Boxer 6',
    'Wankel',
    'W12',
    'W16'
];

const transmissionTypes = [
    'Handgeschakeld',
    'Automatisch',
    'Semi-Automatisch',
    'CVT',
    'Overig'
];

const SpecificationsStep: React.FC<SpecificationsStepProps> = ({ formData, onChange }) => {
    const handleInputChange = (field: keyof CreateCarDTO, value: any) => {
        onChange({ [field]: value });
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Specificaties
            </Typography>
            <Typography color="text.secondary" sx={{ mb: 3 }}>
                Vul de technische details van je auto in
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        select
                        color='secondary'
                        fullWidth
                        label="Motor Type"
                        value={formData.engineType}
                        onChange={(e) => handleInputChange('engineType', e.target.value)}
                    >
                        {engineTypes.map((type) => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        color='secondary'
                        fullWidth
                        label="Cilinderinhoud (L)"
                        type="number"
                        value={formData.engineDisplacement}
                        onChange={(e) => {
                            const value = e.target.value;
                            // Only allow numbers with up to 1 decimal place
                            if (/^\d*\.?\d{0,1}$/.test(value) || value === '') {
                                handleInputChange('engineDisplacement', value === '' ? null : parseFloat(value));
                            }
                        }}
                        inputProps={{ step: 0.1, min: 0.1, max: 10.0, pattern: 'ˆ*.?{0,1}$' }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        color='secondary'
                        fullWidth
                        label="Bouwjaar"
                        type="number"
                        value={formData.year}
                        onChange={(e) => handleInputChange('year', parseInt(e.target.value))}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        color='secondary'
                        fullWidth
                        label="Kleur"
                        value={formData.color}
                        onChange={(e) => handleInputChange('color', e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        select
                        color='secondary'
                        fullWidth
                        label="Transmissie"
                        value={formData.transmission}
                        onChange={(e) => handleInputChange('transmission', e.target.value)}
                    >
                        {transmissionTypes.map((type) => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        color='secondary'
                        fullWidth
                        label="Vermogen (PK)"
                        type="number"
                        value={formData.horsePower || ''}
                        onChange={(e) => handleInputChange('horsePower', e.target.value ? parseInt(e.target.value) : undefined)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        color='secondary'
                        fullWidth
                        label="Koppel (NM)"
                        type="number"
                        value={formData.torque || ''}
                        onChange={(e) => handleInputChange('torque', e.target.value ? parseInt(e.target.value) : undefined)}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SpecificationsStep; 