import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useStytch } from '@stytch/react';
import { useUser } from '../hooks/useUser';
import * as Sentry from '@sentry/react';

const VerifyEmail = () => {
    const [error, setError] = useState('');
    const [message, setMessage] = useState('E-mail aan het verifiëren...');
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const stytch = useStytch();
    const hasVerified = useRef(false);
    const { verifyEmail, mutateUser } = useUser();

    useEffect(() => {
        const verifyToken = async () => {
            if (hasVerified.current) return;
            hasVerified.current = true;

            const token = new URLSearchParams(location.search).get('token');
            if (!token) {
                setError('Er ging iets mis. Probeer het opnieuw.');
                return;
            }

            try {
                const response = await stytch.magicLinks.authenticate(token, {
                    session_duration_minutes: 60 * 24 * 7
                });

                if (response.status_code === 200) {
                    await verifyEmail(response.session_token);
                    setIsLoading(false);
                    setMessage('E-mail succesvol geverifieerd! Je wordt zo teruggestuurd.');
                    setTimeout(async () => {
                        await mutateUser();
                        navigate('/');
                    }, 2000);
                }
            } catch (error: any) {
                Sentry.captureException(error);
                console.error('Error verifying email:', error);
                if (error.error_type === 'magic_link_expired') {
                    setError('Verificatie link is verlopen. Probeer het opnieuw.');
                } else if (error.error_type === 'magic_link_used') {
                    navigate('/login', {
                        state: { message: 'Je e-mail is al eerder geverifieerd. Log in om verder te gaan.' }
                    });
                } else {
                    setError('Er ging iets mis. Probeer het opnieuw.');
                }
            }
        };

        verifyToken();
    }, [location, navigate, stytch, verifyEmail]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: '#111418',
            padding: 3
        }}>
            <Box sx={{
                backgroundColor: 'background.paper',
                padding: 3,
                borderRadius: 2,
                maxWidth: 400,
                width: '100%',
                textAlign: 'center'
            }}>
                {error ? (
                    <Typography color="primary">{error}</Typography>
                ) : (
                    <>
                        {isLoading ? <CircularProgress sx={{ mb: 2 }} /> : <Typography variant="h4">✅</Typography>}
                        <Typography>{message}</Typography>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default VerifyEmail; 