import React, { useState } from 'react';
import { Box, Typography, Button, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStytch } from '@stytch/react';
import * as Sentry from '@sentry/react';
type VerifyEmailModalProps = {
    open: boolean;
    onClose: () => void;
    email?: string;
}

const VerifyEmailModal: React.FC<VerifyEmailModalProps> = ({ open, onClose, email }) => {
    const stytch = useStytch();
    const [message, setMessage] = useState<string>('Je hebt een mail ontvangen met een verificatielink. Na het activeren kan je gebruik maken van alle functies.');
    const [emailResent, setEmailResent] = useState<boolean>(false);

    const handleResendEmail = async () => {
        const baseUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL || window.location.origin;
        const verifyUrl = new URL('/verify-email', baseUrl).toString();
        if (!email) return;
        try {
            await stytch.magicLinks.email.send(
                email,
                {
                    login_magic_link_url: verifyUrl,
                    signup_magic_link_url: verifyUrl,
                    login_expiration_minutes: 60,
                    signup_expiration_minutes: 60
                }
            );
            setMessage('Nieuwe verificatie e-mail verzonden. Controleer je inbox.');
            setEmailResent(true);
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error resending verification email:', error);
            setMessage('Er ging iets mis. Probeer het opnieuw.');
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="auth-modal-title"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 320,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 3,
                pt: 1,
                borderRadius: 2,
                outline: 'none',
            }}>
                <Box sx={{ display: 'flex', width: '100%', mb: 3, justifyContent: 'space-between', textAlign: 'center' }}>
                    <Typography id="auth-modal-title" variant="h6" component="h2" textAlign={'left'} gutterBottom>
                        Activeer je account
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon sx={{ fontSize: '16pt' }} />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', my: 2 }}>
                    <Typography id="auth-modal-message" variant="body2" textAlign={'left'} gutterBottom>
                        {message}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Button variant='contained' color='primary' onClick={handleResendEmail} disabled={emailResent}>
                        Verstuur opnieuw
                    </Button>

                </Box>
            </Box>
        </Modal>
    );
};

export default VerifyEmailModal;