import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, CircularProgress, FormControlLabel, Checkbox, Link, useTheme } from '@mui/material';
import { useStytch, useStytchUser } from '@stytch/react';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import ImageCarousel from '../components/ImageCarousel';
import { ACTIVITY_TIMEOUT, useSession } from '../hooks/useSession';
import { useUser } from '../hooks/useUser';
import * as Sentry from '@sentry/react';

const redirectUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL || 'http://localhost:3000';


const Login = ({ register, onClose }: { register?: boolean, onClose?: () => void }) => {
    const theme = useTheme();
    const { user } = useStytchUser();
    const { createUser } = useUser();
    const stytch = useStytch();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [usernameError, setUsernameError] = useState<string | null>(null);
    const eventId = new URLSearchParams(location.search).get('event_id');
    const [isRegister, setIsRegister] = useState(register);
    const [passwordStrength, setPasswordStrength] = useState<'weak' | 'strong' | null>(null);
    const navigate = useNavigate();
    const { startActivityMonitoring } = useSession();

    const [contestParticipant, setContestParticipant] = useState(false);
    const [instagramHandle, setInstagramHandle] = useState('');

    const getRedirectUrl = () => {
        const baseUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL || 'http://localhost:3000/';
        return eventId ? `${baseUrl}?event_id=${eventId}` : baseUrl;
    };

    const checkPasswordStrength = (password: string): 'weak' | 'strong' => {
        return password.length >= 8 ? 'strong' : 'weak';
    };

    const validateInstagramHandle = (username: string) => {
        const regex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/;
        return regex.test(username);
    };

    const validateUsername = (username: string): boolean => {
        const regex = /^[a-z0-9_]+$/;
        return regex.test(username);
    };

    const handleAuth = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setPasswordStrength(null);

        try {
            if (isRegister) {
                if (password !== confirmPassword) {
                    setError('Wachtwoorden komen niet overeen.');
                    setIsLoading(false);
                    return;
                }

                if (!username) {
                    setError('Username is verplicht.');
                    setIsLoading(false);
                    return;
                }

                if (contestParticipant && !validateInstagramHandle(instagramHandle)) {
                    setError('Vul een geldig Instagram account in');
                    setIsLoading(false);
                    return;
                }

                const response = await stytch.passwords.create({
                    email,
                    password,
                    session_duration_minutes: ACTIVITY_TIMEOUT,
                });

                if (response.status_code === 200) {
                    try {
                        await createUser(email, response.session_token, {
                            username,
                            instagramHandle: contestParticipant ? instagramHandle : undefined
                        });
                        const baseUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL || window.location.origin;
                        const verifyUrl = new URL('/verify-email', baseUrl).toString();
                        await stytch.magicLinks.email.send(
                            email,
                            {
                                signup_magic_link_url: verifyUrl,
                                login_magic_link_url: verifyUrl,
                                signup_expiration_minutes: 60,
                                login_expiration_minutes: 60
                            }
                        );
                        navigate('/verify-email-sent', {
                            state: {
                                email,
                                message: 'E-mail succesvol verzonden. Controleer je inbox.',
                                action: true
                            },
                            replace: true
                        });
                        return;
                    } catch (error) {
                        Sentry.captureException(error); // Log error to Sentry
                        console.error('Error during user creation:', error);
                        setError('Er ging iets mis bij het aanmaken van je account. Probeer het opnieuw.');
                        throw error;
                    }
                }
            } else {
                try {
                    const response = await stytch.passwords.authenticate({
                        email,
                        password,
                        session_duration_minutes: ACTIVITY_TIMEOUT
                    });

                    if (response.status_code === 200) {
                        startActivityMonitoring();
                        if (onClose) {
                            onClose();
                        } else {
                            window.location.href = getRedirectUrl();
                        }
                    }
                } catch (error: any) {
                    if (error.error_type === 'user_not_found' ||
                        error.error_type === 'email_not_verified') {
                        await stytch.magicLinks.email.send(
                            email,
                            {
                                login_magic_link_url: `${process.env.REACT_APP_LOGIN_REDIRECT_URL}/login`,
                                signup_magic_link_url: `${process.env.REACT_APP_LOGIN_REDIRECT_URL}/verify-email`
                            }
                        );
                        navigate('/verify-email-sent', {
                            state: {
                                email,
                                message: 'Je e-mail is niet geverifieerd. We hebben een nieuwe verificatie link verzonden.',
                                action: true
                            }
                        });
                        return;
                    }
                    Sentry.captureException(error);
                    throw error;
                }
            }
        } catch (error: any) {
            if (error.error_type === 'user_not_found' || error.error_type === 'email_not_found') {
                setError('Gebruiker niet gevonden. Controleer uw e-mailadres of registreer een nieuw account.');
            } else if (error.status_code === 401 || error.error_type === 'unauthorized_credentials') {
                setError('Ongeldige e-mail/wachtwoord combinatie. Probeer het opnieuw.');
            } else if (error.error_type === 'invalid_credentials') {
                setError('Ongeldige e-mail/wachtwoord combinatie. Probeer het opnieuw.');
            } else if (error.error_type === 'invalid_email') {
                setError('Ongeldig e-mailadres. Controleer uw invoer en probeer het opnieuw.');
            } else if (error.error_type === 'weak_password') {
                setError('Gebruik een sterker wachtwoord met minimaal 8 tekens.');
            } else if (error.error_type === 'breached_password') {
                setError('Dit wachtwoord is gevonden in een datalek. Kies een ander, veiliger wachtwoord.');
            } else if (error.error_type === 'password_validation_timeout') {
                setError('De wachtwoordvalidatie duurde te lang. Probeer het opnieuw.');
            } else if (error.error_type === 'duplicate_email') {
                setError('Er bestaat al een account met dit e-mailadres. Probeer in te loggen of gebruik een ander e-mailadres.');
            } else if (error.error_type === 'reset_password') {
                // Send a password reset email
                await stytch.passwords.resetByEmailStart({
                    email: email,
                    login_redirect_url: `${redirectUrl}/login`,
                    reset_password_redirect_url: `${redirectUrl}/reset-password-complete`,
                });

                navigate('/verify-email-sent', {
                    state: {
                        email,
                        message: 'Voor je veiligheid moet je je wachtwoord opnieuw instellen omdat je inlogt vanaf een nieuwe locatie. We hebben een e-mail gestuurd met instructies.'
                    }
                });
                return;
            } else {
                setError('Er ging iets mis. Probeer het opnieuw.');
                Sentry.captureException(error);
            }
            console.error('Login error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            onClose ? onClose() : navigate(eventId ? `/meet/${eventId}` : '/');
        }
    }, [user, navigate, eventId]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            minHeight: '100vh',
            position: 'relative',
            overflow: 'auto',
            paddingTop: '300px',
        }}>
            <Box sx={{
                position: 'absolute',
                top: '25px',
                left: '50%',
                transform: 'translateX(-50%)',
            }}>
                <ImageCarousel />
            </Box>
            <Box sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                top: '200px',
                '@media (max-width: 768px)': {
                    top: '150px',
                },
                left: 0,
                width: '100%',
                height: 'auto',
                zIndex: 1000,
            }}>
                <Box
                    component="form"
                    onSubmit={handleAuth}
                    sx={{
                        width: '320px',
                        backgroundColor: theme.palette.background.paper,
                        padding: 3,
                        mb: 4,
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <>
                        <TextField
                            data-testid="email-input"
                            fullWidth
                            label={'Email'}
                            variant="outlined"
                            type={'email'}
                            value={email}
                            onChange={(e) => setEmail(e.target.value.toLowerCase())}
                            sx={{ mb: 2 }}
                            required
                            autoComplete={'email'}
                            inputProps={{
                                autoCapitalize: 'none',
                                autoCorrect: 'off'
                            }}
                        />
                        {isRegister && (
                            <TextField
                                data-testid="username-input"
                                fullWidth
                                label="Gebruikersnaam"
                                variant="outlined"
                                type="text"
                                value={username}
                                helperText={usernameError}
                                onChange={(e) => {
                                    const newUsername = e.target.value.toLowerCase();
                                    if (newUsername.includes(' ')) {
                                        setUsernameError('Gebruikersnaam mag geen spaties bevatten.');
                                    } else if (!validateUsername(newUsername) && newUsername !== '') {
                                        setUsernameError('Gebruikersnaam mag alleen letters, cijfers en _ bevatten.');
                                    } else {
                                        setUsernameError(null);
                                        setUsername(newUsername);
                                    }
                                }}
                                sx={{ mb: 2 }}
                                required
                                autoComplete="username"
                                inputProps={{
                                    autoCapitalize: 'none',
                                    autoCorrect: 'off',
                                    noSpaces: true,
                                    maxLength: 30,
                                    pattern: '[a-z0-9_]*'
                                }}
                            />
                        )}
                        <TextField
                            data-testid="password-input"
                            fullWidth
                            label="Wachtwoord"
                            variant="outlined"
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                if (isRegister) {
                                    setPasswordStrength(checkPasswordStrength(e.target.value));
                                } else {
                                    setError('');
                                }
                            }}
                            sx={{ mb: 1 }}
                            required
                        />
                        {!isRegister && <Button
                            variant="text"
                            onClick={() => navigate('/reset-password')}
                            sx={{ mb: 3, ml: 'auto', p: 0 }}
                            color="secondary"
                        >
                            <Typography variant="body1">
                                Wachtwoord vergeten?
                            </Typography>
                        </Button>}
                        {(error && !isRegister) && (
                            <Typography
                                sx={{
                                    marginBottom: 2,
                                    textAlign: 'left',
                                    color: 'primary.main'
                                }}
                            >
                                {error}
                            </Typography>
                        )}
                        {isRegister && (
                            <>
                                <TextField
                                    fullWidth
                                    label="Bevestig wachtwoord"
                                    variant="outlined"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    sx={{ mb: 2 }}
                                    required
                                />
                                {(error || (isRegister && passwordStrength)) && (
                                    <Typography
                                        sx={{
                                            marginBottom: 2,
                                            textAlign: 'left',
                                            color: isRegister && passwordStrength === 'strong' ? 'text.secondary' : 'primary.main'
                                        }}
                                    >
                                        {error || (isRegister && passwordStrength === 'weak' ? 'Wachtwoord moet minimaal 8 tekens lang zijn.' : null)}
                                    </Typography>
                                )}
                                {process.env.REACT_APP_ENABLE_GIVEAWAY === 'true' &&
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={contestParticipant}
                                                onChange={(e) => setContestParticipant(e.target.checked)}
                                            />
                                        }
                                        label={
                                            <Box sx={{ display: 'block' }}>
                                                Ik doe mee met de winactie en ga akkoord met de{' '}
                                                <Link
                                                    component={RouterLink}
                                                    to="/winactie"
                                                    onClick={(e) => e.stopPropagation()}
                                                    target="_blank"
                                                >
                                                    voorwaarden
                                                </Link>
                                                .
                                            </Box>
                                        }
                                        sx={{}}
                                    />}
                                {contestParticipant && (
                                    <TextField
                                        fullWidth
                                        label="Instagram account"
                                        variant="outlined"
                                        value={instagramHandle}
                                        onChange={(e) => setInstagramHandle(e.target.value)}
                                        sx={{ mb: 2 }}
                                        placeholder="@username"
                                        helperText={!validateInstagramHandle(instagramHandle) && instagramHandle !== '' ? 'Ongeldig Instagram account' : ''}
                                        error={!validateInstagramHandle(instagramHandle) && instagramHandle !== ''}
                                    />
                                )}
                            </>
                        )}
                        {isRegister && <Typography variant="body1" fontSize={12} sx={{ mt: 2, mb: 1 }}>
                            Door je te registreren ga je akkoord met onze <Link href="/algemene-voorwaarden" target="_blank">algemene voorwaarden</Link>.
                            Ons <Link href="/privacy-statement" target="_blank">privacybeleid</Link> is van toepassing.
                        </Typography>}
                        <Button
                            data-testid="submit-button"
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            disabled={isLoading}
                            sx={{ mb: 0 }}
                        >
                            {isLoading ? <CircularProgress size={24} /> : (isRegister ? 'Account aanmaken' : 'Login')}
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            fullWidth
                            onClick={() => onClose ? onClose() : navigate('/')}
                            sx={{ mt: 1 }}
                        >
                            Sluiten
                        </Button>
                        <Button
                            onClick={() => setIsRegister(!isRegister)}
                            sx={{ mt: 2, px: 3 }}
                            color="secondary"
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 'bold',
                                    whiteSpace: 'pre-line',
                                    textAlign: 'center'
                                }}
                            >
                                {isRegister ?
                                    'Heb je al een account? \nLog hier in' :
                                    'Heb je nog geen account? \nRegistreer hier'}
                            </Typography>
                        </Button>
                    </>
                </Box>
            </Box>
        </Box>
    );
};

export default Login;