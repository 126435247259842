import React, { useMemo, useState } from 'react';
import { Box, IconButton, Typography, Button, Grid, CardMedia, Dialog, DialogTitle, DialogContent, useTheme, useMediaQuery, Avatar } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import FullScreenLoader from '../components/loader/FullScreenLoader';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Car, CarModification } from '../types/Car';
import TopBar from '../components/top-bar/TopBar';
import ImageCarousel from '../components/image-carousel/ImageCarousel';
import ImagePreviewModal from '../components/image-preview/ImagePreviewModal';
import StreetCredButton from '../components/street-cred-button/StreetCredButton';
import StreetCredCounter from '../components/street-cred-counter/StreetCredCounter';
import {
    Instagram as InstagramIcon,
    YouTube as YouTubeIcon,
    MusicNote as TikTokIcon,
    Build as BuildIcon,
    Close as CloseIcon,
    Person as PersonIcon,
    ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import { useCars } from '../hooks/useCars';
import { formatCarModel } from '../utils/car-utils';
import carmeeterCar1 from '../assets/carmeeter-car-1-grey-transparent.png';
import { useUser } from '../hooks/useUser';
import CarFormWizard from '../components/car-form/CarFormWizard';
import * as Sentry from '@sentry/react';
const CarDetails = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { id: carId } = useParams<{ id: string }>();
    const { car, isCarLoading, deleteCar, voteStreetCred, mutateCar } = useCars({ carId });
    const [showImagePreview, setShowImagePreview] = useState(false);
    const [animateShare, setAnimateShare] = useState(false);
    const [isSharing, setIsSharing] = useState(false);
    const { user, isLoading: isUserLoading } = useUser();
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const isOwner = useMemo(() => {
        return user?.cars?.some((c: Car) => c?.id === carId);
    }, [user, carId]);

    const handleBack = () => {
        navigate(-1);
    };

    const handleStreetCredVote = async () => {
        if (!carId) return;
        try {
            await voteStreetCred(carId);
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error voting street cred:', error);
        }
    };

    const handleShare = () => {
        const newUrl = `/car/${car?.id}`;
        window.history.pushState(null, '', newUrl);
        setAnimateShare(false);
        if (navigator.share) {
            setAnimateShare(true);
            setIsSharing(true);
            navigator.share({
                url: `${window.location.origin}/car/${car?.id}`,
            })
                .then(() => {
                    setIsSharing(false);
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    console.error('Error sharing:', error);
                    setIsSharing(false);
                });
        }
    };

    const handleEdit = () => {
        setShowEditDialog(true);
    };

    const handleEditDialogClose = () => {
        setShowEditDialog(false);
    };

    const handleFormSubmit = async () => {
        await mutateCar();
        handleEditDialogClose();
    };

    const handleDeleteClick = () => {
        setShowDeleteDialog(true);
        setShowEditDialog(false);
    };

    const handleDeleteDialogClose = () => {
        setShowDeleteDialog(false);
        setShowEditDialog(true);
    };

    const handleDelete = async () => {
        if (!car) return;
        try {
            await deleteCar(car.id);
            navigate('/');
        } catch (err) {
            Sentry.captureException(err);
            console.error('Error deleting car:', err);
        }
    };

    const renderSocialMediaButtons = () => {
        if (!car?.instagramUrl && !car?.tiktokUrl && !car?.youtubeUrl) {
            return null;
        }

        return (
            <Box sx={{ display: 'flex', gap: 1 }}>
                {car?.instagramUrl && (
                    <IconButton
                        sx={{ p: 0 }}
                        size="small"
                        color="secondary"
                        onClick={() => window.open(car.instagramUrl, '_blank')}
                    >
                        <InstagramIcon />
                    </IconButton>
                )}
                {car?.tiktokUrl && (
                    <IconButton
                        sx={{ p: 0 }}
                        size="small"
                        color="secondary"
                        onClick={() => window.open(car.tiktokUrl, '_blank')}
                    >
                        <TikTokIcon />
                    </IconButton>
                )}
                {car?.youtubeUrl && (
                    <IconButton
                        sx={{ p: 0 }}
                        size="small"
                        color="secondary"
                        onClick={() => window.open(car.youtubeUrl, '_blank')}
                    >
                        <YouTubeIcon />
                    </IconButton>
                )}
            </Box>
        );
    };

    if (isCarLoading || isUserLoading || !car || !user) {
        return <FullScreenLoader />;
    }

    return (
        <Box>
            <Box
                data-testid="car-details"
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                    '@media (min-width: 768px)': {
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        justifyContent: 'center',
                        width: '100%',
                        minHeight: '100vh',
                        alignItems: 'flex-start',
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        '@media (min-width: 768px)': {
                            width: '600px',
                        },
                    }}
                >
                    <TopBar variant="details" title='Auto details' onBack={handleBack} />
                    <Box sx={{ padding: 2, margin: 2, backgroundColor: 'background.paper', marginTop: 0 }}>
                        <Box sx={{ marginBottom: 4 }}>
                            <Typography
                                data-testid="car-title"
                                gutterBottom
                                sx={{
                                    marginBottom: 0.1,
                                    fontFamily: 'Pricedown',
                                    fontWeight: 'bold',
                                    color: 'white',
                                    WebkitTextStroke: '1px black',
                                    zIndex: 0,
                                    fontSize: '32pt',
                                    lineHeight: '1.0',
                                    width: '100%',
                                    textAlign: 'center',
                                }}
                            >
                                {car.make}
                            </Typography>
                            <Typography
                                data-testid="car-title"
                                gutterBottom
                                sx={{
                                    fontFamily: 'Pricedown',
                                    fontWeight: 'bold',
                                    color: 'white',
                                    WebkitTextStroke: '1px black',
                                    zIndex: 0,
                                    fontSize: '22pt',
                                    lineHeight: '1.0',
                                    width: '100%',
                                    textAlign: 'center',
                                }}
                            >
                                {formatCarModel(car.model)}
                            </Typography>
                        </Box>

                        <Box sx={{ position: 'relative', marginBottom: 2 }}>
                            {car.images && car.images.length > 0 ? (
                                <ImageCarousel
                                    images={car.images}
                                    aspectRatio={Number(process.env.REACT_APP_ASPECT_RATIO)}
                                    showArrows={true}
                                />
                            ) : (
                                <CardMedia
                                    component="img"
                                    image={carmeeterCar1}
                                    sx={(theme) => ({
                                        aspectRatio: Number(process.env.REACT_APP_ASPECT_RATIO),
                                        p: 1,
                                        objectFit: 'cover',
                                        backgroundColor: theme.palette.background.default,
                                    })}
                                    alt={`${car.make} ${car.model}`}
                                />)}
                            <Box sx={{ position: 'absolute', bottom: 8, right: 8, zIndex: 999, display: 'flex', gap: 1 }}>
                                <StreetCredCounter count={car.streetCred} />
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginY: 2 }}>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <IconButton
                                    aria-label="share"
                                    onClick={handleShare}
                                    sx={{ height: '40px', width: '40px' }}
                                >
                                    {!isSharing ? (
                                        <IosShareIcon sx={{ fontSize: '24px', color: 'secondary.main' }} />
                                    ) : (
                                        <Typography
                                            sx={{
                                                fontSize: '16pt',
                                                animation: animateShare && isSharing ? 'bounceShare 0.3s' : 'none',
                                                '@keyframes bounceShare': {
                                                    '0%': { transform: 'perspective(400px) scale(0)' },
                                                    '50%': { transform: 'perspective(400px) scale(1.5)' },
                                                    '100%': { transform: 'perspective(400px) scale(1)' },
                                                }
                                            }}
                                        >
                                            🔗
                                        </Typography>
                                    )}
                                </IconButton>
                                {renderSocialMediaButtons()}
                            </Box>
                            {isOwner ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleEdit}
                                    startIcon={<BuildIcon />}
                                >
                                    <Typography variant="body2" fontWeight="bold">Bewerken</Typography>
                                </Button>
                            ) : (
                                <StreetCredButton
                                    hasVoted={car.hasVoted || false}
                                    onVote={handleStreetCredVote}
                                    disabled={false}
                                />
                            )}
                        </Box>
                        {!isOwner && <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            backgroundColor: 'background.default',
                            p: 1,
                            borderRadius: 1,
                            mb: 1
                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Avatar
                                    src={car.owner?.profilePicture ? `${process.env.REACT_APP_API_BASE_URL}${car.owner.profilePicture}` : undefined}
                                    sx={{ width: 48, height: 48 }}
                                >
                                    <PersonIcon />
                                </Avatar>
                                <Box >
                                    <Typography variant="body2" color="text.secondary">
                                        Uit de garage van
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{
                                        fontWeight: 'bold',
                                        overflow: 'clip',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        fontSize: isMobile ? '11pt' : '14pt',
                                        maxWidth: isMobile ? '45vw' : '100%',
                                    }}>
                                        {car.owner?.username}
                                    </Typography>
                                </Box>
                            </Box>
                            <IconButton
                                color="secondary"
                                onClick={() => navigate(`/user/${car.owner?.username}`)}
                            >
                                <ArrowForwardIcon />
                            </IconButton>
                        </Box>}
                        {car.description && (
                            <Box sx={{ marginY: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Beschrijving
                                </Typography>
                                <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <Typography
                                        data-testid="car-description"
                                        variant="body1"
                                        fontSize="13pt"
                                        sx={{
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: showFullDescription ? 'unset' : 3,
                                            WebkitBoxOrient: 'vertical',
                                            transition: 'all 0.3s ease',
                                            position: 'relative',
                                            mb: 1,
                                        }}
                                    >
                                        {car.description}
                                    </Typography>
                                    {!showFullDescription && car.description.length > 100 && (
                                        <Box
                                            sx={(theme) => ({
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                height: '100px',
                                                background: `linear-gradient(to bottom, 
                                                    transparent 0%, 
                                                    ${theme.palette.background.paper} 66%
                                                )`,
                                                pointerEvents: 'none',
                                                marginTop: '-100px',
                                            })}
                                        />
                                    )}
                                    {car.description.length > 100 && (
                                        <Button
                                            onClick={() => setShowFullDescription(!showFullDescription)}
                                            variant='outlined'
                                            size='small'
                                            color='secondary'
                                            sx={{
                                                margin: '0 auto',
                                                textAlign: 'center',
                                                width: '120px',
                                            }}
                                        >
                                            {showFullDescription ? 'Toon minder' : 'Toon meer'}
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        )}
                        {(car.year || car.engineType || car.horsePower || car.torque || car.transmission || car.color) && <Box sx={{ marginY: 3 }}>
                            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                Specificaties
                            </Typography>
                            <Grid container spacing={1} gap={0}>
                                {car.year &&
                                    <Grid item xs={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, backgroundColor: 'background.default', p: 1.5, borderRadius: 1 }}>
                                            <Typography variant="h6">📅</Typography>
                                            <Box>
                                                <Typography variant="caption" color="text.secondary">Bouwjaar</Typography>
                                                <Typography variant="body2">
                                                    {car.year}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>}
                                {car.engineType && (
                                    <Grid item xs={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, backgroundColor: 'background.default', p: 1.5, borderRadius: 1 }}>
                                            <Typography variant="h6">⛽️</Typography>
                                            <Box>
                                                <Typography variant="caption" color="text.secondary">Motor</Typography>
                                                <Typography variant="body2">
                                                    {car.engineDisplacement && `${car.engineDisplacement.toFixed(1)}L`} {car.engineType}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )}
                                {car.horsePower && (
                                    <Grid item xs={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, backgroundColor: 'background.default', p: 1.5, borderRadius: 1 }}>
                                            <Typography variant="h6">🏎️</Typography>
                                            <Box>
                                                <Typography variant="caption" color="text.secondary">Vermogen</Typography>
                                                <Typography variant="body2">{car.horsePower} PK</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )}
                                {car.torque && (
                                    <Grid item xs={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, backgroundColor: 'background.default', p: 1.5, borderRadius: 1 }}>
                                            <Typography variant="h6">⚙️</Typography>
                                            <Box>
                                                <Typography variant="caption" color="text.secondary">Koppel</Typography>
                                                <Typography variant="body2">{car.torque} NM</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )}
                                {car.transmission && (
                                    <Grid item xs={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, backgroundColor: 'background.default', p: 1.5, borderRadius: 1 }}>
                                            <Typography variant="h6">🕹</Typography>
                                            <Box>
                                                <Typography variant="caption" color="text.secondary">Transmissie</Typography>
                                                <Typography variant="body2">{car.transmission}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )}
                                {car.color && (
                                    <Grid item xs={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, backgroundColor: 'background.default', p: 1.5, borderRadius: 1 }}>
                                            <Typography variant="h6">🎨</Typography>
                                            <Box>
                                                <Typography variant="caption" color="text.secondary">Kleur</Typography>
                                                <Typography variant="body2">{car.color}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                        }

                        {car.modifications && car.modifications.length > 0 && (
                            <Box sx={{ marginY: 3 }}>
                                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    Modificaties
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    {car.modifications.map((mod: CarModification, index: number) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                p: 2,
                                                bgcolor: 'background.default',
                                                borderRadius: 1,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 0.5
                                            }}
                                        >
                                            <Typography variant="subtitle2" color="secondary">
                                                {mod.component}
                                            </Typography>
                                            {mod.isCustom ? (
                                                <Typography variant="body2" color="text.secondary">
                                                    Custom: {mod.design}
                                                </Typography>
                                            ) : (
                                                <Typography variant="body2" color="text.secondary">
                                                    {mod.manufacturer}
                                                </Typography>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>

            <Dialog
                open={showEditDialog}
                onClose={handleEditDialogClose}
                maxWidth="md"
                fullWidth
                fullScreen={isMobile}
            >
                <IconButton
                    onClick={handleEditDialogClose}
                    sx={{
                        position: 'absolute',
                        top: theme.spacing(2),
                        right: theme.spacing(2),
                        zIndex: 1,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <Typography variant="h5">
                        Bewerk Auto
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <CarFormWizard
                        car={car}
                        onSubmit={handleFormSubmit}
                        onCancel={handleEditDialogClose}
                        onDelete={handleDeleteClick}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={showDeleteDialog}
                onClose={handleDeleteDialogClose}
            >
                <DialogTitle>Verwijder Auto</DialogTitle>
                <DialogContent>
                    <Typography>
                        Weet je zeker dat je {car?.make} {car?.model} wilt verwijderen?
                        Je raakt je opgebouwde StreetCred kwijt. Deze actie kan niet ongedaan worden gemaakt.
                    </Typography>
                </DialogContent>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, p: 2 }}>
                    <Button onClick={handleDeleteDialogClose} color="secondary">Annuleren</Button>
                    <Button onClick={handleDelete} color="error" variant="contained">
                        Verwijderen
                    </Button>
                </Box>
            </Dialog>

            {car.images && car.images.length > 0 && <ImagePreviewModal
                open={showImagePreview}
                onClose={() => setShowImagePreview(false)}
                images={car.images}
                initialIndex={0}
            />}
        </Box>
    );
};

export default CarDetails; 