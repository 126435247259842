import React from 'react';
import { Box, Paper, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CarCardSkeleton from '../car-card/CarCardSkeleton';

const ProfileHeader = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: theme.palette.background.paper,
}));

const StatsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(3),
    marginTop: theme.spacing(1),
}));

const StatItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(0.5),
}));

const UserProfileSkeleton = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            minHeight: '100vh',
            alignItems: 'center',
            pb: 2,
        }}>
            <Box sx={{
                '@media (min-width: 768px)': {
                    width: '600px',
                },
                width: '100%',
                px: 2,
            }}>
                <ProfileHeader elevation={0}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Box sx={{ position: 'relative', width: 'auto' }}>
                            <Skeleton variant="circular" width={80} height={80} />
                        </Box>
                        <Box sx={{ flex: 1, width: '100%' }}>
                            <Skeleton variant="text" width="60%" height={32} sx={{ mb: 1 }} />
                            <Skeleton variant="text" width="40%" height={24} sx={{ mb: 1 }} />

                            <StatsContainer>
                                {[1, 2, 3].map((item) => (
                                    <StatItem key={item}>
                                        <Skeleton variant="text" width={60} height={20} />
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                            <Skeleton variant="circular" width={20} height={20} />
                                            <Skeleton variant="text" width={30} height={20} />
                                        </Box>
                                    </StatItem>
                                ))}
                            </StatsContainer>
                        </Box>
                    </Box>

                    <Skeleton variant="text" width="80%" height={60} sx={{ mt: 2 }} />

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: 1, mt: 2 }}>
                        <Skeleton variant="rectangular" width="48%" height={36} sx={{ borderRadius: 1 }} />
                        <Skeleton variant="rectangular" width="48%" height={36} sx={{ borderRadius: 1 }} />
                    </Box>
                </ProfileHeader>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {[1, 2].map((item) => (
                        <CarCardSkeleton key={item} />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default UserProfileSkeleton; 